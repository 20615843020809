import React, { useEffect, useState } from "react";
import styles from "./ClassroomTestParticipantsTable.module.scss";
import { ConfigProvider, Table, Tag } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const ClassroomTestParticipantsTable = ({ participants }) => {
  const [participantsData, setParticipantsData] = useState([]);

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  useEffect(() => {
    if (participants) {
      setParticipantsData(
        participants
          .sort((p1, p2) => (p2.startedAt - p1.startedAt))
          .map(p => {
            return { key: p.userId, ...p };
          }),
      );
    }
  }, [participants]);

  const columns = [
    {
      title: "Nickname",
      dataIndex: "nickname",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { status }) => {
        switch (status) {
        case "IN_PROGRESS":
          return <Tag color="geekblue" key={status}>In Progress</Tag>;
        case "COMPLETED":
          return <Tag color="green" key={status}>Completed</Tag>;
        case "PARTIALLY_COMPLETED":
          return <Tag color="orange" key={status}>Incomplete</Tag>;
        default:
          return <Tag key={status}>{status.toUpperCase()}</Tag>;
        }
      },
    },
  ];

  return (
    <div className={styles["main-container"]}>
      <ConfigProvider
        theme={{
          token: {
            fontSize: sm ? 20 : 16,
          },
        }}
      >
        <Table columns={columns} dataSource={participantsData} pagination={false} tableLayout="fixed" />
      </ConfigProvider>
    </div>
  );
};

export default ClassroomTestParticipantsTable;
