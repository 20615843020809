import axios from "axios";
import { API_BASE_URL } from "../constants/api";

export const config = {
  baseURL: API_BASE_URL,
};
export const responseConfig = (response) => {
  return response.data.data;
};

export const api = axios.create(config);
api.interceptors.response.use(responseConfig);
