import { Button, ConfigProvider, Modal } from "antd";
import React from "react";

function ConfirmationModal({
                             title = "",
                             message,
                             open = false,
                             loading = false,
                             onCancel = () => {},
                             onConfirm = () => {},
                             dangerConfirmButton = false,
                           }) {
  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = async () => {
    onConfirm();
  };

  const footer = (
    [
      <Button key="back" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="submit" type="primary" danger={dangerConfirmButton} loading={loading} onClick={handleConfirm}>
        Confirm
      </Button>,
    ]
  );

  return (
    <ConfigProvider theme={{
      components: {
        Modal: {
          contentBg: "#FFFCF8",
          headerBg: "#FFFCF8",
          titleFontSize: 22,
          fontSize: 20,
        },
      },
    }}>
      <Modal
        title={title}
        centered
        open={open}
        onCancel={handleCancel}
        width={1000}
        footer={footer}
        maskClosable={true}
        destroyOnClose={true}
      >
        <div>
          {message}
        </div>
      </Modal>
    </ConfigProvider>
  );
}

export default ConfirmationModal;
