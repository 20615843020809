import { ConfigProvider, Divider } from "antd";
import PropTypes from "prop-types";
import React from "react";
import styles from "./PageLayout.module.scss";
import nusLogos from "../../assets/nus_logos.png";

PageLayout.propTypes = {
  displayMode: PropTypes.oneOf(["normal", "overlay"]),
};

function PageLayout({
                      header = null, body = null, displayMode = "normal", showHeader = true,
                      overlayBodyPadding = true, showFooter = false, showNUSLogos = false,
                    }) {
  let bodyClassName = styles[`body-${displayMode}`];
  bodyClassName = bodyClassName.concat(displayMode === "overlay" && overlayBodyPadding ? " " + styles["body-padding"] : "");

  const Header = () => {
    if (displayMode === "overlay") {
      return <div className={styles["header-overlay"]}></div>;
    } else {
      return (
        <div className={styles["header-normal"]}>
          {
            showNUSLogos &&
            <div className={styles["header-normal-nus-logos"]}>
              <img src={nusLogos} alt="NUS SOC and FASS Logos" className={styles.nusLogoImage} />
            </div>
          }
          <div className={styles["header-normal-content"]}>
            {header}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {showHeader && <Header />}
      <div className={bodyClassName}>
        {
          (displayMode === "overlay" && showNUSLogos) &&
          <div className={styles.nusLogoImageBox}>
            <img src={nusLogos} alt="NUS SOC and FASS Logos" className={styles.nusLogoImage} />
          </div>
        }
        {body}
      </div>
      {
        showFooter &&
        <div className={styles.footer}>
          <div className={styles.container}>
            <ConfigProvider
              theme={{
                token: {
                  colorSplit: "rgba(5,5,5,0.25)",
                },
              }}
            >
              <Divider>Pin It v{process.env.REACT_APP_VERSION}</Divider>
            </ConfigProvider>
          </div>
        </div>
      }
    </>
  );
}

export default PageLayout;
