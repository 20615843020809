import { ACCESS_DENIED_ERROR_MSG, NETWORK_CONNECTION_ERROR_MSG, UNAUTHORIZED_ERROR_MSG } from "../constants/error";

export const getErrorMessage = (error) => {
  if (error.response) {
    const errorCode = getErrorCode(error);
    if (errorCode === 401) {
      return ACCESS_DENIED_ERROR_MSG;
    } else if (errorCode === 403) {
      return UNAUTHORIZED_ERROR_MSG;
    } else {
      return error.response.data.message;
    }
  } else if (error.request) {
    // TODO: change to error message by checking axios error code
    return NETWORK_CONNECTION_ERROR_MSG;
  } else {
    return "An error occurred: " + error;
  }
};

export const getErrorCode = (error) => {
  if (error.response) {
    return error.response.data.code;
  } else if (error.request) {
    return "network error";
  } else {
    return "unknown error";
  }
};
