import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getErrorCode, getErrorMessage } from "../../apiHandlers/apiUtils";
import { ACCESS_DENIED_ERROR_MSG } from "../../constants/error";
import { useAuth } from "../../contexts/AuthContext";
import { showOrUpdateExistingErrorToast, showOrUpdateExistingInfoToast } from "../../utils/toastUtils";
import ClassroomNavBar from "../components/ClassroomNavBar";
import ConfirmationModal from "../components/ConfirmationModal";
import ErrorBody from "../components/ErrorBody";
import LoadingSpinBody from "../components/LoadingSpinBody";
import styles from "./ClassroomHistoryPage.module.scss";
import PageLayout from "./PageLayout";
import ClassroomTestRecordList, {
  SORT_ORDER_NAME,
  SORT_ORDER_NAME_REVERSE,
  SORT_ORDER_START_LATEST,
  SORT_ORDER_START_OLDEST,
} from "../components/ClassroomTestRecordList";
import {
  deleteSessionRecord,
  endAssignedSession,
  getAllSessionRecords,
  renameSessionRecord,
} from "../../apiHandlers/classroomTestSessionApiHandler";
import { Collapse, ConfigProvider, Input } from "antd";
import { isAtLeastSecondsBefore } from "../../utils/TimeUtils";
import pinyin from "pinyin";
import { containsAnyChineseCharacter } from "../../utils/stringUtils";

const END_SESSION_CONFIRM_MESSAGE = "This session will no longer be accessible. Participants currently in process will still be able to finish. Are you sure you want to end this session?";
const SESSION_TO_END_NOT_FOUND_ERROR_MSG = "Oops! This session can't be ended right now. It may have been deleted.";
const SESSION_TO_RENAME_NOT_FOUND_ERROR_MSG = "Oops! This session can't be renamed right now. It may have been deleted.";
const DELETE_SESSION_CONFIRM_MESSAGE = "This session's results will be permanently deleted, including all its participants and data. This action cannot be undone. Any assigned sessions currently in progress will be immediately ended. Are you sure you want to delete this session?";

const CONFIRM_MODAL_MODE_END_SESSION = "confirm end session";
const CONFIRM_MODAL_MODE_DELETE_ASSIGNED_SESSION = "confirm delete assigned session";
const CONFIRM_MODAL_MODE_DELETE_LIVE_SESSION = "confirm delete live session";

const ClassroomHistoryPage = () => {
  const [recordsLoading, setRecordsLoading] = useState(true);
  const [recordsLoadingFailed, setRecordsLoadingFailed] = useState(false);
  const [assignedSessionRecords, setAssignedSessionRecords] = useState([]);
  const [liveSessionRecords, setLiveSessionRecords] = useState([]);

  const [confirmModalMode, setConfirmModalMode] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmSessionId, setConfirmSessionId] = useState("");
  const [confirmSessionName, setConfirmSessionName] = useState("");

  const [sessionToRename, setSessionToRename] = useState(null);
  const [isSessionToRenameAssigned, setIsSessionToRenameAssigned] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);

  const [recordToHighlight, setRecordToHighlight] = useState(null);

  let assignedSessionsSortOrder = useRef("");
  let liveSessionsSortOrder = useRef("");

  let newSessionName = useRef("");

  const { handleLogout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setRecordsLoading(true);
    // TODO: Lazy loading
    const fetchData = async () => {
      try {
        const records = await getAllSessionRecords();
        preprocessRecords(records);
        setRecordsLoading(false);
      } catch (err) {
        if (getErrorCode(err) === 401) {
          await handleLogout();
          toast.error(ACCESS_DENIED_ERROR_MSG);
          navigate("/classroom/login", { replace: true });
        }
        setRecordsLoading(false);
        setRecordsLoadingFailed(true);
      }
    };
    fetchData();
  }, []);

  const preprocessRecords = (records) => {
    const assignedSessionRecords = records.filter(record => record.type === "ASSIGNED");
    setAssignedSessionRecords(() => sortRecordsByStartTime(assignedSessionRecords).map(record => convertRecordModel(record)));

    const liveSessionRecords = records.filter(record => record.type === "LIVE");
    setLiveSessionRecords(() => sortRecordsByStartTime(liveSessionRecords).map(record => convertRecordModel(record)));
  };

  const sortRecordsByStartTime = (records) => {
    // TODO: Handle null startedAt/endedAt
    return [...records].sort((recordA, recordB) => recordB.startedAt - recordA.startedAt);
  };

  const sortRecordsByStartTimeReversed = (records) => {
    // TODO: Handle null startedAt/endedAt
    return [...records].sort((recordA, recordB) => recordA.startedAt - recordB.startedAt);
  };

  const compareRecordsByName = (a, b) => {
    const aTitle = a.title.toLowerCase();
    const bTitle = b.title.toLowerCase();

    // Check if both titles are English
    const isAEnglish = !containsAnyChineseCharacter(aTitle);
    const isBEnglish = !containsAnyChineseCharacter(bTitle);

    if (isAEnglish && isBEnglish) {
      // Sort English titles in alphabetical order (case-insensitive)
      return aTitle.localeCompare(bTitle);
    } else if (isAEnglish && !isBEnglish) {
      // English titles come before Chinese titles
      return -1;
    } else if (!isAEnglish && isBEnglish) {
      // English titles come before Chinese titles
      return 1;
    } else {
      // Sort Chinese titles based on pinyin
      const aPinyin = pinyin(aTitle, { style: pinyin.STYLE_TONE2 }).join("");
      const bPinyin = pinyin(bTitle, { style: pinyin.STYLE_TONE2 }).join("");
      return aPinyin.localeCompare(bPinyin);
    }
  };

  const sortRecordsByName = (records) => {
    return [...records].sort((recordA, recordB) => compareRecordsByName(recordA, recordB));
  };

  const sortRecordsByNameReversed = (records) => {
    return [...records].sort((recordA, recordB) => -compareRecordsByName(recordA, recordB));
  };

  const convertRecordModel = (record) => ({
    id: record.id,
    type: record.type,
    title: record.title,
    status: record.status,
    startedAt: record.startedAt,
    endedAt: record.startedAt,
    startedAtStr: record.startedAt ? DateTime.fromMillis(record.startedAt).toLocaleString(DateTime.DATETIME_MED) : null,
    endedAtStr: record.endedAt ? DateTime.fromMillis(record.endedAt).toLocaleString(DateTime.DATETIME_MED) : null,
    canBeEnded: record.type === "ASSIGNED" && record.status === "IN_PROGRESS",
    canBeShared: record.type === "ASSIGNED" && record.status !== "COMPLETED",
    canBeDeleted: record.type === "ASSIGNED" || (record.status === "COMPLETED") && isAtLeastSecondsBefore(record.endedAt, 90),
  });

  const handleEndSession = (sessionRecord) => {
    setConfirmModalMode(CONFIRM_MODAL_MODE_END_SESSION);
    openConfirmationModal(sessionRecord);
  };

  const handleConfirmEndSession = async () => {
    if (!confirmSessionId) return;

    setConfirmLoading(true);
    setTimeout(async () => {
      try {
        const endedSessionRecord = await endAssignedSession(confirmSessionId);
        setAssignedSessionRecords(prevRecords => prevRecords.map(record => {
          if (record.id === confirmSessionId) {
            return convertRecordModel(endedSessionRecord);
          }
          return record;
        }));
        // toast.success("Success", { autoClose: 600 });
        closeConfirmationModal();
        setRecordToHighlight(endedSessionRecord);
      } catch (err) {
        const toastId = "end-session-error-toast";
        if (getErrorCode(err) === 404) {
          showOrUpdateExistingErrorToast(toastId, SESSION_TO_END_NOT_FOUND_ERROR_MSG);
        } else {
          showOrUpdateExistingErrorToast(toastId, getErrorMessage(err));
        }
        setConfirmLoading(false);
      }
    }, 50);  // the loading icon of the Confirm button in the modal does not always behave correctly
    // if the loading status is set to false too quickly, which may be an antd bug
  };

  const handleDeleteAssignedSession = (sessionRecord) => {
    setConfirmModalMode(CONFIRM_MODAL_MODE_DELETE_ASSIGNED_SESSION);
    openConfirmationModal(sessionRecord);
  };

  const handleDeleteLiveSession = (sessionRecord) => {
    setConfirmModalMode(CONFIRM_MODAL_MODE_DELETE_LIVE_SESSION);
    openConfirmationModal(sessionRecord);
  };

  const handleConfirmDeleteSession = async () => {
    if (!confirmSessionId) return;

    setConfirmLoading(true);
    setTimeout(async () => {
      try {
        await deleteSessionRecord(confirmSessionId);
        if (confirmModalMode === CONFIRM_MODAL_MODE_DELETE_ASSIGNED_SESSION) {
          setAssignedSessionRecords(prevRecords => prevRecords.filter(record => record.id !== confirmSessionId));
        } else {
          setLiveSessionRecords(prevRecords => prevRecords.filter(record => record.id !== confirmSessionId));
        }
        toast.success("Success", { autoClose: 600 });
        closeConfirmationModal();
      } catch (err) {
        const toastId = "delete-session-error-toast";
        showOrUpdateExistingErrorToast(toastId, getErrorMessage(err));
        setConfirmLoading(false);
      }
    }, 50);  // the loading icon of the Confirm button in the modal does not always behave correctly
    // if the loading status is set to false too quickly, which may be an antd bug
  };

  const openConfirmationModal = (sessionRecord) => {
    setConfirmSessionId(sessionRecord.id);
    setConfirmSessionName(sessionRecord.title);
    setConfirmModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmModalOpen(false);
    setConfirmLoading(false);
    setConfirmModalMode("");
    setConfirmSessionId("");
    setConfirmSessionName("");
  };

  const openRenameSessionModal = (sessionRecord) => {
    setSessionToRename(sessionRecord);
    setRenameModalOpen(true);
    newSessionName.current = sessionRecord.title;
  };

  const closeRenameModal = () => {
    setRenameModalOpen(false);
    setRenameLoading(false);
    setSessionToRename(null);
    newSessionName.current = "";
  };

  const handleRenameAssignedSession = (sessionRecord) => {
    setIsSessionToRenameAssigned(true);
    openRenameSessionModal(sessionRecord);
  };

  const handleRenameLiveSession = (sessionRecord) => {
    setIsSessionToRenameAssigned(false);
    openRenameSessionModal(sessionRecord);
  };

  const handleUpdateSessionName = async () => {
    if (!sessionToRename) return;

    const newSessionTitle = newSessionName.current.trim();
    if (!newSessionTitle) {
      showOrUpdateExistingInfoToast("empty-new-session-name-info-toast", "Session name cannot be empty");
      return;
    }
    if (newSessionTitle === sessionToRename.title) {
      showOrUpdateExistingInfoToast("session-name-not-modified-info-toast", "Session name is not changed");
      return;
    }

    setRenameLoading(true);
    setTimeout(async () => {
      try {
        const renamedSessionRecord = await renameSessionRecord(sessionToRename.id, newSessionTitle);
        if (isSessionToRenameAssigned) {
          setAssignedSessionRecords(prevRecords => getUpdatedRecordsAfterRename(prevRecords, renamedSessionRecord, true));
        } else {
          setLiveSessionRecords(prevRecords => getUpdatedRecordsAfterRename(prevRecords, renamedSessionRecord, false));
        }
        // toast.success("Success", { autoClose: 600 });
        closeRenameModal();
        setRecordToHighlight(renamedSessionRecord);
      } catch (err) {
        const toastId = "rename-session-error-toast";
        if (getErrorCode(err) === 404) {
          showOrUpdateExistingErrorToast(toastId, SESSION_TO_RENAME_NOT_FOUND_ERROR_MSG);
        } else {
          showOrUpdateExistingErrorToast(toastId, getErrorMessage(err));
        }
        setRenameLoading(false);
      }
    }, 50);  // the loading icon of the Confirm button in the modal does not always behave correctly
    // if the loading status is set to false too quickly, which may be an antd bug
  };

  const getUpdatedRecordsAfterRename = (prevRecords, renamedSessionRecord, isAssigned) => {
    const unsortedRecords = prevRecords.map(record => {
      if (record.id === sessionToRename.id) {
        return convertRecordModel(renamedSessionRecord);
      }
      return record;
    });

    if ((isAssigned && assignedSessionsSortOrder.current === SORT_ORDER_NAME) || (!isAssigned && liveSessionsSortOrder.current === SORT_ORDER_NAME)) {
      return sortRecordsByName(unsortedRecords);
    } else if ((isAssigned && assignedSessionsSortOrder.current === SORT_ORDER_NAME_REVERSE) || (!isAssigned && liveSessionsSortOrder.current === SORT_ORDER_NAME_REVERSE)) {
      return sortRecordsByNameReversed(unsortedRecords);
    } else {
      return unsortedRecords;
    }
  };

  const handleSortAssignedSessionRecords = (newOrder) => {
    assignedSessionsSortOrder.current = newOrder;

    switch (newOrder) {
    case SORT_ORDER_START_LATEST:
      setAssignedSessionRecords((prevRecords) => sortRecordsByStartTime(prevRecords));
      break;
    case SORT_ORDER_START_OLDEST:
      setAssignedSessionRecords((prevRecords) => sortRecordsByStartTimeReversed(prevRecords));
      break;
    case SORT_ORDER_NAME:
      setAssignedSessionRecords((prevRecords) => sortRecordsByName(prevRecords));
      break;
    case SORT_ORDER_NAME_REVERSE:
      setAssignedSessionRecords((prevRecords) => sortRecordsByNameReversed(prevRecords));
      break;
    }
  };

  const handleSortLiveSessionRecords = (newOrder) => {
    liveSessionsSortOrder.current = newOrder;

    switch (newOrder) {
    case SORT_ORDER_START_LATEST:
      setLiveSessionRecords((prevRecords) => sortRecordsByStartTime(prevRecords));
      break;
    case SORT_ORDER_START_OLDEST:
      setLiveSessionRecords((prevRecords) => sortRecordsByStartTimeReversed(prevRecords));
      break;
    case SORT_ORDER_NAME:
      setLiveSessionRecords((prevRecords) => sortRecordsByName(prevRecords));
      break;
    case SORT_ORDER_NAME_REVERSE:
      setLiveSessionRecords((prevRecords) => sortRecordsByNameReversed(prevRecords));
      break;
    }
  };

  const handleNewSessionNameChange = (e) => {
    newSessionName.current = e.target.value;
  };

  const header = (
    <ClassroomNavBar />
  );

  let body;
  if (recordsLoading) {
    body = <LoadingSpinBody />;
  } else if (recordsLoadingFailed) {
    body = <ErrorBody message="Failed to load history." />;
  } else {
    const collapseItems = [
      {
        key: "1",
        label: "Assigned Sessions",
        children: <ClassroomTestRecordList records={assignedSessionRecords} onEndClick={handleEndSession}
                                           onDeleteClick={handleDeleteAssignedSession}
                                           onRenameClick={handleRenameAssignedSession}
                                           onSortOrderChange={handleSortAssignedSessionRecords}
                                           recordToHighlight={recordToHighlight} />,
        style: { marginBottom: 20 },
      },
      {
        key: "2",
        label: "Live Sessions",
        children: <ClassroomTestRecordList records={liveSessionRecords} onEndClick={handleEndSession}
                                           onDeleteClick={handleDeleteLiveSession}
                                           onRenameClick={handleRenameLiveSession}
                                           onSortOrderChange={handleSortLiveSessionRecords}
                                           recordToHighlight={recordToHighlight} />,
      },
    ];

    body = (
      <div className={styles.body}>
        <ConfirmationModal
          title={confirmModalMode === CONFIRM_MODAL_MODE_END_SESSION ? `Confirm end session "${confirmSessionName}"` : `Confirm delete session "${confirmSessionName}"`}
          message={confirmModalMode === CONFIRM_MODAL_MODE_END_SESSION ? END_SESSION_CONFIRM_MESSAGE : DELETE_SESSION_CONFIRM_MESSAGE}
          open={confirmModalOpen}
          loading={confirmLoading}
          onCancel={closeConfirmationModal}
          onConfirm={confirmModalMode === CONFIRM_MODAL_MODE_END_SESSION ? handleConfirmEndSession : handleConfirmDeleteSession}
          dangerConfirmButton={confirmModalMode !== CONFIRM_MODAL_MODE_END_SESSION}
        />

        <ConfirmationModal title={`Rename session "${sessionToRename?.title}"`}
                           message={(
                             <div className={styles["name-input"]}>
                               <Input size="large"
                                      variant="borderless"
                                      placeholder="Enter a new name for the session"
                                      defaultValue={sessionToRename?.title}
                                      maxLength={50}
                                      onChange={handleNewSessionNameChange}
                                      className={styles["name-input-text"]}
                               />
                             </div>
                           )}
                           open={renameModalOpen}
                           loading={renameLoading}
                           onCancel={closeRenameModal}
                           onConfirm={handleUpdateSessionName}
        />

        <ConfigProvider
          theme={{
            token: {
              colorTextHeading: "black",
              fontSize: 20,
              padding: 0,
              motionDurationSlow: 0.2,
            },
          }}
        >
          <Collapse defaultActiveKey={["1", "2"]} ghost size="large" items={collapseItems}
                    style={{ fontWeight: 600 }} />
        </ConfigProvider>
      </div>
    );
  }

  return (
    <PageLayout header={header} body={body} displayMode={"normal"} showNUSLogos={true} />
  );
};

export default ClassroomHistoryPage;
