import React, { useEffect, useState } from "react";
import PageLayout from "./PageLayout";
import { useSearchParams } from "react-router-dom";
import { getSessionResults } from "../../apiHandlers/classroomTestSessionApiHandler";
import ClassroomTestSummary from "../components/ClassroomTestSummary";
import styles from "./ClassroomTestResultsPage.module.scss";
import { DateTime } from "luxon";
import LoadingSpinBody from "../components/LoadingSpinBody";
import ErrorBody from "../components/ErrorBody";
import { getErrorCode } from "../../apiHandlers/apiUtils";
import { UNAUTHORIZED_ERROR_MSG } from "../../constants/error";
import ClassroomTestParticipantsTable from "../components/ClassroomTestParticipantsTable";

const SESSION_RECORD_NOT_FOUND_ERROR_MSG = "Session record not found. It may have been deleted.";

const ClassroomTestResultsPage = () => {
  const [sessionResult, setSessionResult] = useState(null);
  const [sessionRecord, setSessionRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");

  useEffect(() => {
    if (!sessionId) {
      setErrorMessage(SESSION_RECORD_NOT_FOUND_ERROR_MSG);
      setLoading(false);
      setLoadingFailed(true);
      return;
    }

    const fetchData = async () => {
      try {
        const sessionResult = await getSessionResults(sessionId);
        setSessionResult(sessionResult);
        setLoading(false);
      } catch (err) {
        const errorCode = getErrorCode(err);
        if (errorCode === 403) {
          setErrorMessage(UNAUTHORIZED_ERROR_MSG);
        } else if (errorCode === 404 || errorCode === 400) {
          setErrorMessage(SESSION_RECORD_NOT_FOUND_ERROR_MSG);
        }
        setLoading(false);
        setLoadingFailed(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (sessionResult) {
      setSessionRecord(sessionResult.sessionRecord);
    }
  }, [sessionResult]);

  const Header = () => {
    if (sessionRecord) {
      return (
        <div className={styles.header}>
          <div className={styles["test-title"]}>{sessionRecord.title}</div>
          <div className={styles["test-start-time"]}>
            {/*TODO: Extract into util!*/}
            Start
            Time: {sessionRecord.startedAt ? DateTime.fromMillis(sessionRecord.startedAt).toLocaleString(DateTime.DATETIME_MED) : "--"}
          </div>
        </div>
      );
    }
  };

  const Body = () => {
    if (loading) {
      return <LoadingSpinBody />;
    } else if (loadingFailed) {
      return <ErrorBody fullMessage={errorMessage} />;
    } else {
      return (
        <div className={styles.body}>
          <div className={styles["question-results-container"]}>
            <div className={styles["container-title"]}>Question Results</div>
            <ClassroomTestSummary sessionResult={sessionResult} pageTitle="Question Results" showPageTitle={false} />
          </div>
          <div className={styles["participants-container"]}>
            <div className={styles["container-title"]}>Participants</div>
            <ClassroomTestParticipantsTable participants={sessionResult.participants} />
          </div>
        </div>
      );
    }
  };

  return (<PageLayout header={<Header />} body={<Body />} displayMode={"normal"} showNUSLogos={false} />);
};

export default ClassroomTestResultsPage;
