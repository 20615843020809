import { Button, Modal, Rate } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styles from "./ClassroomFeedback.module.scss";
import TextArea from "antd/es/input/TextArea";
import {
  submitClassroomFeedbackForStudent,
  submitClassroomFeedbackForTeacher,
} from "../../apiHandlers/classroomFeedbackApiHandler";
import { CLASSROOM_STUDENT_FEEDBACK_SUBMITTED } from "../../constants/api";

const ClassroomFeedback = ({
                             userType,
                             userId,
                             isSurveyModalOpen,
                             closeModal,
                             callbackOnSuccessfulSubmit = () => {},
                           }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setData((prevData) => ({ ...prevData, userType, userId }));
  }, []);

  const submitData = async (submitByClosingPanel) => {
    if (isSubmitting) {
      return;
    }

    const isFormFilled = Object.keys(data).some(key => key !== "userType" && key !== "userId");
    if (!isFormFilled) {
      return;
    }

    setIsSubmitting(true);
    try {
      if (userType === "STUDENT") {
        await submitClassroomFeedbackForStudent(data);
        toast.success("Thank you for your feedback! Happy Learning! :)", { autoClose: 1000 });
        localStorage.setItem(CLASSROOM_STUDENT_FEEDBACK_SUBMITTED, "true");
      } else {
        await submitClassroomFeedbackForTeacher(data);
        toast.success("Thank you for your feedback! :)", { autoClose: 1000 });
      }
      setIsSubmitting(false);
      callbackOnSuccessfulSubmit();
      return true;
    } catch (error) {
      if (!submitByClosingPanel) {
        toast.error("Oops, we can't submit the survey right now. Please check your Internet connection or try again later :(", { autoClose: 4000 });
      }
    }
    setIsSubmitting(false);
    return false;
  };

  const handleClosePanel = async () => {
    // immediately close modal
    closeModal();
    if (userType === "STUDENT") {
      await submitData(true);
    }
  };

  const handleSubmitClicked = async () => {
    const success = await submitData(false);
    if (success) {
      closeModal();
    }
  };

  return (
    <Modal
      open={isSurveyModalOpen}
      footer={null}
      onOk={handleClosePanel}
      onCancel={handleClosePanel}
      centered
      width={850}
    >
      <div className={styles.surveyPanel}>
        <h3 style={{ fontSize: 18, color: "#004AAD" }}>🌟 Thank you a lot for using Pin It!</h3>
        <h3 style={{ fontSize: 18, color: "#004AAD" }}>
          Your feedback is invaluable in helping us improve this app. 🚀
        </h3>

        <p className={styles.shareSubheading}>
          (Optional) How would you rate your overall experience with this app?
        </p>
        <p className={styles.shareSubheading} style={{ fontWeight: 400 }}>
          (1 - Very poor, 5 - Excellent)
        </p>
        <Rate style={{ color: "#ffbd03", fontSize: 40 }} className={styles.madeCenter} allowClear={false}
              onChange={(value) => setData((prevData) => ({ ...prevData, overallExperience: value }))}
        />

        <p className={styles.shareSubheading}>
          {
            userType === "STUDENT"
              ? (<>(Optional) Did you encounter any issues during the game, or do you have any suggestions for
                improvement?</>)
              : (<>(Optional) Did you encounter any issues when using the app, or do you have any suggestions for
                improvement?</>)
          }
        </p>
        <TextArea style={{ fontSize: 20 }} className={styles.madeCenter}
                  maxLength={980} autoSize={{ minRows: 2 }}
                  onChange={(e) => setData((prevData) => ({ ...prevData, feedback: e.target.value }))}
        />

        <div className={styles.submitButtonArea}>
          <Button type="primary" size={"large"} onClick={handleSubmitClicked} loading={isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ClassroomFeedback;
