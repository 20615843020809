import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import ClassroomTestRecordItem from "./ClassroomTestRecordItem";
import styles from "./ClassroomTestRecordList.module.scss";
import { ConfigProvider, Select } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export const SORT_ORDER_START_LATEST = "start-latest";
export const SORT_ORDER_START_OLDEST = "start-oldest";
export const SORT_ORDER_NAME = "name";
export const SORT_ORDER_NAME_REVERSE = "name-reverse";

function ClassroomTestRecordList({
                                   records,
                                   onEndClick,
                                   onDeleteClick,
                                   onRenameClick,
                                   onSortOrderChange,
                                   recordToHighlight,
                                 }) {
  const recordRefs = useRef({});

  const [highlightedRecord, setHighlightedRecord] = useState(null);

  useEffect(() => {
    if (recordToHighlight) {
      const recordRef = recordRefs.current[recordToHighlight.id];
      if (recordRef) {
        recordRef.scrollIntoView({ behavior: "smooth", block: "center" });
      }

      setHighlightedRecord(recordToHighlight);
      const timer = setTimeout(() => {
        setHighlightedRecord(null);
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [recordToHighlight]);

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  return (
    <div>
      {
        records.length > 0
        &&
        <div className={styles["control-panel"]}>
          <div className={styles["text-sort-container"]}>
            Sort by:
          </div>
          <div className={styles["btn-sort-container"]}>
            <ConfigProvider theme={{
              components: {
                Select: {
                  optionFontSize: lg ? 20 : 16,
                },
              },
              token: {
                fontSizeLG: 20,
                fontSize: 16,
              },
            }}>
              <Select
                size={sm ? "large" : "default"}
                defaultValue="start-latest"
                onChange={onSortOrderChange}
                options={[
                  {
                    value: SORT_ORDER_START_LATEST,
                    label: "Start time (latest first)",
                  },
                  {
                    value: SORT_ORDER_START_OLDEST,
                    label: "Start time (oldest first)",
                  },
                  {
                    value: SORT_ORDER_NAME,
                    label: "Name (A to Z)",
                  },
                  {
                    value: SORT_ORDER_NAME_REVERSE,
                    label: "Name (Z to A)",
                  },
                ]}
                style={{
                  width: "100%",
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      }
      {/*TODO: Add no record message */}
      <AnimatePresence>
        {records.map(record => {
          const isHighlighted = highlightedRecord && record.id === highlightedRecord.id;
          return (
            <motion.div key={record.id} layout={true}
                        initial={{ opacity: 0, x: 0, y: -10 }}
                        animate={{ opacity: 1, x: 0, y: 0 }}
                        exit={{ opacity: 0, x: 0, y: 10 }}
                        transition={{ duration: 0.5 }}
                        ref={el => (recordRefs.current[record.id] = el)}>
              <ClassroomTestRecordItem record={record} onEndClick={onEndClick} onDeleteClick={onDeleteClick}
                                       onRenameClick={onRenameClick} highlight={isHighlighted} />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
}

export default ClassroomTestRecordList;
