import React from "react";
import { MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_QUIZ_SESSION } from "../../constants/api";
import styles from "./ClassroomGameSummary.module.scss";
import ImageGenerator from "./ImageGenerator";
import ClassroomGameSummaryNotificationArea from "./ClassroomGameSummaryNotificationArea";

const ClassroomGameSummary = ({ performanceData, gameQuestions, participantInfo }) => {
  return (
    <div className={styles.body}>
      <div className={styles["main-container"]}>
        <div className={styles["image-container"]}>
          {/*TODO: Investigate whether this is necessary*/}
          {
            // performanceData is null of DragAndDropGame component is not rendered at all
            !performanceData
              ?
              <div className={styles["game-miss-text"]}>
                <h2>
                  You may have missed the game this time, but keep going — you're making progress!
                </h2>
              </div>
              :
              <ImageGenerator {...performanceData}
                              gameContent={gameQuestions}
                              totalRoundCount={gameQuestions.length} />
          }
        </div>
        <ClassroomGameSummaryNotificationArea referer={MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_QUIZ_SESSION}
                                              uid={participantInfo.userId} />
      </div>
    </div>
  );
};

export default ClassroomGameSummary;
