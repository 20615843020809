import { ConfigProvider, Spin } from "antd";
import React from "react";
import styles from "./LoadingSpinBody.module.scss";

const LoadingSpinBody = ({ height = "85vh" }) => {  // "85vh" fits into the entire blank space of body in PageLayout
  return (
    <div className={styles["body-loading"]} style={{ height }}>
      <ConfigProvider
        theme={{
          components: {
            Spin: {
              dotSizeLG: 40,
            },
          },
        }}
      >
        {/*TODO: Use custom component to add tip*/}
        <Spin size="large" />
      </ConfigProvider>
    </div>
  );
};

export default LoadingSpinBody;
