import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getErrorCode } from "../../apiHandlers/apiUtils";
import { validateRoomNumber } from "../../apiHandlers/classroomTestSessionApiHandler";
import { NETWORK_CONNECTION_ERROR_MSG } from "../../constants/error";
import {
  completeSession,
  joinSessionWithNickname,
  listenToIndividualSessionQuestions,
  listenToJoinStatus,
  registerNewOnWebSocketCloseOrError,
  requestSessionQuestions,
  saveSessionProgress,
  socketConnect,
  socketDisconnect,
} from "../../services/classroomTestWebSocketService";
import { openFullScreenErrorModal } from "../../utils/ModalUtils";
import ClassroomGameSummary from "../components/ClassroomGameSummary";
import ClassroomTestJoin from "../components/ClassroomTestJoin";
import DragAndDropGame from "../components/DragAndDropGame";
import LoadingSpinBody from "../components/LoadingSpinBody";
import { WEBSOCKET_CONNECTION_ERROR_CONTENT, WEBSOCKET_CONNECTION_ERROR_TITLE } from "./ClassroomTestLiveOwnerPage";
import PageLayout from "./PageLayout";
import { PT_PAGE_STATUS_JOIN_NAME } from "./ClassroomTestLiveParticipantPage";
import styles from "./ClassroomTestAssignmentPage.module.scss";
import { Button, ConfigProvider } from "antd";

const AS_PAGE_STATUS_LOADING = "assignment page loading";
const AS_PAGE_STATUS_ERROR = "assignment page error";
const AS_PAGE_STATUS_JOIN_NAME = "assignment join enter nickname";
const AS_PAGE_STATUS_GAME_IP = "assignment game in progress";
const AS_PAGE_STATUS_GAME_OVER = "assignment game over";

// TODO: There are two concepts here, one is room, the other is assignment
//  even if room is destroyed, if user has the assignment id, he/she may still use it to review the assignment
const ROOM_VALIDATE_NOT_AVAILABLE_ERROR_MESSAGE = "Oops! It seems the assignment has either gone on a little vacation or completed its mission... If puzzled, a quick solution awaits — reach out to the assigner! 🧩✨";
const ROOM_VALIDATE_UNKNOWN_ERROR_MESSAGE = NETWORK_CONNECTION_ERROR_MSG;

const ClassroomTestAssignmentPage = () => {
  const [pageStatus, setPageStatus] = useState("");
  const [errorPageMessage, setErrorPageMessage] = useState("");
  const [testTitle, setTestTitle] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [participantInfo, setParticipantInfo] = useState("");
  const [gameQuestions, setGameQuestions] = useState([]);
  const [questionsPerformanceData, setQuestionsPerformanceData] = useState(null);
  const [executeCompleteSession, setExecuteCompleteSession] = useState(false);

  const hasSessionCompleted = useRef(false);

  // TODO: Make stompClient a local state

  const questionsPerformanceDataRef = useRef(null);
  useEffect(() => {
    questionsPerformanceDataRef.current = questionsPerformanceData;
  }, [questionsPerformanceData]);

  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");

  const navigate = useNavigate();

  const handleBeforeUnload = useCallback((e) => {
    e.preventDefault();
    // TODO: make custom message working
    // e.returnValue = "Are you sure you want to exit?";
  }, []);

  const handleVisibilityChange = useCallback(() => {
    // Save user's latest progress on visibility change
    if (!hasSessionCompleted.current) {
      const sessionData = getLatestSessionData();
      if (sessionData) {
        saveSessionProgress(sessionData);
      }
    }
  }, []);

  const getLatestSessionData = () => {
    const performanceData = questionsPerformanceDataRef.current;
    return performanceData ? { questionAttempts: performanceData.questionAttempts } : null;
  };

  useEffect(() => {
    if (!roomId) {
      setErrorPageMessage(ROOM_VALIDATE_NOT_AVAILABLE_ERROR_MESSAGE);
      setPageStatus(AS_PAGE_STATUS_ERROR);
      return;
    }

    setPageStatus(AS_PAGE_STATUS_LOADING);

    enterRoom(roomId);

    // This is NEVER executed because this page is currently not a child of any parent component
    return () => {
      socketDisconnect();
      // window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (executeCompleteSession) {
      handleCompleteSession();
    }
  }, [executeCompleteSession]);

  // called after participant enters room number
  const enterRoom = async (roomNumber) => {
    if (!roomNumber) {
      console.assert(false, "enterRoom expects a valid roomNumber");
      setPageStatus(AS_PAGE_STATUS_ERROR);
      return;
    }

    try {
      const sessionInfo = await validateRoomNumber(roomNumber);
      if (!sessionInfo || sessionInfo.sessionType !== "ASSIGNED") {
        setErrorPageMessage(ROOM_VALIDATE_NOT_AVAILABLE_ERROR_MESSAGE);
        setPageStatus(AS_PAGE_STATUS_ERROR);
        return;
      }

      setTestTitle(sessionInfo.quizTitle);
      setSessionId(sessionInfo.sessionId);
      setPageStatus(AS_PAGE_STATUS_JOIN_NAME);
    } catch (err) {
      if (getErrorCode(err) === 404) {
        setErrorPageMessage(ROOM_VALIDATE_NOT_AVAILABLE_ERROR_MESSAGE);
        setPageStatus(AS_PAGE_STATUS_ERROR);
      } else {
        setErrorPageMessage(ROOM_VALIDATE_UNKNOWN_ERROR_MESSAGE);
        setPageStatus(AS_PAGE_STATUS_ERROR);
      }
    }
  };

  const onWebSocketCloseOrErrorDuringInitialJoin = () => {
    setErrorPageMessage(ROOM_VALIDATE_UNKNOWN_ERROR_MESSAGE);
    setPageStatus(AS_PAGE_STATUS_ERROR);
  };

  const onWebSocketCloseOrErrorAfterInitialJoin = () => {
    if (!hasSessionCompleted.current) {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      openFullScreenErrorModal(WEBSOCKET_CONNECTION_ERROR_TITLE, WEBSOCKET_CONNECTION_ERROR_CONTENT, true, "Refresh", "");
    }
  };

  const connectWebSocket = (onConnectCallback) => {
    const onConnect = () => {
      setTimeout(() => {
        onConnectCallback();
      }, 500);
    };

    socketConnect(sessionId, false, onConnect, onWebSocketCloseOrErrorDuringInitialJoin);
  };

  const joinWithNickname = (nickname, callback) => {
    if (!sessionId || !nickname) {
      console.assert(false, "enterNickname expects valid sessionId and nickname");
      callback();
      return;
    }

    listenToJoinStatus(sessionId, (participantInfo) => {
      listenToIndividualSessionQuestions((result) => {
        if (!result.hasSessionEnded) {
          if (!result.gameQuestions) {
            // Session has not started
            // Even though this is very unlikely to happen as connectWebSocket would have failed already due to session not joinable
            setErrorPageMessage(ROOM_VALIDATE_NOT_AVAILABLE_ERROR_MESSAGE);
            setPageStatus(AS_PAGE_STATUS_ERROR);
            callback();
          } else {
            // Session is in progress, start the game immediately
            setGameQuestions(result.gameQuestions);
            registerNewOnWebSocketCloseOrError(onWebSocketCloseOrErrorAfterInitialJoin);
            window.addEventListener("beforeunload", handleBeforeUnload);
            document.addEventListener("visibilitychange", handleVisibilityChange);
            setPageStatus(AS_PAGE_STATUS_GAME_IP);
            callback();
          }
        } else {
          // Session has ended
          // Even though this is very unlikely to happen as connectWebSocket would have failed already due to session not joinable
          setErrorPageMessage(ROOM_VALIDATE_NOT_AVAILABLE_ERROR_MESSAGE);
          setPageStatus(AS_PAGE_STATUS_ERROR);
          callback();
        }
      });

      setParticipantInfo(participantInfo);
      requestSessionQuestions();
    });

    joinSessionWithNickname(nickname);
  };

  // called after participant enters nickname
  const enterNickname = (nickname, callback) => {
    // Two steps: 1. Connect WebSocket  2. Join with nickname
    connectWebSocket(() => {joinWithNickname(nickname, callback);});
  };

  const handlePerformanceDataUpdate = (performanceData, isLastQuestionCompleted, toNextPageClicked) => {
    // TODO: update page status only when data sent successfully!
    if (toNextPageClicked) {
      setPageStatus(AS_PAGE_STATUS_GAME_OVER);
      return;
    }

    // TODO: Send performance data [per question] here to server using socket

    setQuestionsPerformanceData(performanceData);

    if (isLastQuestionCompleted) {
      setExecuteCompleteSession(true);
    }
  };

  // called after participant completes the last question
  const handleCompleteSession = () => {
    // Session result must be only reported once
    if (hasSessionCompleted.current) return;
    hasSessionCompleted.current = true;

    const sessionData = getLatestSessionData();
    if (sessionData) {
      completeSession(sessionData);  // TODO: need to handle error? network error?
    } else {
      // TODO: This may never happen
      completeSession();
    }
  };

  const refreshPage = () => {
    setPageStatus(AS_PAGE_STATUS_LOADING);
    setTimeout(() => {navigate(0);}, 600);
  };

  const ErrorPageBody = () => (
    <div className={styles["error-page-body"]}>
      <div className={styles["error-page-msg"]}>{errorPageMessage}</div>
      <div className={styles["btn-refresh"]}>
        <ConfigProvider theme={{
          components: {
            Button: {
              colorPrimary: "#02924D",
              contentFontSizeLG: 20,
              algorithm: true,
            },
          },
        }}>
          <Button type="default" block size="large" onClick={refreshPage}>Refresh</Button>
        </ConfigProvider>
      </div>
    </div>
  );

  const body = () => {
    if (pageStatus === AS_PAGE_STATUS_LOADING) {
      return <LoadingSpinBody />;
    } else if (pageStatus === AS_PAGE_STATUS_ERROR) {
      return <ErrorPageBody />;
    } else if (pageStatus === AS_PAGE_STATUS_JOIN_NAME) {
      return <ClassroomTestJoin testTitle={testTitle} isAssignedSession={true}
                                participantInfo={participantInfo} joinStatus={PT_PAGE_STATUS_JOIN_NAME}
                                enterRoom={() => {}} enterNickname={enterNickname} />;
    } else if (pageStatus === AS_PAGE_STATUS_GAME_IP) {
      return <DragAndDropGame gameContent={gameQuestions}
                              maxRoundCount={gameQuestions.length}  // TODO: redundant
                              gameMode={2}  // TODO: Change game mode to constant
                              onPerformanceDataUpdate={handlePerformanceDataUpdate} />;
    } else if (pageStatus === AS_PAGE_STATUS_GAME_OVER) {
      return <ClassroomGameSummary performanceData={questionsPerformanceData} gameQuestions={gameQuestions}
                                   participantInfo={participantInfo} />;
    }
  };

  const displayMode = pageStatus === AS_PAGE_STATUS_GAME_IP ? "overlay" : "normal";

  return (
    <PageLayout body={body()} displayMode={displayMode} showNUSLogos={displayMode === "normal"} />
  );
};

export default ClassroomTestAssignmentPage;
