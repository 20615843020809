import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import styles from "./ClassroomNavBar.module.scss";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { slide as SlideMenu } from "react-burger-menu";

function ClassroomNavBar() {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const { user } = useAuth();

  const { sm } = useBreakpoint();

  const MenuNavLinks = () => (
    <>
      <div className={styles["navbar-item"]} hidden={!user.isAdmin}>
        <NavLink to="/classroom/admin"
                 className={({ isActive }) => isActive ? styles["item-link-active"] : styles["item-link"]}
        >
          Admin
        </NavLink>
      </div>
      <div className={styles["navbar-item"]}>
        <NavLink to="/classroom/tests"
                 className={({ isActive }) => isActive ? styles["item-link-active"] : styles["item-link"]}
        >
          Tests
        </NavLink>
      </div>
      <div className={styles["navbar-item"]}>
        <NavLink to="/classroom/history"
                 className={({ isActive }) => isActive ? styles["item-link-active"] : styles["item-link"]}
        >
          History
        </NavLink>
      </div>
      <div className={styles["navbar-item"]}>
        <NavLink to="/classroom/account"
                 className={({ isActive }) => isActive ? styles["item-link-active"] : styles["item-link"]}
        >
          Account
        </NavLink>
      </div>
    </>
  );

  if (sm) {
    return (
      <nav className={styles.navbar}>
        <MenuNavLinks />
      </nav>
    );
  } else {
    return (
      <>
        <SlideMenu right
                   width={150}
                   customBurgerIcon={false}
                   customCrossIcon={false}
                   isOpen={burgerMenuOpen}
                   menuClassName={styles["bm-menu"]}
                   itemListClassName={styles["bm-item-list"]}
                   styles={{ bmMenuWrap: { top: 0 }, bmOverlay: { top: 0 } }}
                   onClose={() => {setBurgerMenuOpen(false);}}
        >
          <CloseOutlined className={styles["cross-button"]} onClick={() => {setBurgerMenuOpen(false);}} />
          <MenuNavLinks />
        </SlideMenu>

        <div className={styles.navbar}>
          <MenuOutlined className={styles["burger-button"]} onClick={() => {setBurgerMenuOpen(open => !open);}} />
        </div>
      </>
    );
  }

}

export default ClassroomNavBar;
