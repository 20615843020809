import { TrophyFilled } from "@ant-design/icons";
import { ConfigProvider, Progress } from "antd";
import { toJpeg, toPng } from "html-to-image";
import React, { useEffect, useRef, useState } from "react";
import styles from "./ImageGenerator.module.scss";


function ImageGenerator({
                          questionAttempts,
                          progressBarColours,
                          setBase64Image,
                          playMode,
                          isDownloadButtonClicked,
                          setIsDownloadButtonClicked,
                        }) {
  const elementRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imageTypeToGenerate, setImageTypeToGenerate] = useState(0);

  // States used for type 2 image only
  const [totalTimeTaken, setTotalTimeTaken] = useState(0);
  const [totalCharactersSolved, setTotalCharactersSolved] = useState(0);


  useEffect(() => {
    // Update windowWidth whenever the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /**
   * Todo: Create different image types and find the best to generate
   */
  function computeMostSuitableType() {
    setImageTypeToGenerate(2);
  }

  useEffect(() => {
    computeMostSuitableType();
  }, []);


  /**
   * To process the questionAttempts if most suitable image type is 2.
   */
  useEffect(() => {
    if (imageTypeToGenerate === 2 && questionAttempts !== null) {
      let tempTimeTaken = 0;
      let tempCharactersSolved = 0;
      for (const questionAttempt of questionAttempts) {
        tempTimeTaken += questionAttempt.timeTaken;
        if (questionAttempt.status === "CORRECT") {
          tempCharactersSolved += 1;
        }
      }
      setTotalTimeTaken(tempTimeTaken);
      setTotalCharactersSolved(tempCharactersSolved);
    }
  }, [imageTypeToGenerate]);

  /**
   * Convert generated image from HTML into PNG file and download it
   * Reference: https://github.com/bubkoo/html-to-image
   */
  const htmlToImageConvertAndDownload = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "share_image.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Convert generated image from HTML into JPEG format,
   * and return the JPEG image in Base64.
   * Reference: https://github.com/bubkoo/html-to-image
   */
  const htmlToJpegConvert = async () => {
    try {
      const dataUrl = await
        toJpeg(elementRef.current, { quality: 0.5 })
          .then(function (dataUrl) {
            return dataUrl;
          });
      return dataUrl;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Download button is triggered (state is passed from parent component)
   * Trigger download and then reset the state "isDownloadButtonClicked" to false once download is completed
   */
  useEffect(() => {
    const fetchData = async () => {
      if (isDownloadButtonClicked) {
        await htmlToImageConvertAndDownload();
        if (setIsDownloadButtonClicked) {
          setIsDownloadButtonClicked(false);
        }
      }
    };

    fetchData();
  }, [isDownloadButtonClicked]);

  /**
   * Get the base64 of the image generated after it is converted into jpeg.
   * Then pass the base64 value to the parent through "setBase64Image".
   * This is used to allow parent to upload the image to storage when needed.
   *
   * Although an alternative way is to use useLayoutEffect to set the base64 when component is rendered,
   * but since the charactersSolved and totalTimeTaken is in a state, the component is considered rendered before
   * they are updated, thus making the base64Image returned to be inaccurate of the two states.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (setBase64Image) {
          const base64Image = await htmlToJpegConvert();
          setBase64Image(base64Image);
          console.log("Image converted: " + base64Image);
        }
      } catch (error) {
        // Handle errors
        console.error("Error converting HTML to JPEG:", error);
      }
    };

    if (totalTimeTaken !== 0 && totalCharactersSolved !== 0) {
      fetchData();
    }
  }, [totalTimeTaken, totalCharactersSolved]);

  // TODO: IS THIS STILL NEEDED?
  // Type 1: With most difficult character retrieved to show off
  function retrieveType1Image() {
    return <>
      {/*
      <div className={`${styles.generatedImageWindowBackground} ${styles.image1}`}>

      </div>
      <div className={styles.characterDescription}>
        Pinyin: {gameContent[roundSelected].chinese_character.pinyin}<br />
        Meaning: {gameContent[roundSelected].chinese_character.meaning}<br />
        Example: {gameContent[roundSelected].chinese_character.word} ({gameContent[roundSelected].chinese_character.word_pinyin}), {gameContent[roundSelected].chinese_character.word_meaning}<br />
      </div>
      <div className={styles.dropBox}>
        <img src={`${CCL_ASSETS_PATH}${gameContent[roundSelected].chinese_character.character_svg_path}`}
             className={`${styles.stackedContent} ${styles.structureImage}`}
             alt="structure" />
        <img
          src={`${CCL_ASSETS_PATH}structure_svg/${gameContent[roundSelected].chinese_character.structure_type}.svg`}
          className={`${styles.stackedContent} ${styles.structureImage}`}
          alt="structure" />
      </div>
      <div className={styles.callForAction}>
        I had solved a difficult character “{gameContent[roundSelected].chinese_character.chinese}” in Pin
        It!<br />
        Challenge your Chinese proficiency now!
      </div>
      */}
    </>;
  }

  // Type 2: With speed shown in image to challenge reader
  function retrieveType2Image() {
    return <>
      <div className={`${styles.generatedImageWindowBackground} ${styles.image2}`}>
      </div>
      <div className={`${styles.generatedImageWindowContent}`}>
        <div className={styles.type2TrophyBox}>
          <TrophyFilled className={styles.type2Trophy} />
        </div>
        <div className={styles.type2CallForAction}>
          <b className={styles.type2CallForActionEmphasis}>{totalTimeTaken}</b> seconds speedrun and <b
          className={styles.type2CallForActionEmphasis}>{totalCharactersSolved}</b> Chinese characters solved! <br />
          <b
            className={styles.type2CallForActionEmphasis}>{playMode === "quickplay" ? "Can you beat my speed?" : ""}</b>
        </div>
        <div className={styles.type2ProgressBarBox}>
          <div>
            {questionAttempts.map((item, index) => (
              <div key={index} className={styles.type2ProgressBarCharacters}>
                {item.characterDetails.chinese}
              </div>
            ))}
          </div>
          <ConfigProvider
            theme={{
              components: {
                Progress: {
                  defaultColor: "lightgray",
                },
              },
            }}
          >
            <Progress percent={100} steps={questionAttempts.length}
                      strokeColor={progressBarColours}
                      size={windowWidth <= 410 ? [25, 18] : (windowWidth <= 536 ? [30, 18] : [40, 18])} status="active"
                      showInfo={false} className={styles.type2ProgressBar} />
          </ConfigProvider>
        </div>

        <div className={styles.type2CallForAction}>
          {playMode === "quickplay" ? (
            <>Assemble these characters yourselves at <i
              className={styles.type2CallForActionLink}>pinit-nus.com</i>
            </>) : (
            <>Try more character yourselves at our mobile app!
            </>
          )}

        </div>
      </div>
    </>;
  }

  return (
    <div className={styles.generatedImageWindow} ref={elementRef}>
      <div className={styles.generatedImageWindowFill}>
        {retrieveType2Image()}
      </div>
    </div>
  );
  /*
  return (
    <div className={styles.generatedImageWindow} ref={elementRef}>
      <div className={styles.generatedImageWindowFill}>
        {imageTypeToGenerate === 1 ? retrieveType1Image() : retrieveType2Image()}
      </div>
    </div>
  );
   */
}

export default ImageGenerator;
