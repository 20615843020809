import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { BrowserView } from "react-device-detect";
import { logMobileAppDownloadClickEvent } from "../../apiHandlers/logApiHandler";
import appStoreBadge from "../../assets/app-store-badge.svg";
import downloadApkBadge from "../../assets/download-apk-badge.png";
import playStoreBadge from "../../assets/play-store-badge.png";
import {
  MOBILE_APP_DOWNLOAD_EVENT_BADGE_TYPE_ANDROID_APK,
  MOBILE_APP_DOWNLOAD_EVENT_BADGE_TYPE_APPLE_APP_STORE,
  MOBILE_APP_DOWNLOAD_EVENT_BADGE_TYPE_GOOGLE_PLAY_STORE,
  MOBILE_APP_DOWNLOAD_EVENT_TYPE_STORE_BADGE_CLICK,
} from "../../constants/api";
import styles from "./MobileAppBadges.module.scss";

function MobileAppBadges({
                           horizontal = true,
                           disableQRCode = true,
                           customAppStoreLink,
                           customPlayStoreLink,
                           refererList,
                           uid,
                         }) {
  const appStoreLink = customAppStoreLink ? customAppStoreLink :
    "https://apps.apple.com/sg/app/pin-it/id1606613346?platform=iphone";
  const playStoreLink = customPlayStoreLink ? customPlayStoreLink :
    "https://play.google.com/store/apps/details?id=com.ccl.ChineseCharacterLearner";

  if (!horizontal) disableQRCode = true;

  const logPlayStoreBadgeClick = async () => {
    await logMobileAppDownloadClickEvent(MOBILE_APP_DOWNLOAD_EVENT_TYPE_STORE_BADGE_CLICK, refererList, MOBILE_APP_DOWNLOAD_EVENT_BADGE_TYPE_GOOGLE_PLAY_STORE, uid);
  };

  const logAppStoreBadgeClick = async () => {
    await logMobileAppDownloadClickEvent(MOBILE_APP_DOWNLOAD_EVENT_TYPE_STORE_BADGE_CLICK, refererList, MOBILE_APP_DOWNLOAD_EVENT_BADGE_TYPE_APPLE_APP_STORE, uid);
  };

  const logAndroidApkBadgeClick = async () => {
    await logMobileAppDownloadClickEvent(MOBILE_APP_DOWNLOAD_EVENT_TYPE_STORE_BADGE_CLICK, refererList, MOBILE_APP_DOWNLOAD_EVENT_BADGE_TYPE_ANDROID_APK, uid);
  };

  return (
    <div className={horizontal ? styles["main-container"] : styles["main-container-vertical"]}>
      <div className={horizontal ? styles["badge-container"] : styles["badge-container-vertical"]}>
        {
          // TODO: Remove QRCode or add referrer
          disableQRCode ? "" :
            <BrowserView className={styles["qr-code-container"]}>
              <QRCodeSVG value={appStoreLink} className={styles["qr-code"]} />
            </BrowserView>
        }
        <div className={horizontal ? styles.badge : styles["badge-vertical"]}>
          <a href={playStoreLink} target="_blank" rel="noopener noreferrer"
             onClick={logPlayStoreBadgeClick}
             className={horizontal ? styles.badge : styles["badge-vertical"]}>
            <img alt="Get it on Google Play" src={playStoreBadge}
                 className={horizontal ? styles.playStoreImage : styles.playStoreImageVertical} />
          </a>
        </div>
      </div>

      <div className={horizontal ? styles["badge-container"] : styles["badge-container-vertical"]}>
        {
          // TODO: Remove QRCode or add referrer
          disableQRCode ? "" :
            <BrowserView className={styles["qr-code-container"]}>
              <QRCodeSVG value={appStoreLink} className={styles["qr-code"]} />
            </BrowserView>
        }
        <div className={horizontal ? styles.badge : styles["badge-vertical"]}>
          <a href={appStoreLink} target="_blank" rel="noopener noreferrer"
             onClick={logAppStoreBadgeClick}
             className={horizontal ? styles.badge : styles["badge-vertical"]}>
            <img alt={"Get it on App Store"} src={appStoreBadge}
                 className={horizontal ? styles.appStoreImage : styles.appStoreImageVertical} />
          </a>
        </div>
      </div>

      <div className={horizontal ? styles["badge-container"] : styles["badge-container-vertical"]}>
        <div className={horizontal ? styles.badge : styles["badge-vertical"]}>
          <a href="https://ccl-dashboard.comp.nus.edu.sg/download/pinit_android.apk" download="pinit-nus.apk"
             onClick={logAndroidApkBadgeClick} target="_blank" rel="noopener noreferrer"
             className={horizontal ? styles.badge : styles["badge-vertical"]}>
            <img alt={"Download Android APK File"} src={downloadApkBadge}
                 className={horizontal ? styles.downloadApkImage : styles.downloadApkImageVertical} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default MobileAppBadges;
