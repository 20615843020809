import React from "react";
import { APP_BASE_URL } from "../../constants/api";
import { openInfoModal } from "../../utils/ModalUtils";
import styles from "./ClassroomTestSessionShareModal.module.scss";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { Button, ConfigProvider } from "antd";
import { toast } from "react-toastify";
import { Scrollbars } from "react-custom-scrollbars";

const ASSIGNMENT_JOIN_URL = APP_BASE_URL + "/assignment?roomId=";

const ClassroomTestSessionShareModalTitle = ({ sessionInfo }) => (
  <div className={styles["modal-title-container"]}>{`Share session "${sessionInfo.quizTitle}"`}</div>
);

const ClassroomTestSessionShareModalContent = ({ sessionInfo }) => {
  const assignmentUrl = `${ASSIGNMENT_JOIN_URL}${sessionInfo.roomNumber}`;
  const embedAssignmentUrl = `<iframe src="${assignmentUrl}" title="Pin It assignment" height="700" width="520" style="border:none;"></iframe>`;

  const CopyableShareField = ({ fieldName, fieldValue, successToastMessage = "Success" }) => (
    <div className={styles["share-field-container"]}>
      <div className={styles["share-field-name"]}>{fieldName}</div>
      <div className={styles["share-field-value-container"]}>
        <div className={styles["share-text"]}>
          <Scrollbars style={{ height: 35 }} autoHide>{fieldValue}</Scrollbars>
        </div>
        <CopyToClipboard text={fieldValue}
                         onCopy={() => {toast.success(successToastMessage, { autoClose: 500 });}}>
          <div className={styles["copy-btn-container"]}>
            <ConfigProvider theme={{
              components: {
                Button: {
                  contentFontSize: 18,
                  controlHeight: 41,
                  fontWeight: "500",
                  paddingInline: 0,
                  algorithm: true,
                },
              },
            }}>
              <Button type="default" block>Copy</Button>
            </ConfigProvider>
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );

  return (
    <div className={styles["modal-content-container"]}>
      <CopyableShareField fieldName="URL" fieldValue={assignmentUrl} successToastMessage="URL copied!" />
      <CopyableShareField fieldName="Embed" fieldValue={embedAssignmentUrl} successToastMessage="Link copied!" />
    </div>
  );
};

export const openClassroomTestSessionShareModal = (sessionInfo) => {
  openInfoModal(
    <ClassroomTestSessionShareModalTitle sessionInfo={sessionInfo} />,
    <ClassroomTestSessionShareModalContent sessionInfo={sessionInfo} />,
    true,
  );
};
