import axios from "axios";
import { WEB_API_BASE_URL } from "../constants/api";
import { getErrorCode } from "./apiUtils";

export const uploadShareImage = async (base64Image, uuid) => {
  try {
    const resp = await axios.post(
      `${WEB_API_BASE_URL}/quick_play/upload_share_image`,
      { base64Image, uuid }, // Send data as JSON in the request body
      { headers: { "Content-Type": "application/json" } }, // Set the Content-Type header
    );

    return resp.data;
  } catch (err) {
    throw new Error(getErrorCode(err));
  }
};

export const downloadShareImage = async (uuid) => {
  try {
    const resp = await axios
      .get(`${WEB_API_BASE_URL}/quick_play/download_share_image?uuid=${uuid}`);
    return resp.data.data;
  } catch (err) {
    throw new Error(getErrorCode(err));
  }
};

