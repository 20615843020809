import { apiSecure } from "./apiSecure";

const CHARACTERS_ENDPOINT = "/characters";
const INTENT_CLASSROOM_QUIZ_CREATION = "intent=classroom-quiz-creation";

export const getCharactersForLesson = ({ syllabus_id, level_number, lesson_number }) => {
  return apiSecure.get(`/syllabuses/${syllabus_id}/levels/${level_number}/lessons/${lesson_number}/characters?${INTENT_CLASSROOM_QUIZ_CREATION}`);
};

export const getCharactersWithChinese = (charactersInChinese) => {
  return apiSecure.get(`${CHARACTERS_ENDPOINT}?${INTENT_CLASSROOM_QUIZ_CREATION}&chinese=${encodeURIComponent(charactersInChinese)}`);
};
