import { api } from "./api";
import { apiSecure } from "./apiSecure";

const QUIZ_SESSIONS_ENDPOINT = "/web/quiz/sessions";
const ASSIGNED_QUIZ_SESSIONS_ENDPOINT = "/web/quiz/sessions/assigned";
const QUIZ_SESSION_RECORDS_ENDPOINT = "/web/quiz/records";
const QUIZ_SESSION_RESULTS_ENDPOINT = "/web/quiz/results";

export const initiateNewLiveSession = async (testId) => {
  return apiSecure.post(`${QUIZ_SESSIONS_ENDPOINT}?quizId=${testId}`);
};

export const initiateNewAssignedSession = async (testId) => {
  return apiSecure.post(`${QUIZ_SESSIONS_ENDPOINT}?quizId=${testId}&type=ASSIGNED`);
};

export const validateRoomNumber = async (roomId) => {
  return api.get(`${QUIZ_SESSIONS_ENDPOINT}/${roomId}/validate`);
};

export const getAssignedSessionInfo = async (sessionId) => {
  return apiSecure.get(`${QUIZ_SESSIONS_ENDPOINT}/${sessionId}`);
};

export const endAssignedSession = async (sessionId) => {
  return apiSecure.post(`${ASSIGNED_QUIZ_SESSIONS_ENDPOINT}/end/${sessionId}`);
};

export const getAllSessionRecords = async () => {
  return apiSecure.get(QUIZ_SESSION_RECORDS_ENDPOINT);
};

export const renameSessionRecord = async (sessionId, newSessionTitle) => {
  return apiSecure.put(`${QUIZ_SESSION_RECORDS_ENDPOINT}/${sessionId}`, { newSessionTitle });
};

export const deleteSessionRecord = async (sessionId) => {
  return apiSecure.delete(`${QUIZ_SESSION_RECORDS_ENDPOINT}/${sessionId}`);
};

export const getSessionResults = async (sessionId) => {
  return apiSecure.get(`${QUIZ_SESSION_RESULTS_ENDPOINT}/${sessionId}`);
};
