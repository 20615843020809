import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { getErrorCode } from "../../apiHandlers/apiUtils";
import { validateRoomNumber } from "../../apiHandlers/classroomTestSessionApiHandler";
import { NETWORK_CONNECTION_ERROR_MSG } from "../../constants/error";
import {
  completeSession,
  joinSessionWithNickname,
  listenToIndividualSessionQuestions,
  listenToJoinStatus,
  listenToOwnerLeaveSession,
  listenToSessionEnd,
  listenToSessionStart,
  listenToSessionStartLoading,
  partiallyCompleteSession,
  requestSessionQuestions,
  saveSessionProgress,
  socketConnect,
  socketDisconnect,
} from "../../services/classroomTestWebSocketService";
import { openFullScreenErrorModal } from "../../utils/ModalUtils";
import ClassroomGameSummary from "../components/ClassroomGameSummary";
import ClassroomTestJoin from "../components/ClassroomTestJoin";
import DragAndDropGame from "../components/DragAndDropGame";
import LoadingSpinBody from "../components/LoadingSpinBody";
import { WEBSOCKET_CONNECTION_ERROR_CONTENT, WEBSOCKET_CONNECTION_ERROR_TITLE } from "./ClassroomTestLiveOwnerPage";
import PageLayout from "./PageLayout";

export const PT_PAGE_STATUS_JOIN_ROOM = "participant join enter room number";
export const PT_PAGE_STATUS_JOIN_NAME = "participant join enter nickname";
export const PT_PAGE_STATUS_JOIN_COMPLETE = "participant join complete";
const PT_PAGE_STATUS_GAME_IP = "participant game in progress";
const PT_PAGE_STATUS_GAME_OVER = "participant game over";
const PT_PAGE_STATUS_LOADING = "participant page loading";

const SESSION_ENDED_MESSAGE = "This session has ended.";
const SESSION_ALREADY_STARTED_MESSAGE = "This session has already started. Please complete it as soon as possible.";
const ROOM_VALIDATE_NOT_FOUND_ERROR_MESSAGE = "Oops, this room doesn't exist!";
const ROOM_VALIDATE_UNKNOWN_ERROR_MESSAGE = NETWORK_CONNECTION_ERROR_MSG;
const ROOM_NOT_AVAILABLE_ERROR_MESSAGE = "Oops! This room is no longer available.";

const ClassroomTestLiveParticipantPage = () => {
  const [pageStatus, setPageStatus] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [participantInfo, setParticipantInfo] = useState("");
  const [gameQuestions, setGameQuestions] = useState([]);
  const [questionsPerformanceData, setQuestionsPerformanceData] = useState(null);
  const [executeCompleteSession, setExecuteCompleteSession] = useState(false);
  const [executePartiallyCompleteSession, setExecutePartiallyCompleteSession] = useState(false);

  const hasSessionCompleted = useRef(false);

  // TODO: Make stompClient a local state

  const pageStatusRef = useRef("");
  pageStatusRef.current = pageStatus;

  const questionsPerformanceDataRef = useRef(null);
  useEffect(() => {
    questionsPerformanceDataRef.current = questionsPerformanceData;
  }, [questionsPerformanceData]);

  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");

  const navigate = useNavigate();

  const handleBeforeUnload = useCallback((e) => {
    e.preventDefault();
    // TODO: make custom message working
    // e.returnValue = "Are you sure you want to exit?";
  }, []);

  const handleVisibilityChange = useCallback(() => {
    // Save user's latest progress on visibility change
    if (!hasSessionCompleted.current) {
      const sessionData = getLatestSessionData();
      if (sessionData) {
        saveSessionProgress(sessionData);
      }
    }
  }, []);

  const updatePageStatus = (newPageStatus) => {
    setPageStatus((prevPageStatus) => {
      if (!prevPageStatus || newPageStatus === prevPageStatus || prevPageStatus === PT_PAGE_STATUS_LOADING || newPageStatus === PT_PAGE_STATUS_LOADING) {
        return newPageStatus;
      }

      switch (prevPageStatus) {
      case PT_PAGE_STATUS_JOIN_ROOM:
        return newPageStatus === PT_PAGE_STATUS_JOIN_NAME  // enter room -> enter name
          ? newPageStatus : prevPageStatus;
      case PT_PAGE_STATUS_JOIN_NAME:
        return newPageStatus === PT_PAGE_STATUS_JOIN_COMPLETE  // enter name successfully -> wait for session start
        || newPageStatus === PT_PAGE_STATUS_GAME_IP  // enter name successfully -> session already starts
        || newPageStatus === PT_PAGE_STATUS_GAME_OVER  // enter name successfully -> session already ends before game loads
        || newPageStatus === PT_PAGE_STATUS_JOIN_ROOM  // entering name -> owner leaves
          ? newPageStatus : prevPageStatus;
      case PT_PAGE_STATUS_JOIN_COMPLETE:
        return newPageStatus === PT_PAGE_STATUS_GAME_IP  // wait -> play game
        || newPageStatus === PT_PAGE_STATUS_JOIN_ROOM
          ? newPageStatus : prevPageStatus;  // wait -> owner leaves
      case PT_PAGE_STATUS_GAME_IP:
        return newPageStatus === PT_PAGE_STATUS_GAME_OVER  // game in progress can only transit to game over
          ? newPageStatus : prevPageStatus;
      case PT_PAGE_STATUS_GAME_OVER:
        return prevPageStatus;  // game over is the final state, it cannot transit to any other state
      }
    });
  };

  const getLatestSessionData = () => {
    const performanceData = questionsPerformanceDataRef.current;
    return performanceData ? { questionAttempts: performanceData.questionAttempts } : null;
  };

  useEffect(() => {
    if (!pageStatus) return;

    if (pageStatus === PT_PAGE_STATUS_JOIN_ROOM) {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    } else {
      // TODO: Investigate how to make the page touched by default so that alert still shows
      //  when user enters with roomId URL but then refreshes the enterName page without touching it
      //  same for teacher's lobby
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
  }, [pageStatus, handleBeforeUnload]);

  useEffect(() => {
    if (!roomId) {
      updatePageStatus(PT_PAGE_STATUS_JOIN_ROOM);
      return;
    }

    updatePageStatus(PT_PAGE_STATUS_LOADING);

    enterRoom(roomId, () => {});

    // This is NEVER executed because this page is currently not a child of any parent component
    return () => {
      socketDisconnect();
      // window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (executeCompleteSession) {
      handleCompleteSession(false, false);
    }
  }, [executeCompleteSession]);

  useEffect(() => {
    if (executePartiallyCompleteSession) {
      handleCompleteSession(true, true);
    }
  }, [executePartiallyCompleteSession]);

  // called after participant enters room number
  const enterRoom = async (roomNumber, callback) => {
    if (!roomNumber) {
      console.assert(false, "enterRoom expects a valid roomNumber");
      updatePageStatus(PT_PAGE_STATUS_JOIN_ROOM);
      callback();
      return;
    }

    const toastId = "enter-room-toast";
    toast.dismiss(toastId);

    let sid;

    try {
      const sessionInfo = await validateRoomNumber(roomNumber);

      if (sessionInfo.sessionType === "ASSIGNED") {
        navigate(`/assignment?roomId=${roomNumber}`, { replace: true });
        return;
      }

      sid = sessionInfo.sessionId;
      setSessionId(sid);
    } catch (err) {
      setTimeout(() => {
        updatePageStatus(PT_PAGE_STATUS_JOIN_ROOM);
        const errorMsg = getErrorCode(err) === 404 ? ROOM_VALIDATE_NOT_FOUND_ERROR_MESSAGE : ROOM_VALIDATE_UNKNOWN_ERROR_MESSAGE;
        toast.error(errorMsg, {
          toastId: toastId,
          autoClose: 1200,
          transition: Bounce,
        });
        callback();
        // this timeout is used to wait for last error toast to fully disappear, otherwise new error toast won't be displayed
        // TODO: make toast animation faster or use something different
      }, 1500);
    }

    if (!sid) return;

    const onConnect = () => {
      listenToOwnerLeaveSession(sid, handleOwnerLeaveSession);

      setTimeout(() => {
        setRoomNumber(roomNumber);
        updatePageStatus(PT_PAGE_STATUS_JOIN_NAME);
        toast.success("Room entered!", {
          toastId: toastId,
          autoClose: 1200,
          transition: Bounce,
        });
        callback();
        // this timeout is used to wait for last error toast to fully disappear, otherwise new success toast won't be displayed
      }, 1500);
    };

    const onWebSocketCloseOrError = () => {
      // TODO: Disable any page status update from now
      if (!hasSessionCompleted.current) {
        openFullScreenErrorModal(WEBSOCKET_CONNECTION_ERROR_TITLE, WEBSOCKET_CONNECTION_ERROR_CONTENT);
      }
      // window.removeEventListener("beforeunload", handleBeforeUnload);
      callback();
    };

    socketConnect(sid, false, onConnect, onWebSocketCloseOrError);
  };

  // called after participant enters nickname
  const enterNickname = (nickname, callback) => {
    if (!sessionId || !nickname) {
      console.assert(false, "enterNickname expects valid sessionId and nickname");
      callback();
      return;
    }

    // TODO: If already subscribed, should NOT subscribe again!
    listenToJoinStatus(sessionId, (participantInfo) => {
      /* 1. On successful join, subscribe to session status changes first */

      listenToSessionStartLoading(sessionId, () => {
        updatePageStatus(PT_PAGE_STATUS_LOADING);
      });
      listenToSessionStart(sessionId, (gameQuestions) => {
        setGameQuestions(gameQuestions);
        document.addEventListener("visibilitychange", handleVisibilityChange);
        updatePageStatus(PT_PAGE_STATUS_GAME_IP);
      });
      listenToSessionEnd(sessionId, handleTestEndedByOwner);

      /* 2. After subscribing to all status changes, request session questions to ensure session can start and end properly
      in case any status changes happen before subscription */

      listenToIndividualSessionQuestions((result) => {
        if (!result.hasSessionEnded) {
          if (!result.gameQuestions) {
            // Session has not started, wait for session to start
            updatePageStatus(PT_PAGE_STATUS_JOIN_COMPLETE);
            callback();
          } else {
            // Session is in progress, start the game immediately
            const currPageStatus = pageStatusRef.current;
            if (currPageStatus === PT_PAGE_STATUS_GAME_IP) return;
            // if page status is IP, questions must have been set (by start message) already

            setGameQuestions(result.gameQuestions);
            document.addEventListener("visibilitychange", handleVisibilityChange);
            updatePageStatus(PT_PAGE_STATUS_GAME_IP);
            toast.info(SESSION_ALREADY_STARTED_MESSAGE);
            callback();
          }
        } else {
          // Session has ended
          // if page status is OVER, questions may NOT have been set (by end message) already
          setGameQuestions(result.gameQuestions);
          handleTestEndedByOwner();
          callback();
        }
      });

      setParticipantInfo(participantInfo);
      requestSessionQuestions();
    }, () => {
      toast.error(ROOM_NOT_AVAILABLE_ERROR_MESSAGE);
      socketDisconnect();
      updatePageStatus(PT_PAGE_STATUS_JOIN_ROOM);
      callback();
    });

    joinSessionWithNickname(nickname);
  };

  const handlePerformanceDataUpdate = (performanceData, isLastQuestionCompleted, toNextPageClicked) => {
    // TODO: toNextPageClicked might be refactored to a separate function
    if (toNextPageClicked) {
      updatePageStatus(PT_PAGE_STATUS_GAME_OVER);
      return;
    }

    // TODO: Send performance data [per question] here to server using socket

    setQuestionsPerformanceData(performanceData);

    if (isLastQuestionCompleted) {
      setExecuteCompleteSession(true);
    }
  };

  // called after participant voluntarily completes the last question or the session is forced to end by owner
  const handleCompleteSession = (isPartialComplete, shouldUpdatePageStatusToGameOver) => {
    // Session result must be only reported once
    if (hasSessionCompleted.current) return;
    hasSessionCompleted.current = true;

    const sessionData = getLatestSessionData();
    if (sessionData) {
      if (isPartialComplete) {
        partiallyCompleteSession(sessionData);  // TODO: need to handle error? network error?
      } else {
        completeSession(sessionData);  // TODO: need to handle error? network error?
      }
    } else {
      // TODO: This may never happen
      if (isPartialComplete) {
        partiallyCompleteSession();
      } else {
        completeSession();
      }
    }

    // TODO: update page status only when data sent successfully!
    if (shouldUpdatePageStatusToGameOver) {
      updatePageStatus(PT_PAGE_STATUS_GAME_OVER);
    }
  };

  const handleOwnerLeaveSession = () => {
    // ref object is needed to access the latest state
    const currPageStatus = pageStatusRef.current;

    if (currPageStatus === PT_PAGE_STATUS_JOIN_NAME || currPageStatus === PT_PAGE_STATUS_JOIN_COMPLETE) {
      // If game has not started, force all participants to leave session
      toast.info(SESSION_ENDED_MESSAGE);
      socketDisconnect();
      updatePageStatus(PT_PAGE_STATUS_JOIN_ROOM);
    } else if (currPageStatus === PT_PAGE_STATUS_GAME_IP) {
      // If participant is still doing the questions, have the same effect as owner clicks "End Test"
      handleTestEndedByOwner();
    }
  };

  const handleTestEndedByOwner = () => {
    // TODO: Investigate when to disconnect socket (before page unload)
    const currPageStatus = pageStatusRef.current;
    if (currPageStatus === PT_PAGE_STATUS_GAME_OVER) {
      // If participant is already at the game over page, he/she must have already fully completed the session
      // no further action is needed
    } else if (currPageStatus === PT_PAGE_STATUS_GAME_IP) {
      if (hasSessionCompleted.current) {
        // Participant has completed the session but still at game page
        // => he/she must be staying at the last question and didn't click "Complete"
        updatePageStatus(PT_PAGE_STATUS_GAME_OVER);
      } else {
        // Participant has not completed the last question
        // he/she is considered to have partially completed the session when the test is forced to end by owner
        setExecutePartiallyCompleteSession(true);
      }
      toast.info(SESSION_ENDED_MESSAGE);
    }
  };

  const body = () => {
    if (pageStatus === PT_PAGE_STATUS_LOADING) {
      return <LoadingSpinBody />;
    } else if (pageStatus === PT_PAGE_STATUS_JOIN_ROOM || pageStatus === PT_PAGE_STATUS_JOIN_NAME || pageStatus === PT_PAGE_STATUS_JOIN_COMPLETE) {
      return <ClassroomTestJoin roomNumber={roomNumber} participantInfo={participantInfo} joinStatus={pageStatus}
                                enterRoom={enterRoom} enterNickname={enterNickname} />;
    } else if (pageStatus === PT_PAGE_STATUS_GAME_IP) {
      return <DragAndDropGame gameContent={gameQuestions}
                              maxRoundCount={gameQuestions.length}  // TODO: redundant
                              gameMode={2}  // TODO: Change game mode to constant
                              onPerformanceDataUpdate={handlePerformanceDataUpdate} />;
    } else if (pageStatus === PT_PAGE_STATUS_GAME_OVER) {
      return <ClassroomGameSummary performanceData={questionsPerformanceData} gameQuestions={gameQuestions}
                                   participantInfo={participantInfo} />;
    }
  };

  const displayMode = pageStatus === PT_PAGE_STATUS_GAME_IP ? "overlay" : "normal";

  return (
    <PageLayout body={body()} displayMode={displayMode} showNUSLogos={displayMode === "normal"} />
  );
};

export default ClassroomTestLiveParticipantPage;
