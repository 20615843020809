import { UserAddOutlined } from "@ant-design/icons";
import { Alert, Button, ConfigProvider, Form, Input } from "antd";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import React, { useState } from "react";
import { getErrorCode, getErrorMessage } from "../../apiHandlers/apiUtils";
import { registerUser } from "../../apiHandlers/userApiHandler";
import { ACCESS_DENIED_ERROR_MSG, UNAUTHORIZED_ERROR_MSG } from "../../constants/error";
import ClassroomNavBar from "../components/ClassroomNavBar";
import styles from "./ClassroomAdminPage.module.scss";
import PageLayout from "./PageLayout";

const ClassroomAdminPage = () => {
  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerResult, setRegisterResult] = useState("");  // "success" or "error"
  const [registerResultMsg, setRegisterResultMsg] = useState("");

  const controls = useAnimation();

  const playHighlightResultAnimation = async () => {
    // Play the animation to make the div slightly larger with a spring effect
    await controls.start({ scale: 1.2, transition: { type: "easeInOut", duration: 0.5 } });

    // Wait for a short duration
    await new Promise((resolve) => setTimeout(resolve, 100));

    // Play the animation to return the div to its normal size with a spring effect
    await controls.start({ scale: 1, transition: { type: "easeInOut", duration: 0.5 } });
  };

  const handleRegisterUser = (values) => {
    setRegisterLoading(true);
    setTimeout(async () => {
      try {
        const registeredUser = await registerUser({
          email: values.email.trim(),
          password: values.password,
          name: values.name.trim(),
          institute: values.institute.trim(),
        });
        // registeredUser should never be null
        setRegisterResultMsg(`User ${registeredUser.email} has been registered.`);
        setRegisterResult("success");
      } catch (err) {
        let message;
        const errorCode = getErrorCode(err);
        if (errorCode === 401) {
          message = ACCESS_DENIED_ERROR_MSG;
        } else if (errorCode === 403) {
          message = UNAUTHORIZED_ERROR_MSG;
        } else {
          message = getErrorMessage(err);
        }
        setRegisterResultMsg(message);
        setRegisterResult("error");
      } finally {
        playHighlightResultAnimation();
        setRegisterLoading(false);
      }
    }, 500);
  };

  const header = (<ClassroomNavBar />);

  const body = (
    <div className={styles.body}>
      <div className={styles["reg-form-container"]}>
        <AnimatePresence>
          <motion.div className={styles["error-message-container"]} hidden={!registerResult} animate={controls}>
            <ConfigProvider
              theme={{
                components: {
                  Alert: {
                    withDescriptionIconSize: 28,
                  },
                },
                token: {
                  fontSize: 18,
                },
              }}
            >
              <Alert
                message={registerResult === "success" ? "Success" : "Failed to register user"}
                description={registerResultMsg}
                type={registerResult || "info"}
                showIcon
                className={styles["error-message"]}
              />
            </ConfigProvider>
          </motion.div>
        </AnimatePresence>
        <ConfigProvider
          theme={{
            components: {
              Form: {
                labelFontSize: 18,
                itemMarginBottom: 8,
                verticalLabelPadding: "0 0 0",
              },
            },
            token: {
              fontSize: 16,
            },
          }}
        >
          <Form
            name="registration"
            onFinish={handleRegisterUser}
            layout="vertical"
            size={"large"}
            disabled={registerLoading}
            style={
              { fontWeight: 600 }
            }
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter email" },
                { type: "email" },
                { max: 128 },
              ]}
            >
              <Input className={styles["reg-form-input"]} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter password" },
                { min: 6 },
                { max: 50 },
              ]}
            >
              <Input.Password className={styles["reg-form-input"]} />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Please confirm password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("The new password that you entered do not match"));
                  },
                }),
              ]}
            >
              <Input.Password className={styles["reg-form-input"]} />
            </Form.Item>

            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: "Please enter name" },
                { type: "string", max: 70, whitespace: true },
              ]}
            >
              <Input className={styles["reg-form-input"]} />
            </Form.Item>

            <Form.Item
              name="institute"
              label="Institute"
              rules={[
                { required: true, message: "Please enter institute" },
                { type: "string", max: 80, whitespace: true },
              ]}
            >
              <Input className={styles["reg-form-input"]} />
            </Form.Item>

            <Form.Item>
              <div className={styles["btn-reg-container"]}>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: "#004AAD",
                        fontWeight: "500",
                        fontSizeLG: 22,
                        algorithm: true,
                      },
                    },
                  }}
                >
                  <Button type="primary" htmlType="submit" className={styles["btn-reg"]}
                          disabled={false} loading={registerLoading} icon={<UserAddOutlined />}
                  >
                    Register User
                  </Button>
                </ConfigProvider>
              </div>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </div>
    </div>
  );

  return (
    <PageLayout header={header} body={body} displayMode={"normal"} showNUSLogos={true} />
  );
};

export default ClassroomAdminPage;
