import { FormOutlined, LinkOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, Col, ConfigProvider, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getErrorCode, getErrorMessage } from "../../apiHandlers/apiUtils";
import { retrieveLoggedInUserInfo } from "../../apiHandlers/userApiHandler";
import characterJian from "../../assets/jian.svg";
import characterZai from "../../assets/zai.svg";
import { ACCESS_DENIED_ERROR_MSG } from "../../constants/error";
import { useAuth } from "../../contexts/AuthContext";
import { showOrUpdateExistingErrorToast } from "../../utils/toastUtils";
import ClassroomNavBar from "../components/ClassroomNavBar";
import ErrorBody from "../components/ErrorBody";
import LoadingSpinBody from "../components/LoadingSpinBody";
import styles from "./ClassroomAccountPage.module.scss";

import PageLayout from "./PageLayout";
import ClassroomFeedback from "../components/ClassroomFeedback";
import { CLASSROOM_TEACHER_FEEDBACK_SUBMITTED } from "../../constants/api";

const ClassroomAccountPage = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [userInfoLoading, setUserInfoLoading] = useState(true);
  const [userInfoLoadingFailed, setUserInfoLoadingFailed] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);

  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);

  const { handleLogout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setUserInfoLoading(true);
    const fetchData = async () => {
      try {
        const userInfo = await retrieveLoggedInUserInfo();
        setUserInfo(userInfo);
        setUserInfoLoading(false);
      } catch (err) {
        if (getErrorCode(err) === 401) {
          await handleLogout();
          toast.error(ACCESS_DENIED_ERROR_MSG);
          navigate("/classroom/login", { replace: true });
        }
        setUserInfoLoading(false);
        setUserInfoLoadingFailed(true);
      }
    };
    setTimeout(() => {
      fetchData();
      // This timeout is completely optional
    }, 300);
  }, []);

  const handleSignOutClick = async () => {
    setLogoutLoading(true);
    setTimeout(async () => {
      try {
        const success = await handleLogout();
        if (success) {
          navigate("/", { replace: true });
          setLogoutLoading(false);
        }
      } catch (err) {
        const toastId = "logout-error-toast";
        showOrUpdateExistingErrorToast(toastId, getErrorMessage(err));
        setLogoutLoading(false);
      }
      // This timeout is completely optional
    }, 500);
  };

  const header = (<ClassroomNavBar />);

  const body = () => {
    if (userInfoLoading) {
      return <LoadingSpinBody />;
    } else if (userInfoLoadingFailed) {
      return <ErrorBody />;
    } else {
      return (
        <>
          <div className={styles.body}>
            <Row className={styles.row}>
              <Col xs={24} lg={16} xl={14}>
                <div className={styles["left-container"]}>
                  <div className={styles["info-container"]}>
                    <div className={styles["info-title"]}>Email:</div>
                    <div className={styles["info-value"]}>{userInfo.email}</div>
                  </div>
                  <div className={styles["info-container"]}>
                    <div className={styles["info-title"]}>Name:</div>
                    <div className={styles["info-value"]}>{userInfo.name}</div>
                  </div>
                  <div className={styles["info-container"]}>
                    <div className={styles["info-title"]}>Institute:</div>
                    <div className={styles["info-value"]}>{userInfo.institute}</div>
                  </div>
                  <div className={styles["btn-reset-password-container"]}>
                    <ConfigProvider theme={{
                      components: {
                        Button: {
                          colorPrimary: "#004AAD",
                          contentFontSizeLG: 22,
                          fontWeight: "500",
                          algorithm: true,
                          controlHeight: 32,
                        },
                      },
                    }}>
                      <Link to="/classroom/reset-password" target="_blank">
                        <Button type="default" block size="large" icon={<LinkOutlined />}>
                          Reset Password
                        </Button>
                      </Link>
                    </ConfigProvider>
                  </div>
                  <div className={styles["btn-feedback-container"]}>
                    <ConfigProvider theme={{
                      components: {
                        Button: {
                          colorPrimary: "#004AAD",
                          contentFontSizeLG: 22,
                          fontWeight: "500",
                          algorithm: true,
                          controlHeight: 32,
                        },
                      },
                    }}>
                      <Button type="primary" block size="large" icon={<FormOutlined />}
                              onClick={() => {setIsSurveyModalOpen(true);}}
                      >
                        Feedback
                      </Button>
                    </ConfigProvider>
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={8} xl={10}>
                <div className={styles["right-container"]}>
                  <div className={styles["zaijian-container"]}>
                    <img alt="Chinese Character Zai" src={characterZai}
                         className={`${styles["zaijian-character"]} img-unselectable`} />
                    <img alt="Chinese Character Jian" src={characterJian}
                         className={`${styles["zaijian-character"]} img-unselectable`} />
                  </div>
                  <div className={styles["btn-logout-container"]}>
                    <ConfigProvider theme={{
                      components: {
                        Button: {
                          contentFontSizeLG: 22,
                          fontWeight: "500",
                          algorithm: true,
                          controlHeight: 32,
                        },
                      },
                    }}>
                      <Button type="primary" block size="large" icon={<LogoutOutlined />}
                              onClick={handleSignOutClick} loading={logoutLoading}>
                        Sign Out
                      </Button>
                    </ConfigProvider>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <ClassroomFeedback userType="TEACHER" isSurveyModalOpen={isSurveyModalOpen}
                             closeModal={() => {setIsSurveyModalOpen(false);}}
                             callbackOnSuccessfulSubmit={() => {
                               localStorage.setItem(CLASSROOM_TEACHER_FEEDBACK_SUBMITTED, "true");
                             }}
          />
        </>
      );
    }
  };

  return (
    <PageLayout header={header} body={body()} displayMode={"normal"} showNUSLogos={true} />
  );
};

export default ClassroomAccountPage;
