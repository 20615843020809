import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getQuickPlaySession } from "../../apiHandlers/gameApi";
// import { gameContent } from "../../utils/gameData";
import DragAndDropGame from "../components/DragAndDropGame";
import PageLayout from "./PageLayout";
import styles from "./QuickPlayGamePage.module.scss";

const QuickPlayGamePage = () => {
  const [gameData, setGameData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const diffLevel = -1;
      try {
        const data = await getQuickPlaySession(diffLevel);
        setGameData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();

  }, []);

  const body = (
    <div className={styles.gameMenu}>
      {
        gameData === null ?
          <div className={styles.loading}><Spin size="large" /></div>
          :
          <DragAndDropGame gameContent={gameData} maxRoundCount={10} gameMode={1} />
      }
    </div>
  );

  return (
    <PageLayout body={body} displayMode={"overlay"} />
  );
};

export default QuickPlayGamePage;
