import { Alert, ConfigProvider } from "antd";
import React from "react";
import { NETWORK_CONNECTION_ERROR_MSG } from "../../constants/error";
import styles from "./ErrorBody.module.scss";

const ErrorBody = ({ message = "", fullMessage = "" }) => {
  return (
    <div className={styles["body-error"]}>
      <ConfigProvider
        theme={{
          components: {
            Alert: {
              withDescriptionIconSize: 32,
            },
          },
        }}
      >
        <Alert
          description={fullMessage ? fullMessage : `${message} ${NETWORK_CONNECTION_ERROR_MSG}`}
          type="error"
          showIcon
          className={styles["error-message"]}
        />
      </ConfigProvider>
    </div>
  );
};

export default ErrorBody;
