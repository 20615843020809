import { apiSecure } from "./apiSecure";

const QUIZZES_ENDPOINT = "/web/quizzes";

export const createClassroomTest = (classroomTest) => {
  return apiSecure.post(QUIZZES_ENDPOINT, classroomTest);
};

export const getAllClassroomTests = () => {
  return apiSecure.get(QUIZZES_ENDPOINT);
};

export const updateClassroomTest = (testId, classroomTest) => {
  return apiSecure.put(`${QUIZZES_ENDPOINT}/${testId}`, classroomTest);
};

export const deleteClassroomTest = (testId) => {
  return apiSecure.delete(`${QUIZZES_ENDPOINT}/${testId}`);
};
