import { Spin } from "antd";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addQuickPlayStatistics } from "../../apiHandlers/gameApi";
import QuickPlayGameSummary from "../components/QuickPlayGameSummary";
import PageLayout from "./PageLayout";
import styles from "./QuickPlaySummaryPage.module.scss";

function Redirect(props) {
  return null;
}

Redirect.propTypes = { to: PropTypes.string };
const QuickPlaySummaryPage = () => {
  const data = useLocation();
  const navigate = useNavigate();
  const [uuid, setUuid] = useState(null);


  // Redirect to quickplay main page if the state performanceData does not exist
  useEffect(() => {
    if (data.state === null || data.state.performanceData === null) {
      navigate("/quickplay", { replace: true });
    }

    async function fetchData(totalTimeTaken) {
      try {
        const data = await addQuickPlayStatistics(totalTimeTaken);
        setUuid(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    let totalTimeTaken = 0;

    for (const attempt of data.state.performanceData.questionAttempts) {
      if (attempt.timeTaken !== null) {
        totalTimeTaken += attempt.timeTaken;
      }
    }

    fetchData(totalTimeTaken);

  }, []);


  const body = (
    <div className={styles.gameMenu}>
      {
        uuid === null ?
          <div className={styles.loading}><Spin size="large" /></div>
          :
          <QuickPlayGameSummary performanceData={data.state.performanceData} uuid={uuid} />
      }

    </div>
  );

  return (
    <PageLayout body={body} displayMode={"normal"} />
  );
};

export default QuickPlaySummaryPage;
