import { AlertOutlined, FormOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Modal } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import MobileAppBadges from "./MobileAppBadges";
import styles from "./ClassroomGameSummaryNotificationArea.module.scss";
import MobileAppDownloadSection from "./MobileAppDownloadSection";
import MobileAppGetButton from "./MobileAppGetButton";
import ClassroomFeedback from "./ClassroomFeedback";

const ClassroomGameSummaryNotificationArea = ({ referer, uid }) => {
  const [isAppDownloadModalOpen, setIsAppDownloadModalOpen] = useState(false);
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  const buttonControlHeightLG = lg ? 70 : 55;
  const onlyIconSizeLG = lg ? 35 : 28;
  const lineHeight = lg ? 2.3 : 1.8;

  const modalStyles = {
    mask: {
      backgroundColor: "rgba(110, 110, 110, 0.1)",
    },
    content: {
      boxShadow: "0 0 30px #999",
      background: "linear-gradient(to right, #f0f5fc, #f7fbfe)",
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setIsAppDownloadModalOpen(true);
    }, 1000);
  }, []);

  return (
    <div className={styles["main-container"]}>
      <motion.div
        animate={{ scale: [1.1, 1, 1.1], transition: { duration: 2, repeat: Infinity } }}
        whileHover={{ scale: 1, transition: { duration: 0.2 } }}
        hidden={isAppDownloadModalOpen}
      >
        <ConfigProvider
          theme={{
            components: {
              Button: {
                controlHeightLG: buttonControlHeightLG,
                onlyIconSizeLG: onlyIconSizeLG,
                lineHeight: lineHeight,
              },
            },
          }}
        >
          <Button type="primary" shape="circle" icon={<AlertOutlined />} size="large"
                  onClick={() => {setIsAppDownloadModalOpen(true);}} className={styles.button} />
        </ConfigProvider>
      </motion.div>

      <ConfigProvider
        theme={{
          components: {
            Button: {
              controlHeightLG: buttonControlHeightLG,
              onlyIconSizeLG: onlyIconSizeLG,
              lineHeight: lineHeight,
              colorPrimary: "#1f1f1f",
              algorithm: true,
            },
          },
        }}
      >
        <Button type="default" shape="circle" icon={<FormOutlined />} size="large"
                onClick={() => {setIsSurveyModalOpen(true);}} className={styles.button} />
      </ConfigProvider>

      <Modal
        open={isAppDownloadModalOpen}
        onOk={() => setIsAppDownloadModalOpen(false)}
        onCancel={() => setIsAppDownloadModalOpen(false)}
        footer={null}
        width={750}
        styles={modalStyles}
      >
        <MobileAppDownloadSection />
        <div className={styles["app-get-button-container"]}>
          <div className={styles["app-get-button"]}>
            <BrowserView>
              <MobileAppGetButton referer={referer} uid={uid} />
            </BrowserView>
            <MobileView>
              <div className={styles["badges-container"]}>
                <div className={styles["badges"]}>
                  <MobileAppBadges horizontal={false} refererList={referer ? [referer] : null} uid={uid} />
                </div>
              </div>
            </MobileView>
          </div>
        </div>
      </Modal>

      <ClassroomFeedback userType="STUDENT" userId={uid} isSurveyModalOpen={isSurveyModalOpen}
                         closeModal={() => {setIsSurveyModalOpen(false);}} />
    </div>
  );
};

export default ClassroomGameSummaryNotificationArea;
