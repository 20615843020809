import { WEB_AUTH_TOKEN_NAME } from "../constants/api";
import { api } from "./api";
import { apiSecure, apiSecureFullResponse } from "./apiSecure";

const AUTH_ENDPOINT = "/web/auth";
const USERS_ENDPOINT = "/web/users";

export const login = ({ email, password }) => {
  return apiSecureFullResponse.post(`${AUTH_ENDPOINT}/login`, { email, password })
    .then(response => {
      const authToken = response.headers[WEB_AUTH_TOKEN_NAME];
      return { loggedInUser: response.data.data, authToken };
    });
};

export const logout = () => {
  return apiSecure.post(`${AUTH_ENDPOINT}/logout`);
};

export const authenticateToken = () => {
  return apiSecure.get(AUTH_ENDPOINT);
};

export const sendPasswordResetVerificationCode = (email) => {
  return api.post(`${AUTH_ENDPOINT}/password-reset/token`, { email });
};

export const resetPassword = ({ email, password, token }) => {
  return api.post(`${AUTH_ENDPOINT}/password-reset`, { email, password, token });
};

export const retrieveLoggedInUserInfo = () => {
  return apiSecure.get(USERS_ENDPOINT);
};

export const registerUser = ({ email, password, name, institute }) => {
  return apiSecure.post(USERS_ENDPOINT, { email, password, name, institute });
};
