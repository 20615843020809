import { DoubleRightOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, ConfigProvider, Row } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styles from "./ClassroomTestLobby.module.scss";
import ClassroomTestJoinInstruction from "./ClassroomTestJoinInstruction";

const ClassroomTestLobby = ({ roomNumber, participants, onGoClick }) => {
  const participantList = () => {
    if (participants.length === 0) {
      return (
        <div className={styles["waiting-message"]}>
          Waiting for participants to join...
        </div>
      );
    }
    return (
      <AnimatePresence>
        <div className={styles["participants-container"]}>
          {
            participants.map((participant) => participantCard(participant))
          }
        </div>
      </AnimatePresence>
    );
  };

  const participantCard = (participant) => {
    return (
      <motion.div key={participant.userId}
                  initial={{ opacity: 0.5, scale: 1.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
      >
        <div className={styles["participant-card"]}>{participant.nickname}</div>
      </motion.div>
    );
  };

  return (
    <div className={styles.body}>
      <div className={styles["main-container"]}>
        <div className={styles["row-container"]}>
          <Row className={styles["row"]}>
            <Col span={10}>
              <ClassroomTestJoinInstruction roomNumber={roomNumber} />
            </Col>
            <Col span={14}>
              <div className={styles["participants-area"]}>{participantList()}</div>
              <div className={styles["bottom-area"]}>
                <div className={styles["participant-number-container"]}>
                  <div className={styles["participant-icon"]}>
                    <UserOutlined />
                  </div>
                  <div className={styles["participant-number"]}>{participants.length}</div>
                </div>
                <div className={styles["btn-go-container"]}>
                  <div className={styles["btn-go"]}>
                    <ConfigProvider theme={{
                      components: {
                        Button: {
                          colorPrimary: "#02924D",
                          contentFontSizeLG: 28,
                          fontWeight: "500",
                          paddingInlineLG: 0,
                          controlHeightLG: 50,
                          algorithm: true,
                        },
                      },
                    }}>
                      <Button type="primary" block size="large" className={styles["antd-btn"]}
                              onClick={onGoClick} disabled={participants.length === 0}>
                        <div className={styles["btn-go-content"]}>
                          Go
                          <DoubleRightOutlined />
                        </div>
                      </Button>
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ClassroomTestLobby;
