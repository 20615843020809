import { Modal } from "antd";
import React from "react";

// TODO: Resolve warning: Static function can not consume context like dynamic theme. Please use 'App' component instead.
export const openFullScreenErrorModal = (titleMessage, contentMessage, displayOk = false, okText, okHref) => {
  // TODO: change to a bgColor different from white
  Modal.error({
    icon: null,
    title: <div style={{ fontSize: 24 }}>{titleMessage}</div>,
    content: <div style={{ fontSize: 20 }}>{contentMessage}</div>,
    centered: true,
    width: 800,
    footer: displayOk ? undefined : null,
    okText: okText,
    okButtonProps: {
      size: "large",
      href: okHref,
    },
  });
};

export const openInfoModal = (title, content, displayOk = false, okText, okHref) => {
  // TODO: change to a bgColor different from white
  Modal.info({
    icon: null,
    title: title,
    content: content,
    centered: true,
    width: 850,
    footer: displayOk ? undefined : null,
    okText: okText,
    okButtonProps: {
      size: "large",
      href: okHref,
    },
    closable: true,
  });
};
