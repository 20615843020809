import { api } from "./api";
import { apiSecure } from "./apiSecure";

export const submitClassroomFeedbackForStudent = async (data) => {
  await api.post("/web/classroom_feedback", data);
};

export const submitClassroomFeedbackForTeacher = async (data) => {
  await apiSecure.post("/web/classroom_feedback", data);
};
