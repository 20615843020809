import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ACCESS_DENIED_ERROR_MSG, UNAUTHORIZED_ERROR_MSG } from "../constants/error";
import { useAuth } from "../contexts/AuthContext";
import ClassroomAccountPage from "../views/pages/ClassroomAccountPage";
import ClassroomAdminPage from "../views/pages/ClassroomAdminPage";
import ClassroomLoginPage from "../views/pages/ClassroomLoginPage";
import ClassroomTestLiveOwnerPage from "../views/pages/ClassroomTestLiveOwnerPage";
import ClassroomTestsPage from "../views/pages/ClassroomTestsPage";
import PrivateRoute from "./PrivateRoute";
import ClassroomHistoryPage from "../views/pages/ClassroomHistoryPage";
import ClassroomTestResultsPage from "../views/pages/ClassroomTestResultsPage";

const ClassroomRoutes = () => {
  const { isLoggedIn, user } = useAuth();

  return (
    <Routes>
      <Route path="login"
             element={isLoggedIn ? <Navigate to="/classroom/tests" replace /> : <ClassroomLoginPage />} />
      <Route path="reset-password" element={<ClassroomLoginPage loginMode={false} />} />

      <Route element={
        <PrivateRoute isAllowed={isLoggedIn}
                      redirectPath="/classroom/login"
                      redirectReason={ACCESS_DENIED_ERROR_MSG} />
      }>
        <Route path="tests" element={<ClassroomTestsPage />} />
        <Route path="history" element={<ClassroomHistoryPage />} />
        <Route path="account" element={<ClassroomAccountPage />} />
        <Route path="lobby" element={<ClassroomTestLiveOwnerPage />} />
        <Route path="results" element={<ClassroomTestResultsPage />} />
      </Route>

      <Route path="admin" element={
        <PrivateRoute isAllowed={isLoggedIn && user.isAdmin}
                      redirectPath={isLoggedIn ? "/classroom/tests" : "/classroom/login"}
                      redirectReason={isLoggedIn ? UNAUTHORIZED_ERROR_MSG : ACCESS_DENIED_ERROR_MSG}>
          <ClassroomAdminPage />
        </PrivateRoute>
      } />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ClassroomRoutes;
