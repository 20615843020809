import { DownloadOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Popover } from "antd";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { Link } from "react-router-dom";
import { APP_DOWNLOAD_PAGE_URL } from "../../constants/AppConstants";
import styles from "./MobileAppGetButton.module.scss";

const MobileAppGetButton = ({ referer, uid }) => {
  const hoverContent = (
    <div className={styles["hover-content-container"]}>
      <div className={styles["qr-code-container"]}>
        <QRCodeSVG value={`${APP_DOWNLOAD_PAGE_URL}?referer=${referer}_qr&uid=${uid}`} className={styles["qr-code"]} />
      </div>
      <div className={styles["scan-text"]}>Scan QR code to download</div>
    </div>
  );

  return (
    <div className={styles["main-container"]}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              controlHeightLG: 48,
              contentFontSizeLG: 20,
              fontWeight: 600,
            },
          },
        }}
      >
        <Popover content={hoverContent} trigger="hover">
          <Link to={`${APP_DOWNLOAD_PAGE_URL}?referer=${referer}&uid=${uid}`} target="_blank"
                className={styles["link"]}>
            <Button type="primary" block icon={<DownloadOutlined />} size="large">
              Get Mobile App
            </Button>
          </Link>
        </Popover>
      </ConfigProvider>
    </div>
  );
};

export default MobileAppGetButton;
