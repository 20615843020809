import { green, grey, red, volcano, yellow } from "@ant-design/colors";
import { Checkbox, Col, ConfigProvider, Progress, Radio, Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import Character from "./Character";
import styles from "./ClassroomTestSummary.module.scss";

const SORT_MODE_INACCURACY = "inaccuracy";
const SORT_MODE_AVG_TIME_TAKEN = "avg time taken";

const TIME_PERFORMANCE_BREAKPOINT_1 = 5;
const TIME_PERFORMANCE_BREAKPOINT_2 = 10;
const TIME_PERFORMANCE_BREAKPOINT_3 = 20;
const TIME_PERFORMANCE_BREAKPOINT_4 = 30;

const INACCURACY_PERFORMANCE_BREAKPOINT_1 = 30;
const INACCURACY_PERFORMANCE_BREAKPOINT_2 = 60;
const INACCURACY_PERFORMANCE_BREAKPOINT_3 = 90;
const INACCURACY_PERFORMANCE_BREAKPOINT_4 = 100;

const COLOR_PERFORMANCE_BREAKPOINT_1 = green[6];
const COLOR_PERFORMANCE_BREAKPOINT_2 = yellow[5];
const COLOR_PERFORMANCE_BREAKPOINT_3 = volcano[5];
const COLOR_PERFORMANCE_BREAKPOINT_4 = red[7];
const COLOR_PERFORMANCE_DEFAULT = grey[5];

const getValueOrElse = (nullableValue, defaultValue) => {
  return nullableValue !== null ? nullableValue : defaultValue;
};

const compareNumbers = (a, b) => (a - b);

const ClassroomTestSummary = ({ characters, sessionResult, pageTitle = "Review", showPageTitle = true }) => {
  const [questionResults, setQuestionResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortMode, setSortMode] = useState(SORT_MODE_INACCURACY);
  const [useQuestionOrder, setUseQuestionOrder] = useState(false);
  const [maxAvgTimeTaken, setMaxAvgTimeTaken] = useState(0);

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  useEffect(() => {
    if (sessionResult) {
      setQuestionResults(preprocessSessionResult(sessionResult));
    }
    setLoading(false);
  }, []);

  const handleSortOptionChange = (newSortMode, newUseQuestionOrder) => {
    setSortMode(newSortMode);
    setUseQuestionOrder(newUseQuestionOrder);
    if (newUseQuestionOrder) {
      setQuestionResults((prevQuestionResults) => sortQuestionResultsByQuestionIndex(prevQuestionResults));
    } else if (newSortMode === SORT_MODE_INACCURACY) {
      setQuestionResults(sortQuestionResultsByInaccuracy(questionResults));
    } else if (newSortMode === SORT_MODE_AVG_TIME_TAKEN) {
      setQuestionResults((prevQuestionResults) => sortQuestionResultsByAvgTimeTaken(prevQuestionResults));
    }
  };

  const preprocessSessionResult = (sessionResult) => {
    let results = sessionResult.questionResults;
    setMaxAvgTimeTaken(Math.max(...results.map(r => r.averageTimeTaken)));
    results = results.map((result, index) => {
      return {
        ...result,
        questionIndex: index,
      };
    });
    return sortQuestionResultsByInaccuracy(results);
  };

  const sortQuestionResultsByInaccuracy = (results) => {
    return [...results].sort((r1, r2) => {
      const compareResult = compareNumbers(getValueOrElse(r2.inaccuracy, -Infinity), getValueOrElse(r1.inaccuracy, -Infinity));
      return compareResult !== 0 ? compareResult : (r1.questionIndex - r2.questionIndex);  // tie-breaking by question index
    });
  };

  const sortQuestionResultsByAvgTimeTaken = (results) => {
    return [...results].sort((r1, r2) => {
      const compareResult = compareNumbers(getValueOrElse(r2.averageTimeTaken, -Infinity), getValueOrElse(r1.averageTimeTaken, -Infinity));
      return compareResult !== 0 ? compareResult : (r1.questionIndex - r2.questionIndex);  // tie-breaking by question index
    });
  };

  const sortQuestionResultsByQuestionIndex = (results) => {
    return [...results].sort((r1, r2) => (r1.questionIndex - r2.questionIndex));
  };

  const getQuestionPerformancePercent = (questionResult) => {
    if (sortMode === SORT_MODE_AVG_TIME_TAKEN) {
      return questionResult.averageTimeTaken !== null && maxAvgTimeTaken !== 0 ? Math.round((questionResult.averageTimeTaken / maxAvgTimeTaken) * 100) : null;
    } else {
      return questionResult.inaccuracy !== null ? Math.round(questionResult.inaccuracy * 100) : null;
    }
  };

  const getQuestionPerformanceText = (questionResult, percent) => {
    if (sortMode === SORT_MODE_AVG_TIME_TAKEN) {
      return questionResult.averageTimeTaken !== null ? `${questionResult.averageTimeTaken.toFixed(1)}s` : null;
    } else {
      return percent !== null ? `${percent}%` : null;
    }
  };

  const getQuestionPerformanceColor = (questionResult, percent) => {
    if (sortMode === SORT_MODE_AVG_TIME_TAKEN) {
      if (questionResult.averageTimeTaken <= TIME_PERFORMANCE_BREAKPOINT_1) {
        return COLOR_PERFORMANCE_BREAKPOINT_1;
      } else if (questionResult.averageTimeTaken <= TIME_PERFORMANCE_BREAKPOINT_2) {
        return COLOR_PERFORMANCE_BREAKPOINT_2;
      } else if (questionResult.averageTimeTaken <= TIME_PERFORMANCE_BREAKPOINT_3) {
        return COLOR_PERFORMANCE_BREAKPOINT_3;
      } else if (questionResult.averageTimeTaken <= TIME_PERFORMANCE_BREAKPOINT_4) {
        return COLOR_PERFORMANCE_BREAKPOINT_4;
      } else {
        return COLOR_PERFORMANCE_DEFAULT;
      }
    } else {
      if (percent <= INACCURACY_PERFORMANCE_BREAKPOINT_1) {
        return COLOR_PERFORMANCE_BREAKPOINT_1;
      } else if (percent <= INACCURACY_PERFORMANCE_BREAKPOINT_2) {
        return COLOR_PERFORMANCE_BREAKPOINT_2;
      } else if (percent <= INACCURACY_PERFORMANCE_BREAKPOINT_3) {
        return COLOR_PERFORMANCE_BREAKPOINT_3;
      } else if (percent <= INACCURACY_PERFORMANCE_BREAKPOINT_4) {
        return COLOR_PERFORMANCE_BREAKPOINT_4;
      } else {
        return COLOR_PERFORMANCE_DEFAULT;
      }
    }
  };

  const QuestionResult = ({ questionResult }) => {
    const character = questionResult.character;
    const percent = getQuestionPerformancePercent(questionResult);
    const text = getQuestionPerformanceText(questionResult, percent);

    return (
      <div className={styles["question-result-container"]}>
        <div className={styles["question-character-container"]}>
          <Character character={character} fallbackFontColor="white" fallbackFontSize={xs ? "4.5vh" : "6.5vh"}
                     disableSVG={true} />
        </div>
        <div className={styles["question-performance-container"]}>
          {
            text === null
              ?
              (
                <div className={styles["question-performance-text-no-ans"]}>
                  No Answer
                </div>
              )
              :
              (
                <>
                  <div className={styles["progress-bar-container"]}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Progress: {
                            marginXS: 0,
                            remainingColor: "white",
                            defaultColor: getQuestionPerformanceColor(questionResult, percent),
                            colorSuccess: getQuestionPerformanceColor(questionResult, percent),
                          },
                        },
                      }}
                    >
                      <Progress percent={percent} showInfo={false} />
                    </ConfigProvider>
                  </div>
                  <div className={styles["question-performance-text"]}>
                    {text}
                  </div>
                </>
              )
          }
        </div>
      </div>
    );
  };

  if (!sessionResult) {
    return (
      <div className={styles.body}>
        <div className={styles["main-container"]}>
          {showPageTitle && <div className={styles["page-title-text"]}>{pageTitle}</div>}
          <div className={styles["characters-container"]}>
            {
              characters.map(character => {
                return (<Character key={character.id} character={character} fallbackFontSize="10vw" />);
              })
            }
          </div>
        </div>
      </div>
    );
  } else if (!loading) {
    return (
      <div className={styles.body}>
        <div className={styles["main-container"]}>
          {showPageTitle && <div className={styles["page-title-text"]}>{pageTitle}</div>}
          <div className={styles["options-container"]}>
            <ConfigProvider
              theme={{
                token: {
                  fontSize: xs ? 18 : 22,
                  controlHeight: xs ? 37 : 45,
                },
              }}
            >
              <Radio.Group value={sortMode}
                           onChange={(e) => {handleSortOptionChange(e.target.value, useQuestionOrder);}}
                           className={styles["sort-options"]}>
                <Radio.Button value={SORT_MODE_INACCURACY}>Inaccuracy</Radio.Button>
                <Radio.Button value={SORT_MODE_AVG_TIME_TAKEN}>Time</Radio.Button>
              </Radio.Group>
              <Checkbox onChange={(e) => {handleSortOptionChange(sortMode, e.target.checked);}}>
                Question Order
              </Checkbox>
            </ConfigProvider>
          </div>
          {
            lg
              ? (<div>
                <Row>
                  <Col span={12}>
                    <div className={styles["question-results-container"]}>
                      {
                        questionResults.map((questionResult, index) => {
                          return (index % 2 === 0) &&
                            (<QuestionResult key={questionResult.character.id} questionResult={questionResult} />);
                        })
                      }
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles["question-results-container"]}>
                      {
                        questionResults.map((questionResult, index) => {
                          return (index % 2 === 1) &&
                            (<QuestionResult key={questionResult.character.id} questionResult={questionResult} />);
                        })
                      }
                    </div>
                  </Col>
                </Row>
              </div>)
              : (<div>
                <Row>
                  <Col span={24}>
                    <div className={styles["question-results-container"]}>
                      {
                        questionResults.map((questionResult) => {
                          return (<QuestionResult key={questionResult.character.id} questionResult={questionResult} />);
                        })
                      }
                    </div>
                  </Col>
                </Row>
              </div>)
          }
        </div>
      </div>
    );
  }
};

export default ClassroomTestSummary;
