import React from 'react';
import styles from "./ClassroomTestJoinInstruction.module.scss";
import { QRCodeSVG } from "qrcode.react";
import { APP_BASE_URL } from "../../constants/api";

const ROOM_JOIN_URL = APP_BASE_URL + "/join";

function ClassroomTestJoinInstruction({ roomNumber }) {
  return (
    <div className={styles["main-container"]}>
      <div className={styles["join-instruction-container"]}>
        <div className={styles["join-instruction-text"]}>Join at</div>
        <div className={styles["join-instruction-url"]}>{ROOM_JOIN_URL}</div>
      </div>
      <div className={styles["qr-code-container"]}>
        <QRCodeSVG value={`${ROOM_JOIN_URL}?roomId=${roomNumber}`}
                   className={styles["qr-code"]} />
      </div>
    </div>
  );
}

export default ClassroomTestJoinInstruction;