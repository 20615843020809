import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getErrorMessage } from "../apiHandlers/apiUtils";
import { authenticateToken, login, logout } from "../apiHandlers/userApiHandler";
import { WEB_AUTH_TOKEN_NAME } from "../constants/api";

const AuthContext = createContext(null);

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within an AuthContext");
  }
  return authContext;
};

const checkLoggedIn = async () => {
  const authenticatedUser = await authenticateToken();
  if (!authenticatedUser) {
    Cookies.remove(WEB_AUTH_TOKEN_NAME);
    return null;
  }
  return authenticatedUser;
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [checking, setChecking] = useState(true);

  useEffect(() => {
    setChecking(true);
    checkLoggedIn().then((loggedInUser) => {
      if (loggedInUser) {
        setIsLoggedIn(true);
        setUser(loggedInUser);
      }
      setChecking(false);
    }).catch((err) => {
      toast.error(getErrorMessage(err));
    });
  }, []);

  const handleLogin = async ({ email, password }) => {
    const { loggedInUser, authToken } = await login({ email, password });
    Cookies.set(WEB_AUTH_TOKEN_NAME, authToken, { expires: 7 });
    setIsLoggedIn(true);
    setUser(loggedInUser);
    return true;
  };

  const handleLogout = async () => {
    await logout();
    Cookies.remove(WEB_AUTH_TOKEN_NAME);
    setIsLoggedIn(false);
    setUser(null);
    return true;
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, handleLogin, handleLogout }}>
      {
        // TODO: Return an custom error page on initial checkLoggedIn failure
        checking ? <></> : children
      }
    </AuthContext.Provider>
  );
};
