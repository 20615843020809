import axios from "axios";
import Cookies from "js-cookie";
import { WEB_AUTH_TOKEN_NAME } from "../constants/api";
import { config, responseConfig } from "./api";

const requestConfig = async (request) => {
  const token = await Cookies.get(WEB_AUTH_TOKEN_NAME);
  request.headers.set(WEB_AUTH_TOKEN_NAME, token);
  return request;
};

export const apiSecure = axios.create(config);
apiSecure.interceptors.response.use(responseConfig);
apiSecure.interceptors.request.use(requestConfig);

export const apiSecureFullResponse = axios.create(config);
apiSecureFullResponse.interceptors.request.use(requestConfig);
