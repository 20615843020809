import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { AuthProvider } from "./contexts/AuthContext";
import ClassroomRoutes from "./routes/ClassroomRoutes";
import AppDownloadPage from "./views/pages/AppDownloadPage";
import ClassroomTestLiveParticipantPage from "./views/pages/ClassroomTestLiveParticipantPage";
import Home from "./views/pages/HomePage.js";
import QuickPlayGamePage from "./views/pages/QuickPlayGamePage.js";
import QuickPlayHomePage from "./views/pages/QuickPlayHomePage.js";
import QuickPlaySharingPage from "./views/pages/QuickPlaySharingPage.js";
import QuickPlaySummaryPage from "./views/pages/QuickPlaySummaryPage.js";
import ClassroomTestAssignmentPage from "./views/pages/ClassroomTestAssignmentPage";
import ClassroomRegisterPage from "./views/pages/ClassroomRegisterPage";

export default function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/quickplay" element={<QuickPlayHomePage />} />
          <Route path="/quickplay/game" element={<QuickPlayGamePage />} />
          <Route path="/quickplay/summary" element={<QuickPlaySummaryPage />} />
          <Route path="/quickplay/share/:shareId" element={<QuickPlaySharingPage />} />

          <Route path="/classroom/*" element={<AuthProvider><ClassroomRoutes /></AuthProvider>} />
          <Route path="/join" element={<ClassroomTestLiveParticipantPage />} />
          <Route path="/assignment" element={<ClassroomTestAssignmentPage />} />
          <Route path="/register" element={<ClassroomRegisterPage />} />

          <Route path="/download-app" element={<AppDownloadPage />} />

          {/* default redirect to home page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
        transition={Zoom}
      />
    </div>
  );
}
