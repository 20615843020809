import { geekblue } from "@ant-design/colors/lib";
import { LockOutlined, LoginOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Form } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getErrorCode, getErrorMessage } from "../../apiHandlers/apiUtils";
import { resetPassword, sendPasswordResetVerificationCode } from "../../apiHandlers/userApiHandler";
import schoolIcon from "../../assets/school.png";
import { CONTACT_EMAIL } from "../../constants/AppConstants";
import { useAuth } from "../../contexts/AuthContext";
import { showOrUpdateExistingErrorToast } from "../../utils/toastUtils";
import LoginFormInputItem from "../components/LoginFormInputItem";
import styles from "./ClassroomLoginPage.module.scss";
import PageLayout from "./PageLayout";

const VERIFICATION_CODE_RESEND_SECONDS = 60;

const ClassroomLoginPage = ({ loginMode = true }) => {
  const [pageInitialLoading, setPageInitialLoading] = useState(true);
  const [isInitialMode, setIsInitialMode] = useState(true);  // select between registration and login
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);

  // Resend verification code timer
  const [countdown, setCountdown] = useState(VERIFICATION_CODE_RESEND_SECONDS);
  const [isCounting, setIsCounting] = useState(false);

  const { handleLogin } = useAuth();
  const navigate = useNavigate();

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  const [form] = Form.useForm();
  const verificationCodeValue = Form.useWatch(["token"], form);  // Watch verificationCodeValue changes

  useEffect(() => {
    if (!loginMode) {
      setIsInitialMode(false);
      setIsLoginMode(false);
    }
    setPageInitialLoading(false);
  }, []);

  useEffect(() => {
    let interval;
    if (isCounting) {
      interval = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCounting]);

  const stopTimer = () => {
    setIsCounting(false);
    setCountdown(VERIFICATION_CODE_RESEND_SECONDS);
  };

  useEffect(() => {
    if (countdown === 0) {
      stopTimer();
    }
  }, [countdown]);

  useEffect(() => {
    setInvalidCode(false);
  }, [verificationCodeValue]);

  useEffect(() => {
    form.validateFields(["token"]);
  }, [invalidCode]);

  const handleSignIn = async (values) => {
    setSubmitLoading(true);
    setTimeout(async () => {
      try {
        const success = await handleLogin(values);
        if (success) {
          navigate("/classroom/tests", { replace: true });
          setSubmitLoading(false);
        }
      } catch (err) {
        const toastId = "login-error-toast";
        const message = getErrorCode(err) === 401 ? "Invalid username or password" : getErrorMessage(err);
        showOrUpdateExistingErrorToast(toastId, message);
        setSubmitLoading(false);
      }
      // This timeout is completely optional
    }, 500);
  };

  const handleSendVerificationCode = async () => {
    let targetEmail;
    try {
      const value = await form.validateFields(["email"]);
      targetEmail = value.email.trim();
    } catch (err) {
      // no special action is needed
    }

    if (!targetEmail) return;

    setSendCodeLoading(true);
    setTimeout(async () => {
      try {
        await sendPasswordResetVerificationCode(targetEmail);
        toast.success(`Verification code sent to ${targetEmail}. If the email is valid, you should receive it shortly.`, { autoClose: 4000 });
        setSendCodeLoading(false);
        setIsCounting(true);
      } catch (err) {
        const toastId = "send-verify-code-error-toast";
        const message = getErrorMessage(err);
        showOrUpdateExistingErrorToast(toastId, message);
        setSendCodeLoading(false);
      }
      // This timeout is completely optional
    }, 500);
  };

  const handleResetPassword = async (values) => {
    setSubmitLoading(true);
    setTimeout(async () => {
      try {
        await resetPassword({
          email: values.email.trim(),
          password: values.password,
          token: values.token.trim(),
        });
        toast.success("Your password has been successfully reset!", { autoClose: 4000 });
        form.resetFields(["token"]);
        stopTimer();
        setSubmitLoading(false);
      } catch (err) {
        if (getErrorCode(err) !== 401) {
          const toastId = "reset-pw-error-toast";
          showOrUpdateExistingErrorToast(toastId, getErrorMessage(err));
        } else {
          setInvalidCode(true);
        }
        setSubmitLoading(false);
      }
      // This timeout is completely optional
    }, 500);
  };

  const handleResetPasswordLinkClick = () => {
    navigate("/classroom/reset-password", { replace: true });
    setIsLoginMode(false);
  };

  const handleLoginModeSelect = () => {
    setIsInitialMode(false);
    setIsLoginMode(true);
  };

  const RegistrationOrLoginSelection = () => (
    <div className={styles["mode-selection-container"]}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: geekblue[5],
              fontWeight: "600",
              algorithm: true,
            },
          },
        }}
      >
        <Link to="/register" target="_blank">
          <Button type="primary" shape="round" className={styles["btn-mode-selection"]}>
            Register
          </Button>
        </Link>
      </ConfigProvider>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              fontWeight: "600",
              algorithm: true,
            },
          },
        }}
      >
        <Button type="default" shape="round" className={styles["btn-mode-selection"]}
                onClick={handleLoginModeSelect}
        >
          Sign In
        </Button>
      </ConfigProvider>
    </div>
  );

  const body = () => {
    return (
      <div className={styles.body}>
        {
          (isInitialMode || isLoginMode) &&
          <div className={styles["contact-info"]}>
            Contact us at <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
          </div>
        }

        {
          (isInitialMode || isLoginMode)
            ? <img alt="School Image" src={schoolIcon}
                   className={`${isInitialMode ? styles["school-icon-initial"] : styles["school-icon"]} img-unselectable`} />
            : <div className={styles["empty-box"]}></div>
        }

        {
          isInitialMode
            ? <RegistrationOrLoginSelection />
            : (
              <ConfigProvider
                theme={{
                  components: {
                    Form: {
                      labelFontSize: 20,
                      itemMarginBottom: 26,
                      verticalLabelPadding: "0 0 2px",
                    },
                  },
                  token: {
                    fontSize: 18,
                  },
                }}
              >
                <Form
                  form={form}
                  name="login"
                  onFinish={isLoginMode ? handleSignIn : handleResetPassword}
                  layout="vertical"
                  disabled={submitLoading}
                  style={
                    { fontWeight: 600 }
                  }
                >
                  <LoginFormInputItem
                    name="email"
                    label="Email"
                    rules={[
                      { required: true, message: "Please enter your email address" },
                      { type: "email", message: "Please enter a valid email address" },
                    ]}
                  />

                  <LoginFormInputItem
                    type="password"
                    name="password"
                    label={isLoginMode ? "Password" : "New Password"}
                    rules={[
                      { required: true, message: "Please enter a password" },
                      isLoginMode ? {} : { min: 6 },
                      isLoginMode ? {} : { max: 50 },
                    ]}
                  />

                  {
                    !isLoginMode &&
                    <LoginFormInputItem
                      type="password"
                      name="confirm"
                      label="Confirm New Password"
                      dependencies={["password"]}
                      rules={[
                        { required: true, message: "Please confirm your password" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error("The new password that you entered do not match"));
                          },
                        }),
                      ]}
                    />
                  }

                  {
                    !isLoginMode &&
                    <LoginFormInputItem
                      name="token"
                      label="Verification Code"
                      tooltip={`Click the 'Send Verification Code' button below to receive a 6-digit verification code sent to your registered email address.`}
                      rules={[
                        { required: true, whitespace: true, message: "Please enter verification code" },
                        () => ({
                          validator(_) {
                            if (invalidCode) {
                              return Promise.reject(new Error("Invalid verification code"));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    />
                  }

                  {
                    !isLoginMode &&
                    <div className={styles["btn-verify-container"]}>
                      <ConfigProvider
                        theme={{
                          components: {
                            Button: {
                              colorPrimary: "#4CAF50",
                              fontWeight: "500",
                              colorBgContainerDisabled: "rgba(137, 202, 140, 0.5)",
                              colorTextDisabled: "rgb(255, 255, 255)",
                              algorithm: true,
                            },
                          },
                        }}
                      >
                        <Button type="primary" onClick={handleSendVerificationCode} className={styles["btn"]}
                                loading={sendCodeLoading} disabled={submitLoading || isCounting}
                        >
                          {isCounting ? `Resend in ${countdown} seconds` : "Send Verification Code"}
                        </Button>
                      </ConfigProvider>
                    </div>
                  }

                  <Form.Item name="btn-submit">
                    <div className={styles["btn-submit-container"]}>
                      <ConfigProvider
                        theme={{
                          components: {
                            Button: {
                              fontWeight: "500",
                              colorBgContainerDisabled: "rgba(68, 145, 254, 0.5)",
                              colorTextDisabled: "rgb(255, 255, 255)",
                              algorithm: true,
                            },
                          },
                        }}
                      >
                        <Button type="primary" htmlType="submit" className={styles["btn"]}
                                loading={submitLoading} icon={isLoginMode ? <LoginOutlined /> : <LockOutlined />}
                        >
                          {isLoginMode ? "Sign In" : "Reset My Password"}
                        </Button>
                      </ConfigProvider>
                    </div>
                  </Form.Item>

                  {
                    isLoginMode &&
                    <div className={styles["link-reset-pw-container"]}>
                      <ConfigProvider
                        theme={{
                          components: {
                            Button: {
                              fontWeight: "600",
                            },
                          },
                          token: {
                            colorLink: "#1169dc",
                            colorLinkHover: "#004AAD",
                          },
                        }}
                      >
                        <Button type="link" className={styles["link-reset-pw"]} onClick={handleResetPasswordLinkClick}>
                          <span className={styles["link-reset-pw-text"]}>Forgot your password?</span>
                        </Button>
                      </ConfigProvider>
                    </div>
                  }
                </Form>
              </ConfigProvider>
            )
        }
      </div>
    );
  };

  if (pageInitialLoading) {
    return <></>;
  } else {
    return (<PageLayout body={(body())} displayMode="overlay" overlayBodyPadding={false} showNUSLogos={true} />);
  }
};

export default ClassroomLoginPage;
