import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getErrorCode } from "../../apiHandlers/apiUtils";
import { getSessionResults, initiateNewLiveSession } from "../../apiHandlers/classroomTestSessionApiHandler";
import { NETWORK_CONNECTION_ERROR_MSG } from "../../constants/error";
import {
  endSession,
  joinSessionAsOwner,
  listenToJoinStatus,
  listenToNewParticipantJoinSession,
  listenToParticipantCompleteSession,
  listenToParticipantLeaveSession,
  listenToSessionStart,
  socketConnect,
  socketDisconnect,
  startSession,
} from "../../services/classroomTestWebSocketService";
import { openFullScreenErrorModal } from "../../utils/ModalUtils";
import ClassroomTestLobby from "../components/ClassroomTestLobby";
import ClassroomTestProgress from "../components/ClassroomTestProgress";
import ClassroomTestSummary from "../components/ClassroomTestSummary";
import LoadingSpinBody from "../components/LoadingSpinBody";
import PageLayout from "./PageLayout";
import styles from "./ClassroomTestLiveOwnerPage.module.scss";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const OW_PAGE_STATUS_LOBBY = "owner lobby";
const OW_PAGE_STATUS_GAME_IP = "owner game in progress";
const OW_PAGE_STATUS_GAME_OVER = "owner game over";

const TEST_NOT_FOUND_ERROR_TITLE = "Oops! We couldn't find this test";
const TEST_NOT_FOUND_ERROR_CONTENT = "The test may have been deleted. Please use another test.";
const TEST_START_OTHER_ERROR_TITLE = "Failed to load this test";
const TEST_START_OTHER_ERROR_CONTENT = NETWORK_CONNECTION_ERROR_MSG;

export const WEBSOCKET_CONNECTION_ERROR_TITLE = "Connection lost...";
export const WEBSOCKET_CONNECTION_ERROR_CONTENT = "Sorry we're unable to reconnect automatically right now. Please consider refreshing the page or trying again later. Thanks for your patience.";

const ERROR_MODAL_OK_TEXT = "Back to all tests";
const ERROR_MODAL_OK_HREF = "/classroom/tests";

const ClassroomTestLiveOwnerPage = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [displayRoomNumber, setDisplayRoomNumber] = useState(false);
  const [displayTestTitle, setDisplayTestTitle] = useState(false);
  const [pageStatus, setPageStatus] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [testTitle, setTestTitle] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [participants, setParticipants] = useState([]);
  const [characters, setCharacters] = useState([]);
  const [endSessionLoading, setEndSessionLoading] = useState(false);
  const [sessionResult, setSessionResult] = useState(null);

  const participantsRef = useRef([]);
  useEffect(() => {
    participantsRef.current = participants;
  }, [participants]);

  const pageStatusRef = useRef("");
  pageStatusRef.current = pageStatus;

  const [searchParams] = useSearchParams();
  const testId = searchParams.get("testId");

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    // TODO: make custom message working
    e.returnValue = "Are you sure you want to exit?";
  };

  const initiateSession = async () => {
    let sid;
    try {
      const sessionInfo = await initiateNewLiveSession(testId);
      setRoomNumber(sessionInfo.roomNumber);
      setTestTitle(sessionInfo.quizTitle);
      sid = sessionInfo.sessionId;
      setSessionId(sid);
    } catch (err) {
      setPageLoading(false);
      // TODO: extract HTTP status codes
      if (getErrorCode(err) === 404) {
        openFullScreenErrorModal(TEST_NOT_FOUND_ERROR_TITLE, TEST_NOT_FOUND_ERROR_CONTENT, true, ERROR_MODAL_OK_TEXT, ERROR_MODAL_OK_HREF);
      } else {
        openFullScreenErrorModal(TEST_START_OTHER_ERROR_TITLE, TEST_START_OTHER_ERROR_CONTENT, true, ERROR_MODAL_OK_TEXT, ERROR_MODAL_OK_HREF);
      }
    }

    if (!sid) return;

    const handleNewParticipantJoinSession = (participant) => {
      setParticipants((prevWaitingList) => {
        return [{ ...participant, completed: false }, ...prevWaitingList];
      });
    };

    const handleParticipantLeaveSession = (participant) => {
      setParticipants((prevParticipants) => {
        const leftParticipant = prevParticipants.find(p => p.userId === participant.userId);
        if (!leftParticipant) {
          console.error("A left participant does not exist");
          return prevParticipants;
        }

        // A participant will only be removed from the list if he/she leaves before completing all the questions
        if (leftParticipant.completed) {
          return prevParticipants;
        }

        return prevParticipants.filter(p => p.userId !== participant.userId);
      });
    };

    const onConnect = () => {
      listenToJoinStatus(sid, () => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        // window.addEventListener("unload", () => {socketDisconnect();});

        listenToNewParticipantJoinSession(sid, handleNewParticipantJoinSession);
        listenToParticipantLeaveSession(sid, handleParticipantLeaveSession);

        setPageLoading(false);
        setDisplayRoomNumber(true);
        setDisplayTestTitle(true);
        setPageStatus(OW_PAGE_STATUS_LOBBY);
      }, () => {
        setPageLoading(false);
        // TODO: Change to modal with state instead of static Modal.error
        openFullScreenErrorModal(TEST_START_OTHER_ERROR_TITLE, TEST_START_OTHER_ERROR_CONTENT, true, ERROR_MODAL_OK_TEXT, ERROR_MODAL_OK_HREF);
      });

      joinSessionAsOwner();
    };

    const onWebSocketCloseOrError = () => {
      // TODO: Disable any page status update from now
      if (pageStatusRef.current !== OW_PAGE_STATUS_GAME_OVER) {
        openFullScreenErrorModal(WEBSOCKET_CONNECTION_ERROR_TITLE, WEBSOCKET_CONNECTION_ERROR_CONTENT, true, ERROR_MODAL_OK_TEXT, ERROR_MODAL_OK_HREF);
      }
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

    try {
      socketConnect(sid, true, onConnect, onWebSocketCloseOrError);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!testId) {
      openFullScreenErrorModal(TEST_NOT_FOUND_ERROR_TITLE, TEST_NOT_FOUND_ERROR_CONTENT, true, ERROR_MODAL_OK_TEXT, ERROR_MODAL_OK_HREF);
      return;
    }

    setPageLoading(true);

    initiateSession();

    // Cleanup the WebSocket and STOMP connection when the component unmounts
    // However, this is NEVER executed because this page is currently not a child of any parent component
    return () => {
      socketDisconnect();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoClick = () => {
    setDisplayRoomNumber(false);
    setDisplayTestTitle(false);
    setPageLoading(true);

    const handleParticipantCompleteSession = (participant) => {
      setParticipants((prevParticipants) => {
        const index = prevParticipants.findIndex(p => p.userId === participant.userId);

        if (index === -1) {
          console.error("A completed participant does not exist");
          return prevParticipants;
        }

        const newParticipants = [...prevParticipants];
        newParticipants[index] = {
          ...newParticipants[index],
          completed: true,
        };
        return newParticipants;
      });
    };

    listenToSessionStart(sessionId, (gameQuestions) => {
      listenToParticipantCompleteSession(sessionId, handleParticipantCompleteSession);

      const characters = gameQuestions.map(question => {
        return {
          id: question.chinese_character.id,
          svg_path: question.chinese_character.character_svg_path,
          chinese: question.chinese_character.chinese,
        };
      });
      setCharacters(characters);

      setDisplayRoomNumber(true);
      setDisplayTestTitle(true);
      setPageLoading(false);
      setPageStatus(OW_PAGE_STATUS_GAME_IP);
    });

    startSession();
  };

  const handleEndClick = () => {
    setEndSessionLoading(true);
    endSession();  // TODO: Need to handle internet connection error
    setTimeout(() => {
      checkReportAvailability(1);
    }, 200);
  };

  // TODO: check availability at backend or frontend?
  const checkReportAvailability = (numAttempts) => {
    // console.log("check report availability, numAttempts = ", numAttempts)
    if (hasAllParticipantsCompletedSession() || numAttempts > 6) {
      fetchSessionResult().then(() => {
          setEndSessionLoading(false);
          setDisplayRoomNumber(false);
          setDisplayTestTitle(false);
          setPageStatus(OW_PAGE_STATUS_GAME_OVER);
        },
      );
    } else {
      // TODO: Reason about whether it's possible that at the end not all participants has completed = true
      setTimeout(() => checkReportAvailability(numAttempts + 1), 500);
    }
  };

  const hasAllParticipantsCompletedSession = () => {
    return participantsRef.current.filter(p => p.completed === false).length === 0;
  };

  const fetchSessionResult = async () => {
    try {
      const sessionResult = await getSessionResults(sessionId);
      setSessionResult(sessionResult);
    } catch (err) {
      // TODO: Handle error! should allow view result anyway
      console.log(err);
    }
  };

  const RoomNumber = () => (
    // TODO: separate "Room:" and number using a Row
    <div className={styles["room-number"]}>Room: {roomNumber}</div>
  );

  const TestTitle = () => (
    <div className={styles["test-title-container"]}>
      <div className={styles["test-title"]} style={{ fontSize: testTitle.length > 25 ? "20px" : "28px" }}>
        {testTitle}
      </div>
    </div>
  );

  const BodyContent = () => {
    if (pageLoading) {
      return <LoadingSpinBody />;
    } else if (pageStatus === OW_PAGE_STATUS_LOBBY) {
      return <ClassroomTestLobby roomNumber={roomNumber} participants={participants} onGoClick={handleGoClick} />;
    } else if (pageStatus === OW_PAGE_STATUS_GAME_IP) {
      return <ClassroomTestProgress roomNumber={roomNumber} participants={participants} onEndClick={handleEndClick}
                                    endLoading={endSessionLoading} />;
    } else if (pageStatus === OW_PAGE_STATUS_GAME_OVER) {
      return <ClassroomTestSummary characters={characters} sessionResult={sessionResult} />;
    }
  };

  const Body = () => {
    return (
      <div className={styles.body}>
        {displayRoomNumber && <RoomNumber />}
        {displayTestTitle && <TestTitle />}
        <BodyContent />
      </div>
    );
  };

  return (
    <PageLayout body={<Body />} displayMode="overlay" showNUSLogos={md} />
  );
};

export default ClassroomTestLiveOwnerPage;
