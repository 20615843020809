import React from "react";
import styles from "./MobileAppDownloadSection.module.scss";

const MobileAppDownloadSection = () => {
  return (
    <div className={styles["text-container"]}>
      <div className={styles["download-message"]}>
        Download our app to unlock the full experience!
      </div>

      <div className={styles["learning-detail-messages-container"]}>
        <div className={styles["learning-detail-message"]}>NUS/NTU/HSK/MOE Syllabus</div>
        <div className={styles["learning-detail-message"]}>Learning History</div>
        <div className={styles["learning-detail-message"]}>Radical Knowledge</div>
        <div className={styles["learning-detail-message"]}>Periodical Revision</div>
      </div>
    </div>
  );
};

export default MobileAppDownloadSection;
