import { Button, Modal, Radio, Rate, Spin } from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { recordQuickPlaySurveyData } from "../../apiHandlers/surveyApiHandler";
import styles from "./Survey.module.scss";

const Survey = ({
                  uuid,
                  isSurveyModalOpen,
                  setSurveyModalOpen,
                  experienceRateValue,
                  setExperienceRateValue,
                  tryMoreCharsValue,
                  setTryMoreCharsValue,
                  shareInterestValue,
                  setShareInterestValue,
                  mobileInterestValue,
                  setMobileInterestValue,
                  difficultyViewValue,
                  setDifficultyViewValue,
                }) => {

  const [isSubmittingData, setSubmittingData] = useState(false);

  async function recordData(uuid, experienceRateValue, tryMoreCharsValue, shareInterestValue, mobileInterestValue, difficultyViewValue) {
    try {
      await setSubmittingData(true);
      const result = await recordQuickPlaySurveyData(uuid, experienceRateValue, tryMoreCharsValue, shareInterestValue, mobileInterestValue, difficultyViewValue);
      await setSubmittingData(false);

      if (result === "Success") {
        toast.info("Thank you for your response! Happy Learning! :)", { autoClose: 1200 });
      } else {
        toast.error("Something went wrong, please try again later! :(", { autoClose: 600 });
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  function handleClosePanel(setSurveyModalOpen) {
    if (experienceRateValue !== 0 || tryMoreCharsValue !== 0 || shareInterestValue !== 0
      || mobileInterestValue !== 0 || difficultyViewValue !== 0) {
      recordData(uuid, experienceRateValue, tryMoreCharsValue, shareInterestValue, mobileInterestValue, difficultyViewValue)
        .then(r => setSurveyModalOpen(false));
    } else {
      setSurveyModalOpen(false);
    }
  }

  return (
    <Modal
      open={isSurveyModalOpen}
      footer={null}
      onOk={() => handleClosePanel(setSurveyModalOpen)}
      onCancel={() => handleClosePanel(setSurveyModalOpen)}
      centered
    >
      {isSubmittingData
        ? <div className={styles.loading}><Spin size="large" /></div>
        : (
          <div className={styles.surveyPanel}>
            <p className={styles.shareSubheading}> How do you rate your game experience? </p>
            <Rate style={{ color: "#ffbd03", fontSize: 40 }} className={styles.madeCenter}
                  onChange={(value) => setExperienceRateValue(value)} defaultValue={experienceRateValue} />

            <p className={styles.shareSubheading}> I have more interest in trying more characters after this game </p>
            <Radio.Group size="large" className={styles.madeCenter} defaultValue={tryMoreCharsValue}
                         onChange={(e) => setTryMoreCharsValue(e.target.value)}>
              <Radio.Button value="1" className={styles.radioOption}>Disagree</Radio.Button>
              <Radio.Button value="2" className={styles.radioOption}>Neutral</Radio.Button>
              <Radio.Button value="3" className={styles.radioOption}>Agree</Radio.Button>
            </Radio.Group>

            <p className={styles.shareSubheading}> I have interest in sharing the image generated </p>
            <Radio.Group size="large" className={styles.madeCenter} defaultValue={shareInterestValue}
                         onChange={(e) => setShareInterestValue(e.target.value)}>
              <Radio.Button value="1" className={styles.radioOption}>Disagree</Radio.Button>
              <Radio.Button value="2" className={styles.radioOption}>Neutral</Radio.Button>
              <Radio.Button value="3" className={styles.radioOption}>Agree</Radio.Button>
            </Radio.Group>

            <p className={styles.shareSubheading}> I have interest in installing the mobile app </p>
            <Radio.Group size="large" className={styles.madeCenter} defaultValue={mobileInterestValue}
                         onChange={(e) => setMobileInterestValue(e.target.value)}>
              <Radio.Button value="1" className={styles.radioOption}>Disagree</Radio.Button>
              <Radio.Button value="2" className={styles.radioOption}>Neutral</Radio.Button>
              <Radio.Button value="3" className={styles.radioOption}>Agree</Radio.Button>
            </Radio.Group>

            <p className={styles.shareSubheading}> I find the difficulty of the game... </p>
            <Radio.Group size="large" className={styles.madeCenter} defaultValue={difficultyViewValue}
                         onChange={(e) => setDifficultyViewValue(e.target.value)}>
              <Radio.Button value="1" className={styles.radioOption}>Too hard</Radio.Button>
              <Radio.Button value="2" className={styles.radioOption}>Suitable</Radio.Button>
              <Radio.Button value="3" className={styles.radioOption}>Too easy</Radio.Button>
            </Radio.Group>

            <div className={styles.submitButtonArea}>
              <Button type="primary" size={"large"} onClick={() => handleClosePanel(setSurveyModalOpen)}>
                Submit
              </Button>

            </div>
          </div>

        )
      }
    </Modal>
  );
};

export default Survey;
