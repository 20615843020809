export const APP_BASE_URL = process.env.REACT_APP_ENV === "dev"
  ? `http://${process.env.REACT_APP_FRONTEND_DOMAIN_LOCAL}`
  : "https://pinit-nus.com";

export const API_BASE_URL = process.env.REACT_APP_ENV === "dev"
  ? `http://${process.env.REACT_APP_BACKEND_DOMAIN_LOCAL}/v1`
  : "https://api.pinit-nus.com/v1";

export const WEB_API_BASE_URL = API_BASE_URL + "/web";

export const CCL_ASSETS_PATH = "https://s3.ap-southeast-1.amazonaws.com/ccl.assets/";

export const SHARE_ASSETS_PATH = "https://s3.ap-southeast-1.amazonaws.com/ccl.web.share/";

/* Cookies */
export const WEB_AUTH_TOKEN_NAME = "web-satoken";

/* Local Storage */
export const CLASSROOM_TEACHER_FEEDBACK_SUBMITTED = "feedbackSubmitted";
export const CLASSROOM_STUDENT_FEEDBACK_SUBMITTED = "studentFeedbackSubmitted";

export const MOBILE_APP_DOWNLOAD_EVENT_TYPE_APP_DOWNLOAD_PAGE_VISIT = "APP_DOWNLOAD_PAGE_VISIT";
export const MOBILE_APP_DOWNLOAD_EVENT_TYPE_STORE_BADGE_CLICK = "STORE_BADGE_CLICK";

export const MOBILE_APP_DOWNLOAD_EVENT_BADGE_TYPE_GOOGLE_PLAY_STORE = "GOOGLE_PLAY_STORE";
export const MOBILE_APP_DOWNLOAD_EVENT_BADGE_TYPE_APPLE_APP_STORE = "APPLE_APP_STORE";

export const MOBILE_APP_DOWNLOAD_EVENT_BADGE_TYPE_ANDROID_APK = "ANDROID_APK";


export const MOBILE_APP_DOWNLOAD_EVENT_REFERER_HOME_PAGE = "home_page";
export const MOBILE_APP_DOWNLOAD_EVENT_REFERER_QUICK_PLAY = "quickplay";
export const MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_QUIZ_SESSION = "classroom";
export const MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_QUIZ_SESSION_QR = "classroom_qr";
export const MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_APP_DOWNLOAD_PAGE = "app_download_page";
export const MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_APP_DOWNLOAD_PAGE_QR = "app_download_page_qr";
