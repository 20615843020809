import { toast } from "react-toastify";

const TOAST_MIN_DISPLAY_DURATION = 700;

export const updateToastAfterMinDuration = (toastId, startTime, updateOptions) => {
  const elapsed = Date.now() - startTime; // Calculate the duration for which the toast has been displayed
  if (elapsed < TOAST_MIN_DISPLAY_DURATION) {
    // If the elapsed time is less than 1 second, delay the toast update
    setTimeout(() => {
      toast.update(toastId, updateOptions);
    }, TOAST_MIN_DISPLAY_DURATION - elapsed);
  } else {
    // Else update the toast immediately
    toast.update(toastId, updateOptions);
  }
};

export const dismissToastAfterMinDuration = (toastId, startTime) => {
  const elapsed = Date.now() - startTime; // Calculate the duration for which the toast has been displayed
  if (elapsed < TOAST_MIN_DISPLAY_DURATION) {
    // If the elapsed time is less than 1 second, delay the toast dismissal
    setTimeout(() => {
      toast.dismiss(toastId);
    }, TOAST_MIN_DISPLAY_DURATION - elapsed);
  } else {
    // Else dismiss the toast immediately
    toast.dismiss(toastId);
  }
};

export const showOrUpdateExistingErrorToast = (toastId, message, options = {}) => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, { render: message, ...options });
  } else {
    toast.error(message, { toastId, ...options });
  }
};

export const showOrUpdateExistingInfoToast = (toastId, message) => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, { render: message });
  } else {
    toast.info(message, { toastId });
  }
};
