import { RedoOutlined, StarOutlined } from "@ant-design/icons";
import { Button, Divider, Row } from "antd";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";
import { updateQuickPlayStatistics } from "../../apiHandlers/gameApi";
import { uploadShareImage } from "../../apiHandlers/quickplayShareApiHandler";

import SaveImageIcon from "../../assets/save-image-icon.svg";
import { APP_BASE_URL, MOBILE_APP_DOWNLOAD_EVENT_REFERER_QUICK_PLAY, SHARE_ASSETS_PATH } from "../../constants/api";
import ImageGenerator from "./ImageGenerator";
import MobileAppGetButton from "./MobileAppGetButton";
import styles from "./QuickPlayGameSummary.module.scss";
import Survey from "./Survey";


function InstagramShareButton(props) {
  return null;
}

InstagramShareButton.propTypes = {
  children: PropTypes.any,
  url: PropTypes.string,
};

function InstagramIcon(props) {
  return null;
}

InstagramIcon.propTypes = {
  size: PropTypes.number,
  round: PropTypes.bool,
};
const QuickPlayGameSummary = ({ performanceData, uuid }) => {
  const [isSurveyModalOpen, setSurveyModalOpen] = useState(false);
  const [isStoreModalOpen, setStoreModalOpen] = useState(false);

  const [isUploadingImage, setUploadingImage] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const [hasUploadedImage, setUploadedImage] = useState(false);
  const FIXED_LANDING_PAGE = APP_BASE_URL + "/quickplay"; // Same for everyone
  const DEFAULT_SHARE_IMAGE = SHARE_ASSETS_PATH + "share-image.png";
  const CALL_FOR_ACTION = "Assemble Chinese characters yourselves at Pin It!";
  const DYNAMIC_LANDING_PAGE = FIXED_LANDING_PAGE + "/share/" + uuid;
  const [shareImageLocation, setShareImageLocation] = useState(SHARE_ASSETS_PATH + uuid + ".jpeg");
  const [isUploadToS3Required, setIsUploadToS3Required] = useState(false);
  const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState(false);
  const [shareMessage] = useState(generateSharingText());

  // For survey

  const [experienceRateValue, setExperienceRateValue] = useState(0);
  const [tryMoreCharsValue, setTryMoreCharsValue] = useState(0);
  const [shareInterestValue, setShareInterestValue] = useState(0);
  const [mobileInterestValue, setMobileInterestValue] = useState(0);
  const [difficultyViewValue, setDifficultyViewValue] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSurveyModalOpen(true);
    }, 5000); // 3 seconds in milliseconds

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  /*
    Sharing text sample:
    Assemble Chinese characters yourselves at Pin It!
    https://pinit-nus.com/quickplay/
    My record:
    🟥🟩🟩🟩🟩🟧🟧🟩🟩🟨
    Details:
    🟩 x 6 - 以、而、散、思、舞、流
    🟨 x 1 - 气
    🟧 x 2 - 拔、酒
    🟥 x 1 - 拾

    For Whatsapp, extra two lines below:
    Click this:
    https://pinit-nus.com/quickplay/share/UUID-UUID-UUID
   */

  function generateSharingText() {
    // String and data initialisation
    let tempShareMessage = "",
      progressBar = "",
      redCharacters = "",
      orangeCharacters = "",
      yellowCharacters = "",
      greenCharacters = "";
    let iterationCount = 0,
      redCount = 0,
      orangeCount = 0,
      yellowCount = 0,
      greenCount = 0;

    for (const color of performanceData.progressBarColours) {
      let currentChineseCharacter = performanceData.questionAttempts[iterationCount].characterDetails.chinese;
      switch (color) {
      case "#389e0d":
        progressBar += "🟩";
        greenCharacters += (greenCount === 0 ? currentChineseCharacter : "、" + currentChineseCharacter);
        greenCount += 1;
        break;
      case "#fadb14":
        progressBar += "🟨";
        yellowCharacters += (yellowCount === 0 ? currentChineseCharacter : "、" + currentChineseCharacter);
        yellowCount += 1;
        break;
      case "#fa541c":
        progressBar += "🟧";
        orangeCharacters += (orangeCount === 0 ? currentChineseCharacter : "、" + currentChineseCharacter);
        orangeCount += 1;
        break;
      case "#a8071a":
        progressBar += "🟥";
        redCharacters += (redCount === 0 ? currentChineseCharacter : "、" + currentChineseCharacter);
        redCount += 1;
        break;
      default:
        break;
      }
      iterationCount += 1;
    }

    tempShareMessage = CALL_FOR_ACTION + "\n"
      + FIXED_LANDING_PAGE + "\n"
      + "My record:\n"
      + progressBar + "\n"
      + "Details:\n"
      + "🟩 x " + greenCount + " - " + greenCharacters + "\n"
      + "🟨 x " + yellowCount + " - " + yellowCharacters + "\n"
      + "🟧 x " + orangeCount + " - " + orangeCharacters + "\n"
      + "🟥 x " + redCount + " - " + redCharacters + "\n";

    return tempShareMessage;
  }

  // Function to handle the download button click
  const handleDownloadClick = () => {
    setIsDownloadButtonClicked(true);
  };

  function updateClickAttempt(parameter) {
    updateQuickPlayStatistics(parameter, uuid);
  }

  function handleShareButtonClick(social_media, uuid) {
    const uploadImage = async () => {
      if (hasUploadedImage) {
        return;
      }

      if (base64Image === "") {
        toast.info("Image is still converting, please try again in 5 seconds.", { autoClose: 600 });
        return;
      }

      try {
        console.log("Uploading to S3.");
        setUploadingImage(true);

        const receivedUuid = await uploadShareImage(base64Image, uuid);
        if (receivedUuid === "failed") {
          // Use default share image if the upload failed
          await setShareImageLocation(DEFAULT_SHARE_IMAGE);
          toast.error("Image not uploaded. Personalised sharing may not be available", { autoClose: 600 });
        } else {
          await setUploadedImage(true);
        }
      } catch (err) {
        toast.error("Something went wrong. Image sharing may not be available.", { autoClose: 600 });
        console.log(err);
      }
    };

    updateClickAttempt(social_media);

    // Return the promise returned by uploadImage
    return uploadImage().then(() => {
      setUploadingImage(false);
    });
  }

  return (
    <div>
      <Row justify="center" align="middle" className={styles.shareImageWindow}>
        <ImageGenerator {...performanceData} setBase64Image={setBase64Image} playMode={"quickplay"}
                        isDownloadButtonClicked={isDownloadButtonClicked}
                        setIsDownloadButtonClicked={setIsDownloadButtonClicked} />

      </Row>

      <Divider className={styles.dividerWords}>Share the image, challenge your friends!</Divider>

      <Row className={styles.buttonsRow}>
        {/* Facebook only allows sharing of a link. The link of the share image hosted at AWS S3 is shared. */}
        <FacebookShareButton children={<FacebookIcon size={64} round={true} />}
                             url={shareImageLocation} beforeOnClick={() => handleShareButtonClick("facebook", uuid)}
                             className={styles.buttons} disabled={isUploadingImage || base64Image === ""} />
        {/* In Twitter, the url is added below the title automatically. */}
        <TwitterShareButton children={<TwitterIcon size={64} round={true} />} url={DYNAMIC_LANDING_PAGE}
                            title={shareMessage} beforeOnClick={() => handleShareButtonClick("twitter", uuid)}
                            className={styles.buttons}
                            disabled={isUploadingImage || base64Image === ""} />
        <WhatsappShareButton children={<WhatsappIcon size={64} round={true} />}
                             url={DYNAMIC_LANDING_PAGE} title={shareMessage} separator={"\nClick this: \n"}
                             beforeOnClick={() => handleShareButtonClick("whatsapp", uuid)} className={styles.buttons}
                             disabled={isUploadingImage || base64Image === ""} />

        {/* Credit: The below download image icon is created by The Icon Z from the Noun Project */}
        <Button type="primary" shape="circle" style={{ height: 64, width: 64, backgroundColor: "#5783db" }}
                icon={<img src={SaveImageIcon} style={{ height: 40, width: 40 }} />}
                onClick={handleDownloadClick} className={styles.buttons} />

        <Button type="primary" shape="circle" style={{ height: 64, width: 64, backgroundColor: "#ffbd03" }}
                icon={<StarOutlined style={{ fontSize: "25px" }} />}
                onClick={() => setSurveyModalOpen(true)} className={styles.buttons} />

        <Link to="/quickplay">
          <Button type="primary" shape="circle" style={{ height: 64, width: 64, backgroundColor: "#5dbea3" }}
                  icon={<RedoOutlined style={{ fontSize: "25px" }} />} className={styles.buttons}
                  onClick={() => updateQuickPlayStatistics("play_again", uuid)} />
        </Link>
      </Row>

      <div className={styles.centraliseBox}>
        <div className={styles.storeBox}>
          <MobileAppGetButton referer={MOBILE_APP_DOWNLOAD_EVENT_REFERER_QUICK_PLAY} uid={null} />
        </div>
      </div>

      <Survey
        uuid={uuid}
        isSurveyModalOpen={isSurveyModalOpen}
        setSurveyModalOpen={setSurveyModalOpen}
        experienceRateValue={experienceRateValue}
        setExperienceRateValue={setExperienceRateValue}
        tryMoreCharsValue={tryMoreCharsValue}
        setTryMoreCharsValue={setTryMoreCharsValue}
        shareInterestValue={shareInterestValue}
        setShareInterestValue={setShareInterestValue}
        mobileInterestValue={mobileInterestValue}
        setMobileInterestValue={setMobileInterestValue}
        difficultyViewValue={difficultyViewValue}
        setDifficultyViewValue={setDifficultyViewValue}
      />
    </div>
  );
};
export default QuickPlayGameSummary;
