import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { CCL_ASSETS_PATH } from "../../constants/api";
import styles from "./Character.module.scss";

const Character = ({ character, fallbackFontSize, fallbackFontColor, fontColor, disableSVG = false }) => {
  const [svgLoadingFailed, setSvgLoadingFailed] = useState(false);

  if (!character) {
    return "";
  }

  return (
    disableSVG || svgLoadingFailed
      ?
      <div className={styles["character-chinese"]}  // TODO: Better deal with default font size
           style={{ fontSize: fallbackFontSize || 20, color: fallbackFontColor || "black" }}>
        {character.chinese ? character.chinese : ""}
      </div>
      :
      <SVG
        src={`${CCL_ASSETS_PATH}${character.svg_path}`} // src can be both URL or svg string!!
        preProcessor={(code) => {
          // Different SVGs have conflicting cls-1 and cls-2 values!
          // return code.replace(/fill:#6360ff;/g, 'fill:currentColor;'); THIS SIMPLE SOLUTION DOES NOT WORK DUE TO CONFLICT

          return code.replace(/<rect\b([^>]*)class="([^"]*)"\s*([^>]*)>/, `<rect$1style="fill:none;" $3>`)
            .replace(/class="([^"]*)"/, "style=\"fill:currentColor;\"");
        }}
        className={styles["character-svg"]}
        onError={() => {setSvgLoadingFailed(true);}}
      />
  );
};

export default Character;
