import { Button, Col, ConfigProvider, Dropdown, Row, Tooltip } from "antd";
import React, { useState } from "react";
import styles from "./ClassroomTestRecordItem.module.scss";
import { getAssignedSessionInfo } from "../../apiHandlers/classroomTestSessionApiHandler";
import { openClassroomTestSessionShareModal } from "./ClassroomTestSessionShareModal";
import { getErrorCode, getErrorMessage } from "../../apiHandlers/apiUtils";
import { showOrUpdateExistingErrorToast } from "../../utils/toastUtils";
import {
  BarChartOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { blue, green, grey } from "@ant-design/colors";
import { Link } from "react-router-dom";

const SESSION_TO_SHARE_NOT_FOUND_ERROR_MSG = "Oops! This session can't be shared right now. It may have completed or been deleted.";

const ACTION_KEY_VIEW = "view";
const ACTION_KEY_END = "end";
const ACTION_KEY_SHARE = "share";
const ACTION_KEY_RENAME = "rename";
const ACTION_KEY_DELETE = "delete";

function ClassroomTestRecordItem({ record, onEndClick, onDeleteClick, onRenameClick, highlight }) {
  const [shareLoading, setShareLoading] = useState(false);
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false);

  const handleShareClick = async () => {
    setShareLoading(true);
    setTimeout(async () => {
      try {
        const sessionInfo = await getAssignedSessionInfo(record.id);
        openClassroomTestSessionShareModal(sessionInfo);
        setShareLoading(false);
        preventAccidentalMouseEventAndCloseMenu();
      } catch (err) {
        const toastId = "assigned-session-share-error-toast";
        const errorCode = getErrorCode(err);
        if (errorCode === 404) {
          showOrUpdateExistingErrorToast(toastId, SESSION_TO_SHARE_NOT_FOUND_ERROR_MSG, { autoClose: 5000 });
        } else {
          showOrUpdateExistingErrorToast(toastId, getErrorMessage(err));
        }
        setShareLoading(false);
      }
      // This timeout is completely optional
    }, 500);
  };

  const handleEndClick = () => {
    onEndClick(record);
  };

  const handleDeleteClick = () => {
    onDeleteClick(record);
  };

  const handleRenameClick = () => {
    onRenameClick(record);
  };

  const RecordStatusIcon = ({ status }) => {
    const statusIconTextSize = "26px";
    switch (status) {
    case "NOT_STARTED":
      return (
        <Tooltip title={"Not Started"} color={grey[7]}>
          <ClockCircleOutlined style={{ fontSize: statusIconTextSize, color: grey[7] }} />
        </Tooltip>
      );
    case "IN_PROGRESS":
      return (
        <Tooltip title="In Progress" color={blue[6]}>
          <Loading3QuartersOutlined style={{ fontSize: statusIconTextSize, color: blue[6] }} />
        </Tooltip>
      );
    case "COMPLETED":
      return (
        <Tooltip title="Completed" color={green[6]}>
          <CheckCircleOutlined style={{ fontSize: statusIconTextSize, color: green[6] }} />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={status}>
          <QuestionCircleOutlined style={{ fontSize: statusIconTextSize }} />
        </Tooltip>
      );
    }
  };

  const ActionLabel = ({ icon, text }) => (
    <div className={styles["action"]}>
      {icon}
      <div className={styles["action-text"]}>
        {text}
      </div>
    </div>
  );

  const handleMenuActionClick = (e) => {
    switch (e.key) {
    case ACTION_KEY_VIEW:
      break;
    case ACTION_KEY_END:
      handleEndClick();
      preventAccidentalMouseEventAndCloseMenu();
      break;
    case ACTION_KEY_SHARE:
      handleShareClick();
      // share will be loading first before closing the menu
      break;
    case ACTION_KEY_RENAME:
      handleRenameClick();
      preventAccidentalMouseEventAndCloseMenu();
      break;
    case ACTION_KEY_DELETE:
      handleDeleteClick();
      preventAccidentalMouseEventAndCloseMenu();
      break;
    }
  };

  const actions = [
    {
      key: ACTION_KEY_VIEW,
      label: (
        <Link to={`/classroom/results?sessionId=${record.id}`} target="_blank">
          <ActionLabel icon={<BarChartOutlined />} text="View" />
        </Link>
      ),
    },
    record.canBeEnded && {
      key: ACTION_KEY_END,
      label: <ActionLabel icon={<CheckCircleOutlined />} text="End" />,
    },
    record.canBeShared && {
      key: ACTION_KEY_SHARE,
      label: <ActionLabel icon={shareLoading ? <LoadingOutlined /> : <SendOutlined />} text="Share" />,
    },
    {
      key: ACTION_KEY_RENAME,
      label: <ActionLabel icon={<EditOutlined />} text="Rename" />,
    },
    record.canBeDeleted && {
      key: ACTION_KEY_DELETE,
      label: <ActionLabel icon={<DeleteOutlined />} text="Delete" />,
    },
  ];

  const menuProps = {
    items: actions,
    onClick: handleMenuActionClick,
  };

  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setActionsMenuOpen(nextOpen);
    }
  };

  const preventAccidentalMouseEventAndCloseMenu = () => {
    // the modal takes some time to open, and the mask takes some time to take effect to hide other items
    // we use this to prevent the other buttons being hovered accidentally during this period

    const styleElement = document.createElement("style");
    styleElement.innerHTML = `* { pointer-events: none; }`;
    document.body.appendChild(styleElement);

    setTimeout(() => {
      styleElement.remove();
    }, 300);

    setTimeout(() => {
      setActionsMenuOpen(false);
    }, 80);
  };

  return (
    <div className={`${styles["record-box"]} ${highlight ? styles.highlight : ""}`}>
      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Row justify="space-evenly" align="middle" gutter={3}>
            <Col span={17}>
              <div className={styles["record-title"]}>{record.title}</div>
              <div className={styles["record-start-time"]}>Start Time: {record.startedAtStr}</div>
              {/*<div className={styles["record-end-time"]}>End Time: {record.endedAt}</div>*/}
            </Col>
            <Col span={4}>
              <RecordStatusIcon status={record.status} />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row justify="space-evenly" align="middle">
            <Col xs={24} lg={10}>
              <ConfigProvider theme={{
                token: {
                  motionDurationMid: "0.05s",
                },
                components: {
                  Button: {
                    contentFontSize: 18,
                    fontWeight: "500",
                    controlHeight: 35,
                    paddingInline: 0,
                    algorithm: true,
                  },
                },
              }}>
                <Dropdown menu={menuProps} onOpenChange={handleOpenChange} open={actionsMenuOpen} trigger="hover"
                          mouseEnterDelay={0.3}>
                  <div className={styles["btn-control"]}>
                    <Button type="default" block size="middle">
                      Actions
                      <DownOutlined />
                    </Button>
                  </div>
                </Dropdown>
              </ConfigProvider>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ClassroomTestRecordItem;
