import { api } from "./api";

const LOG_ENDPOINT = "/log";

export const logMobileAppDownloadClickEvent = async (eventType, refererList, badgeType, userId) => {
  // TODO: Maybe can validate parameter here before sending request?
  try {
    await api.post(`${LOG_ENDPOINT}/click/app-download`, { eventType, refererList, badgeType, userId });
  } catch (err) {
    // ignore error
    console.log(err);
  }
};
