import { CheckCircleTwoTone, CloseCircleFilled } from "@ant-design/icons";
import React from "react";
import Character from "./Character";
import styles from "./CharacterOption.module.scss";

function CharacterOption({
                           character,
                           bgColor = "#58E440",
                           size = 35,
                           outline = false,
                           enableSelect = false,
                           onSelect,
                           enableRemove = false,
                           onRemove,
                           showSelectedTick = false,
                         }) {
  const customStyle = {
    backgroundColor: bgColor,
    width: size,
    height: size,
    outline: outline ? "2px dashed #B6B2B2" : "none",
  };

  const hoverClass = enableSelect ? styles.hoverEffectSelect : enableRemove ? styles.hoverEffectRemove : "";

  const handleCharacterClick = () => {
    if (!enableSelect) return;
    onSelect(character);
  };

  const handleCrossIconClick = () => {
    if (!enableRemove) return;
    onRemove(character);
  };

  return (
    <div className={`${styles.container} ${hoverClass}`} style={customStyle} onClick={handleCharacterClick}>

      {/*alternative solution of rendering SVG as image*/}
      {/*<img src={`data:image/svg+xml;utf8,${encodeURIComponent(characterSvg)}`} alt="" draggable="false" />*/}

      <Character character={character} fallbackFontSize={30} disableSVG={true} />

      {
        enableRemove
          ? <CloseCircleFilled className={styles["icon-cross"]} style={{ fontSize: "18px", color: "#BE1E2D" }}
                               onClick={handleCrossIconClick} />
          : showSelectedTick
            ? <CheckCircleTwoTone className={styles["icon-tick"]} twoToneColor="#58E440" style={{ fontSize: "18px" }} />
            : ""
      }
    </div>
  );
}

export default CharacterOption;
