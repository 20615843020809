import { Button, Col, ConfigProvider, Progress, Row } from "antd";
import React from "react";
import styles from "./ClassroomTestProgress.module.scss";
import ClassroomTestJoinInstruction from "./ClassroomTestJoinInstruction";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const ClassroomTestProgress = ({ roomNumber, participants, onEndClick, endLoading }) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  const numCompleted = participants.filter(p => p.completed === true).length;
  const completionRatio = Math.round((numCompleted / participants.length) * 100);

  return (
    <div className={styles.body}>
      <div className={styles["main-container"]}>
        <div className={styles["row-container"]}>
          <Row className={styles["row"]}>
            <Col span={12}>
              <ClassroomTestJoinInstruction roomNumber={roomNumber} />
            </Col>
            <Col span={12}>
              <div className={styles["session-progress-container"]}>
                <div className={styles["session-running-text"]}>Session Running</div>
                <div className={styles["progress-circle-container"]}>
                  <div className={styles["progress-text"]}>{numCompleted}/{participants.length} Completed</div>
                  <Progress type="circle" percent={completionRatio} format={() => ""} size={sm ? 280 : 170} />
                </div>
              </div>
              <div className={styles["bottom-area"]}>
                <div className={styles["btn-end-container"]}>
                  <div className={styles["btn-end"]}>
                    <ConfigProvider theme={{
                      components: {
                        Button: {
                          colorPrimary: "#f46c00",
                          contentFontSizeLG: 28,
                          fontWeight: "500",
                          paddingInlineLG: 0,
                          controlHeightLG: 50,
                          algorithm: true,
                        },
                      },
                    }}>
                      {/* TODO: For some reason the button momentarily stops and reverts to loading when participants got updated in the middle */}
                      {/* TODO: e.g. if a participant leaves just before end test is clicked and it takes 2 seconds to receive the left message */}
                      <Button type="primary" block size="large" onClick={onEndClick} loading={endLoading}>
                        End Test
                      </Button>
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ClassroomTestProgress;
