import { ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tooltip } from "antd";
import React from "react";
import nusLogos from "../../assets/nus_logos.png";
import pinDemonstrationGif from "../../assets/pin-illustration.gif";
import pinitLogoFull from "../../assets/pin_logo_svg/2067_char.svg";
import pinitLogoLeft from "../../assets/pin_logo_svg/2067_option_l.svg";
import pinitLogoRight from "../../assets/pin_logo_svg/2067_option_r.svg";
import pinitText from "../../assets/pin_logo_svg/pinit_word_transparent_background.png";
import pinitLogoStructure from "../../assets/structure_svg/LEFT_RIGHT.svg";
import { MOBILE_APP_DOWNLOAD_EVENT_REFERER_HOME_PAGE } from "../../constants/api";
import styles from "./AnimatedSlideshow.module.scss";
import MobileAppBadges from "./MobileAppBadges";
import { CONTACT_EMAIL } from "../../constants/AppConstants";

export function PinItLogoPanel() {
  return (
    <div className={styles.logoPanelBox}>
      <div className={styles.pinItLogoBox}>
        <img src={pinitLogoStructure} alt="Structure" className={`${styles.pinItLogoStructure} ${styles.pinItLogo}`} />
        <img src={pinitLogoLeft} alt="Logo left" className={`${styles.pinItLogoLeft} ${styles.pinItLogo}`} />
        <img src={pinitLogoRight} alt="Logo right" className={`${styles.pinItLogoRight} ${styles.pinItLogo}`} />
        <img src={pinitLogoFull} alt="Logo full" className={`${styles.pinItLogoFull} ${styles.pinItLogo}`} />
      </div>
      <img src={pinitText} alt="Pin It!" className={`${styles.pinItText}`} />
    </div>
  );
}

export function ModesPanel({ setCurrentComponent }) {
  return (
    <>
      <Row className={styles.modesPanelBox}>
        <Col className={styles.modeBox}>
          <p className={styles.modeTitle}>Quick Play</p>
          <div className={`${styles.modesExplanationBox} ${styles.quickPlayExplanationBox}`}>
            <div className={styles.modeExplanation}>
              Try assembling some Chinese characters with our unique game style!<br /><br />
              Challenge your limit and see how fast you can do it!
            </div>
          </div>
        </Col>

        <Col className={styles.modeBox}>
          <p className={styles.modeTitle}>Classroom</p>
          <div className={`${styles.modesExplanationBox} ${styles.classroomExplanationBox}`}>
            <div className={styles.modeExplanation}>
              Set questions for your students to play in class!<br /><br />
              Gamify your class with the aid of our unique but character-rich games.
              <div className={styles.contactInfo}>
                Contact us at <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
              </div>
            </div>
          </div>
        </Col>

      </Row>
      <Tooltip title="Reload" className={styles.reloadButton2}>
        <Button shape="circle" icon={<ReloadOutlined />} onClick={() => setCurrentComponent(0)} />
      </Tooltip>
    </>

  );
}

export function MobileAppPanel() {
  return (
    <div className={styles["download-section"]}>
      <h2 className={styles["download-text"]}>Full Syllabus Available in Our Mobile App Too!</h2>
      <div className={styles["download-badges"]}>
        <MobileAppBadges refererList={[MOBILE_APP_DOWNLOAD_EVENT_REFERER_HOME_PAGE]} />
      </div>
    </div>
  );
}

export function TitlePanel() {
  return (
    <div className={styles.title}>
      <div className={styles.animatedTitle}>
        <div className={styles.textTop}>
          <div>
            <span>CHINESE CHARACTERS</span>
            <span>"pīn"-ing</span>
          </div>
        </div>
        <div className={styles.textBottom}>
          <div><b>Pin It</b> - Aid Chinese Character Learning and Memorisation through Game</div>
        </div>
      </div>
    </div>
  );
}

export function CreatorPanel() {
  return (
    <div className={styles.nusLogoBox}>
      <p className={styles.nusLogoTitle}>Gameplay Developed by NUS Researchers</p>
      <p className={styles.nusLogoTitle}>Design based on Academic Research </p>
      <img src={nusLogos} alt="SOC and FASS Logos" className={styles.nusLogoImage} />
    </div>
  );
}

export function DemoPanel({ setCurrentComponent }) {
  return (
    <>
      <Tooltip title="Reload" className={styles.reloadButton}>
        <Button shape="circle" icon={<ReloadOutlined />} onClick={() => setCurrentComponent(0)} />
      </Tooltip>
      <img src={pinDemonstrationGif} alt="Game Demostration" className={styles.display} />
    </>
  );
}