import { api } from "./api";

const QUICKPLAY_SESSION_ENDPOINT = "/web/quick_play/get_session";
const QUICKPLAY_ADD_STATISTICS_ENDPOINT = "/web/quick_play/add_statistics";
const QUICKPLAY_UPDATE_STATISTICS_ENDPOINT = "/web/quick_play/update_click_statistics";

export const getQuickPlaySession = (diff_level) => {
  return api.get(`${QUICKPLAY_SESSION_ENDPOINT}?diff_level=${diff_level}`);
};

export const addQuickPlayStatistics = (time_taken) => {
  return api.get(`${QUICKPLAY_ADD_STATISTICS_ENDPOINT}?time_taken=${time_taken}`);
};

export const updateQuickPlayStatistics = (parameter, uuid) => {
  return api.get(`${QUICKPLAY_UPDATE_STATISTICS_ENDPOINT}?parameter=${parameter}&uuid=${uuid}`);
};
