import { Button, ConfigProvider, Divider, Input } from "antd";
import React, { useEffect, useState } from "react";
import {
  PT_PAGE_STATUS_JOIN_COMPLETE,
  PT_PAGE_STATUS_JOIN_NAME,
  PT_PAGE_STATUS_JOIN_ROOM,
} from "../pages/ClassroomTestLiveParticipantPage";
import styles from "./ClassroomTestJoin.module.scss";

const ClassroomTestJoin = ({
                             testTitle,
                             isAssignedSession = false,
                             participantInfo,
                             joinStatus,
                             enterRoom,
                             enterNickname,
                           }) => {
  const [inputValue, setInputValue] = useState("");
  const [validating, setValidating] = useState(false);

  useEffect(() => {
    setInputValue("");
  }, [joinStatus]);

  const handleInputValueChange = (e) => {
    setInputValue(e.target.value);
  };

  const endValidating = () => {
    setValidating(false);
  };

  const handleConfirmInput = () => {
    const trimmedInput = inputValue.trim();
    if (!trimmedInput) return;

    setValidating(true);
    if (joinStatus === PT_PAGE_STATUS_JOIN_ROOM) {
      enterRoom(trimmedInput, endValidating);
    } else if (joinStatus === PT_PAGE_STATUS_JOIN_NAME) {
      enterNickname(trimmedInput, endValidating);
    }
  };

  const NoteMessage = () => (
    <div className={styles["waiting-msg-note"]}>
      <Divider />
      Hey there! 😊 Just a quick heads up – it would be awesome if you could stay on this browser page
      until the end of this session. If not, there's a chance you might get disconnected.
    </div>
  );

  if (joinStatus === PT_PAGE_STATUS_JOIN_COMPLETE) {
    return (
      <div className={styles.body}>
        <div className={styles["waiting-msg-container"]}>
          <div className={styles["waiting-msg-nickname"]}>{participantInfo.nickname}</div>
          <div className={styles["waiting-msg-instruction"]}>You're in! Waiting for the game to start...</div>
          <NoteMessage />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.body}>
        {
          isAssignedSession &&
          <>
            <div className={styles["test-title"]} style={{ fontSize: testTitle.length > 25 ? "20px" : "26px" }}>
              {testTitle}
            </div>
            <NoteMessage />
          </>
        }
        <div className={styles["info-form"]}>
          <div className={styles["info-form-input-container"]}>
            <Input size="large"
                   bordered={false}
                   placeholder={joinStatus === PT_PAGE_STATUS_JOIN_ROOM ? "Enter room number" : "Enter nickname"}
                   maxLength={joinStatus === PT_PAGE_STATUS_JOIN_ROOM ? 7 : 15}
                   value={inputValue}
                   onChange={handleInputValueChange}
                   onPressEnter={handleConfirmInput}
                   className={styles["info-form-input"]}
            />
          </div>
          <div className={styles["info-form-btn-container"]}>
            <ConfigProvider theme={{
              components: {
                Button: {
                  colorPrimary: "#004AAD",
                  contentFontSizeLG: 20,
                  fontWeight: "600",
                  paddingInline: 0,
                  controlHeightLG: 40,
                  algorithm: true,
                },
              },
            }}>
              <Button type="primary" block size="large" onClick={handleConfirmInput} loading={validating}
                      className={styles["info-form-btn"]}>
                {isAssignedSession ? "Go" : "Enter"}
              </Button>
            </ConfigProvider>
          </div>
        </div>
      </div>
    );
  }
};

export default ClassroomTestJoin;
