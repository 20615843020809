import { Button, Cascader, ConfigProvider, Input, Modal } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../apiHandlers/apiUtils";
import { getCharactersForLesson, getCharactersWithChinese } from "../../apiHandlers/characterApiHandler";
import { extractUniqueChineseCharactersToArray } from "../../utils/stringUtils";
import { updateToastAfterMinDuration } from "../../utils/toastUtils";
import CharacterOption from "./CharacterOption";
import styles from "./EditTestModal.module.scss";

/*
const optionsSample = [
    {
      value: '1',
      label: 'NUS',
      children: [
        {
          value: '1',
          label: 'Level 1',
          children: [
            {
              value: '1',
              label: 'Lesson 1',
            },
            {
              value: '2',
              label: 'Lesson 2',
            },
          ],
        },
        {
          value: '2',
          label: 'Level 2',
          children: [
            {
              value: '9',
              label: 'Lesson 9',
            },
            {
              value: '10',
              label: 'Lesson 10',
            },
          ],
        },
      ],
    },
    {
      value: '2',
      label: 'ps',
      children: [
        {
          value: '1',
          label: 'Level 1',
          children: [
            {
              value: '1',
              label: 'Lesson 1',
            },
          ],
        },
      ],
    },
    {
      value: '3',
      label: 'HSK',
      children: [
        {
          value: '1',
          label: 'Level 1',
          children: [
            {
              value: '1',
              label: 'Lesson 1',
            },
          ],
        },
      ],
    },
  ];
*/

const syllabusData = {
  nus: [8, 14, 20, 26, 32, 38], // level 1: lessons 1-8; level 2: lessons 9-14; ...
  ntu: [7, 13, 22], // same schema as nus
  ps: [20, 19, 17, 16, 15, 10], // level 1: lessons 1-20; level 2: lessons 1-19; ...
  hsk: [6, 6, 9, 15, 21, 31],  // same schema as ps
};
const options = [];

// Function to generate lessons for a given syllabus and level
const generateLessonOptionObjs = (minLessonNumber, maxLessonNumber) => {
  const lessonOptionObjs = [];
  for (let i = minLessonNumber; i <= maxLessonNumber; i++) {
    lessonOptionObjs.push({
      value: i.toString(),
      label: `Lesson ${i}`,
    });
  }
  return lessonOptionObjs;
};

// Generate the options object based on syllabusData
for (const syllabus in syllabusData) {
  const levels = syllabusData[syllabus];

  const syllabusOptionObj = {
    value: syllabus === "nus" ? "1" : syllabus === "ps" ? "2" : syllabus === "hsk" ? "3" : "4",
    label: syllabus === "nus" ? "NUS" : syllabus === "ps" ? "Primary" : syllabus === "hsk" ? "HSK" : "NTU",
    children: [],
  };

  for (let i = 0; i < levels.length; i++) {
    const levelName = syllabus === "ps" ? "Primary" : "Level";
    let minLessonNumber = 1;
    if ((syllabus === "nus" || syllabus === "ntu") && i >= 1) {
      minLessonNumber = levels[i - 1] + 1;
    }
    const maxLessonNumber = levels[i];

    const levelOptionObj = {
      value: (i + 1).toString(),
      label: `${levelName} ${i + 1}`,
      children: generateLessonOptionObjs(minLessonNumber, maxLessonNumber),
    };
    syllabusOptionObj.children.push(levelOptionObj);
  }

  options.push(syllabusOptionObj);
}

function EditTestModal({ open, loading, onCancel, onConfirm, mode, existingTest }) {
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [candidateCharacters, setCandidateCharacters] = useState([]);
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [testName, setTestName] = useState("");
  const [prevSearchedCharacters, setPrevSearchedCharacters] = useState([]);

  useEffect(() => {
    if (existingTest) { // parent component may set existingTest to null, in which case these should not be executed
      setSelectedCharacters(existingTest.characters);
      setTestName(existingTest.name);
    } else {
      cleanUpModalContents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingTest]);

  const handleLessonChange = async (lesson) => {
    if (lesson == null) {
      setSelectedLesson(null);
      setCandidateCharacters([]);
      return;
    } else if (selectedLesson != null) {
      if (lesson[0] === selectedLesson[0] && lesson[1] === selectedLesson[1] && lesson[2] === selectedLesson[2]) return;
    }

    setSelectedLesson(lesson);
    let toastId;
    const loadingStartTime = Date.now();
    try {
      toastId = toast.loading("Loading characters", { autoClose: 1000 });
      const lessonCharacters = await getCharactersForLesson({
        syllabus_id: lesson[0],
        level_number: lesson[1],
        lesson_number: lesson[2],
      });
      handleLoadingCharactersSuccess(lessonCharacters, toastId, loadingStartTime);
    } catch (err) {
      handleLoadingCharactersError(err, toastId, loadingStartTime);
    }
  };

  useEffect(() => {
    setPrevSearchedCharacters([]);
  }, [selectedLesson]);

  const handleSearchCharacters = async (value) => {
    if (!value) return;

    let toastId;
    const chineseCharacters = extractUniqueChineseCharactersToArray(value);
    if (chineseCharacters.length === 0) {
      toastId = "no-chinese-char-found-toast";
      toast.info("No Chinese characters were found in the text.", { toastId, autoClose: 2000 });
      return;
    } else if (chineseCharacters.join("") === prevSearchedCharacters.join("")) {
      toastId = "no-new-chinese-char-found-toast";
      toast.info("No new Chinese characters were found in the text.", { toastId, autoClose: 2000 });
      return;
    }

    const loadingStartTime = Date.now();
    try {
      toastId = toast.loading("Loading characters", { autoClose: 1000 });
      const charactersSearchResult = await getCharactersWithChinese(chineseCharacters);
      handleLoadingCharactersSuccess(charactersSearchResult, toastId, loadingStartTime);
      setSelectedLesson(null);
      setPrevSearchedCharacters(chineseCharacters);
    } catch (err) {
      handleLoadingCharactersError(err, toastId, loadingStartTime);
    }
  };

  const handleLoadingCharactersSuccess = (loadedCharacters, toastId, loadingStartTime) => {
    setCandidateCharacters(
      // TODO: may be better if backend directly return whether this character can be "pin"ed
      loadedCharacters.filter((character) => character.svg_path.includes("characters")),
    );
    updateToastAfterMinDuration(toastId, loadingStartTime, {
        render: "Characters loaded",
        type: toast.TYPE.SUCCESS,
        autoClose: 1000,
        isLoading: false,
        closeButton: null,
        hideProgressBar: true,
      },
    );
  };

  const handleLoadingCharactersError = (err, toastId, loadingStartTime) => {
    updateToastAfterMinDuration(toastId, loadingStartTime, {
        render: getErrorMessage(err),
        type: toast.TYPE.ERROR,
        autoClose: null,
        isLoading: false,
        closeButton: null,
      },
    );
  };

  const cleanUpModalContents = () => {
    setSelectedLesson(null);
    setCandidateCharacters([]);
    setSelectedCharacters([]);
    setTestName("");
    setPrevSearchedCharacters([]);
  };

  const handleCancel = () => {
    onCancel(cleanUpModalContents);
  };

  const handleConfirm = async () => {
    // TODO: disable cancel, character selection, etc., maybe have one props modalDisabled
    if (confirmDisabled) return;

    onConfirm({
      quiz_name: testName,
      character_ids: selectedCharacters.map(c => c.id),
    }, cleanUpModalContents);
  };

  const handleSelectCharacter = (character) => {
    if (selectedCharacters.length >= 10) {
      const toastId = "exceed-char-limit-toast";
      toast.info("A maximum of 10 characters have been selected for the test.", { toastId, autoClose: 2000 });
      return;
    }
    setSelectedCharacters((prevSelectedCharacters) => {
      return [
        ...prevSelectedCharacters,
        character,
      ];
    });
  };

  const handleRemoveCharacter = (character) => {
    setSelectedCharacters((prevSelectedCharacters) => {
      return prevSelectedCharacters.filter(i => i.id !== character.id);
    });
  };

  const handleTestNameChange = (e) => {
    setTestName(e.target.value);
  };

  const confirmDisabled = selectedCharacters.length === 0 || testName.length === 0;

  const footer = (
    [
      <Button key="back" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="submit" type="primary" loading={loading} disabled={confirmDisabled} onClick={handleConfirm}>
        {mode === CREATE_TEST_MODE ? "Create" : "Confirm"}
      </Button>,
    ]
  );

  return (
    <ConfigProvider theme={{
      components: {
        Modal: {
          contentBg: "#FFFCF8",
          headerBg: "#FFFCF8",
          titleFontSize: 22,
        },
      },
    }}>
      <Modal
        title={mode === CREATE_TEST_MODE ? "Create new test" : `Edit Test "${existingTest?.name}"`}
        centered
        open={open}
        onCancel={handleCancel}
        width={1000}
        footer={footer}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className={styles["modal-body"]}>
          <div className={styles["lesson-selector"]}>
            <ConfigProvider
              theme={{
                components: {
                  Cascader: {
                    controlWidth: 270,
                    controlItemWidth: 80,
                    dropdownHeight: 250,
                  },
                },
                token: {
                  colorTextPlaceholder: "#8A8B86",
                },
              }}
            >
              <Cascader options={options} onChange={handleLessonChange} placeholder="Select a lesson"
                        allowClear={false} value={selectedLesson} size="large" />
            </ConfigProvider>
          </div>

          <div className={styles["search-bar"]}>
            <ConfigProvider
              theme={{
                token: {
                  colorTextPlaceholder: "#8A8B86",
                },
              }}
            >
              <Search placeholder="Input text to search for characters" onSearch={handleSearchCharacters} enterButton
                      allowClear size="large" maxLength={400} />
            </ConfigProvider>
          </div>

          <div className={styles["candidate-characters-area"]}>
            {
              candidateCharacters.map((lessonCharacter) => {
                if (selectedCharacters.some(c => c.id === lessonCharacter.id)) {
                  return <CharacterOption key={lessonCharacter.id}
                                          character={lessonCharacter}
                                          bgColor="#58E440"
                                          showSelectedTick={true}
                  />;
                } else {
                  return <CharacterOption key={lessonCharacter.id}
                                          character={lessonCharacter}
                                          bgColor="#D6F4F6"
                                          enableSelect={true}
                                          onSelect={handleSelectCharacter}
                  />;
                }
              })
            }
          </div>

          <div className={styles["selected-characters-area"]}>
            {
              selectedCharacters.map((selectedCharacter) => {
                return <CharacterOption key={selectedCharacter.id}
                                        character={selectedCharacter}
                                        bgColor="#D6F6D8"
                                        enableRemove={true}
                                        onRemove={handleRemoveCharacter}
                />;
              })
            }
            {
              [...Array(10 - selectedCharacters.length).keys()].map((index) => {
                return <CharacterOption key={`empty-${index}`} bgColor="transparent" outline={true} />;
              })
            }
          </div>

          <div className={styles["name-input"]}>
            <Input size="large"
                   bordered={false}
                   placeholder="Enter a name for the test"
                   maxLength={50}
                   value={testName}
                   onChange={handleTestNameChange}
                   className={styles["name-input-text"]}
            />
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
}

export default EditTestModal;

export const CREATE_TEST_MODE = "create";
export const UPDATE_TEST_MODE = "update";
