import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import ClassroomTestItem from "./ClassroomTestItem";

function ClassroomTestList({ tests, onEditClick, onDeleteClick, onAssignClick }) {
  return (
    <AnimatePresence>
      {tests.map(test => {
        return (
          <motion.div key={test.id} layout={true}
                      initial={{ opacity: 0, x: 0, y: -10 }}
                      animate={{ opacity: 1, x: 0, y: 0 }}
                      exit={{ opacity: 0, x: 0, y: 10 }}
                      transition={{ duration: 0.4 }}>
            <ClassroomTestItem test={test} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
          </motion.div>
        );
      })}
    </AnimatePresence>
  );
}

export default ClassroomTestList;
