import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageLayout from "./PageLayout";
import styles from "./QuickPlaySummaryPage.module.scss";

// This file is no longer in used, but left here in case it is needed in the future.

const QuickPlaySharingPage = () => {
  const { shareId } = useParams();
  const navigate = useNavigate();

  const IMAGE_URL = `https://s3.ap-southeast-1.amazonaws.com/ccl.web.share/${shareId}.jpeg`;
  const QUICKPLAY_LANDING_PAGE = "/quickplay";

  // Redirect to another page immediately after rendering the image
  const redirectToNewPage = () => {
    navigate(QUICKPLAY_LANDING_PAGE);
  };

  const body = (
    <div className={styles.gameMenu}>
      {
        <div>
          <img src={IMAGE_URL} alt="Sharing Image" onLoad={redirectToNewPage} />
        </div>
      }
    </div>
  );

  return (
    <PageLayout body={body} displayMode={"overlay"} />
  );
};

export default QuickPlaySharingPage;
