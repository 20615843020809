import React, { useEffect } from "react";
import { CLASSROOM_REGISTRATION_FORM_URL } from "../../constants/AppConstants";

const ClassroomRegisterPage = () => {
  useEffect(() => {
    window.location.href = CLASSROOM_REGISTRATION_FORM_URL;
  }, []);

  return <div></div>;
};

export default ClassroomRegisterPage;
