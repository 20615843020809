import { Button, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { CreatorPanel, MobileAppPanel, ModesPanel, PinItLogoPanel } from "../components/AnimatedSlideshow";
import styles from "./HomePage.module.scss";
import PageLayout from "./PageLayout";

const HomePage = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth whenever the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // The empty dependency array ensures that this effect runs once on mount

  const [currentComponent, setCurrentComponent] = useState(0);
  const PANEL_SWITCH_DURATIONS = [5000, 4500, 4500, 4500];

  useEffect(() => {
    const timer = setTimeout(() => {
      // Switch to the next component after 5 seconds
      if (currentComponent < 3) {
        setCurrentComponent((prevComponent) => (prevComponent + 1));
      }
    }, PANEL_SWITCH_DURATIONS[currentComponent]);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [currentComponent]);


  const body = (
    <>
      {currentComponent === 0 && <PinItLogoPanel />}
      {currentComponent === 1 && <CreatorPanel />}
      {currentComponent === 2 && <MobileAppPanel />}
      {currentComponent === 3 && <ModesPanel setCurrentComponent={setCurrentComponent} />}
      <Row className={`${styles.buttonsBox}`}>
        <Link to="/quickplay" className={`${styles.buttonArea}`}>
          <Button type="primary" block size="large" className={styles.redirectButton}>Quick Play</Button>
        </Link>
        <Link to="/classroom/login" className={`${styles.buttonArea}`}>
          <Button type="primary" block size="large" className={styles.redirectButton}>Teacher's Login</Button>
        </Link>
      </Row>
    </>
  );

  return (
    <PageLayout body={body} displayMode={"overlay"} showFooter={true} />
  );
};


export default HomePage;
