import { Col, Row } from "antd";
import { QRCodeSVG } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { logMobileAppDownloadClickEvent } from "../../apiHandlers/logApiHandler";
import mobileAppIconWithPadding from "../../assets/mobile-app-icon-with-padding.png";
import mobileAppIcon from "../../assets/mobile-app-icon.png";
import {
  MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_APP_DOWNLOAD_PAGE,
  MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_APP_DOWNLOAD_PAGE_QR,
  MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_QUIZ_SESSION,
  MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_QUIZ_SESSION_QR,
  MOBILE_APP_DOWNLOAD_EVENT_TYPE_APP_DOWNLOAD_PAGE_VISIT,
} from "../../constants/api";
import { APP_DOWNLOAD_PAGE_URL } from "../../constants/AppConstants";
import MobileAppBadges from "../components/MobileAppBadges";
import styles from "./AppDownloadPage.module.scss";
import PageLayout from "./PageLayout";

const VALID_REFERERS_LIST = [
  MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_QUIZ_SESSION,
  MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_QUIZ_SESSION_QR,
  MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_APP_DOWNLOAD_PAGE_QR,
];

const AppDownloadPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [refererToDownloadPage, setRefererToDownloadPage] = useState(null);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    let referer = searchParams.get("referer");
    let uid = searchParams.get("uid");
    setSearchParams({}, { replace: true });

    if (!VALID_REFERERS_LIST.includes(referer)) {
      referer = null;
    }
    setRefererToDownloadPage(referer);
    setUid(uid);

    logMobileAppDownloadClickEvent(MOBILE_APP_DOWNLOAD_EVENT_TYPE_APP_DOWNLOAD_PAGE_VISIT, referer ? [referer] : null, null, uid);
  }, []);

  const body = (
    <>
      {/*Browser Display*/}
      <BrowserView className={styles["view-container"]}>
        <Row className={styles["main-container"]}>
          <Col span={12} className={styles["left-container"]}>
            <img alt="Pin It Mobile App Icon" src={mobileAppIcon} className={styles["mobile-app-icon"]} />
          </Col>
          <Col span={12} className={styles["right-container"]}>
            <div className={styles["qr-code-container"]}>
              <QRCodeSVG
                value={`${APP_DOWNLOAD_PAGE_URL}?referer=${MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_APP_DOWNLOAD_PAGE_QR}`}
                bgColor="#FBFBFB"
                imageSettings={{ src: mobileAppIconWithPadding, width: 30, height: 30 }}
                className={styles["qr-code"]}
              />
            </div>
            <div className={styles["badges-container"]}>
              <MobileAppBadges horizontal={false}
                               refererList={refererToDownloadPage ? [refererToDownloadPage, MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_APP_DOWNLOAD_PAGE] : [MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_APP_DOWNLOAD_PAGE]}
                               uid={uid}
              />
            </div>
          </Col>
        </Row>
      </BrowserView>

      {/*Mobile Display*/}
      <MobileView className={styles["mobile-display-view-container"]}>
        <Row className={styles["mobile-display-main-container"]}>
          <Col span={24} className={styles["mobile-display-left-container"]}>
            <img alt="Pin It Mobile App Icon" src={mobileAppIcon}
                 className={styles["mobile-display-mobile-app-icon"]} />
          </Col>
          <Col span={24} className={styles["right-container"]}>
            <div className={styles["mobile-display-badges-container"]}>
              <MobileAppBadges horizontal={false}
                               refererList={refererToDownloadPage ? [refererToDownloadPage, MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_APP_DOWNLOAD_PAGE] : [MOBILE_APP_DOWNLOAD_EVENT_REFERER_CLASSROOM_APP_DOWNLOAD_PAGE]}
                               uid={uid}
              />
            </div>
          </Col>
        </Row>
      </MobileView>
    </>
  );

  return (
    <PageLayout body={body} displayMode="normal" showNUSLogos={true} />
  );
};

export default AppDownloadPage;
