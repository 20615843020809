import { ConfigProvider, Form, Input } from "antd";
import React from "react";
import styles from "./LoginFormInputItem.module.scss";

const LoginFormInputItem = ({ type, name, label, tooltip, dependencies, rules }) => {
  return (
    <div className={styles["login-form-input-item-container"]}>
      <ConfigProvider theme={{
        components: {
          Input: {
            paddingInlineLG: 13,
            activeBorderColor: "#202020",
            activeShadow: "0 0 0 0",
            errorActiveShadow: "0 0 0 0",
            hoverBorderColor: "#000000",
            colorErrorBorderHover: "#ff4d4f",
            colorBgContainer: "rgba(255, 255, 255, 0.6)",
            colorBorder: "rgba(0, 0, 0, 0.8)",
            colorTextDisabled: "rgba(0, 0, 0, 0.4)",
            colorBgContainerDisabled: "rgba(0, 0, 0, 0.05)",
            lineHeightLG: 1,
            lineWidth: 3,
            borderRadiusLG: 20,
          },
        },
      }}>
        <Form.Item
          name={name}
          label={label}
          tooltip={tooltip}
          dependencies={dependencies}
          rules={rules}
          className={styles["antd-form-item"]}
        >
          {
            type === "password"
              ? <Input.Password size="large" />
              : <Input size="large" />
          }
        </Form.Item>
      </ConfigProvider>
    </div>
  );
};

export default LoginFormInputItem;
