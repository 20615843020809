import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";

export default function PrivateRoute({ isAllowed, redirectPath = "/", redirectReason, children }) {
  if (!isAllowed) {
    toast.error(redirectReason);
    return <Navigate to={redirectPath} replace />;  // TODO: Add redirect state to go back to previous page
  }

  return children ? children : <Outlet />;
}
