import { Client } from "@stomp/stompjs";
import Cookies from "js-cookie";
import { WEB_AUTH_TOKEN_NAME } from "../constants/api";

let QUIZ_SESSION_SOCKET_ENDPOINT;
if (process.env.REACT_APP_ENV === "prod") {
  // secure WebSocket connection is needed for web pages served over HTTPS
  // TODO: Use api.pinit-nus.com/v1 from api.js constant file after api refactor
  QUIZ_SESSION_SOCKET_ENDPOINT = `wss://api.pinit-nus.com/v1/ws?purpose=quiz-session`;
} else {
  QUIZ_SESSION_SOCKET_ENDPOINT = `ws://${process.env.REACT_APP_BACKEND_DOMAIN_LOCAL}/v1/ws?purpose=quiz-session`;
}

let stompClient = null;

export const socketConnect = async (sessionId, connectAsQuizSessionOwner, onConnect, onWebSocketCloseOrError = () => {}, onWebSocketCloseOrErrorShouldReconnect = false) => {
  if (stompClient != null) return;

  stompClient = new Client({
    brokerURL: `${QUIZ_SESSION_SOCKET_ENDPOINT}&sessionId=${sessionId}`,
    onConnect: () => {
      onConnect();
    },
    connectHeaders: {
      "quiz-owner": connectAsQuizSessionOwner,
      "web-satoken": await Cookies.get(WEB_AUTH_TOKEN_NAME),
    },
    // 60 seconds heartbeat internals are needed as https://github.com/stomp-js/stompjs/issues/335
    // The issue might be resolved after version 7.1.0 of @stomp/stompjs is released
    heartbeatOutgoing: 30000,
    heartbeatIncoming: 30000,
  });

  registerNewOnWebSocketCloseOrError(onWebSocketCloseOrError, onWebSocketCloseOrErrorShouldReconnect);

  stompClient.activate();

  return stompClient;

  // TODO: handle connection error
};

export const registerNewOnWebSocketCloseOrError = (onWebSocketCloseOrError, onWebSocketCloseOrErrorShouldReconnect = false) => {
  if (stompClient == null) return;

  stompClient.onWebSocketClose = () => {
    onWebSocketCloseOrError();
    if (!onWebSocketCloseOrErrorShouldReconnect) {
      socketDisconnect();
    }
  };

  stompClient.onWebSocketError = () => {
    onWebSocketCloseOrError();
    if (!onWebSocketCloseOrErrorShouldReconnect) {
      socketDisconnect();
    }
  };
};

export const joinSessionAsOwner = () => {
  if (stompClient == null) return;
  stompClient.publish({ destination: `/app/quiz-session/join-owner` });
};

export const joinSessionWithNickname = (nickname) => {
  if (stompClient == null) return;
  stompClient.publish({ destination: `/app/quiz-session/join`, body: nickname });
};

export const listenToJoinStatus = (sessionId, callback, errorCallback) => {
  if (stompClient == null) return;
  const subscription = stompClient.subscribe(`/user/queue/quiz-session/join/status`, message => {
      subscription.unsubscribe();

      const body = JSON.parse(message.body);
      if (body.code === 200) {
        callback(body.data);
      } else {
        errorCallback();
      }
    },
  );
};

export const listenToNewParticipantJoinSession = (sessionId, callback) => {
  if (stompClient == null) return;
  stompClient.subscribe(`/topic/quiz-session/${sessionId}/new-participant`, message => {
      // TODO: Extract this as util
      callback((JSON.parse(message.body)).data);
    },
  );
};

export const listenToParticipantLeaveSession = (sessionId, callback) => {
  if (stompClient == null) return;
  stompClient.subscribe(`/topic/quiz-session/${sessionId}/participant-left`, message => {
      callback((JSON.parse(message.body)).data);
    },
  );
};

export const listenToOwnerLeaveSession = (sessionId, callback) => {
  if (stompClient == null) return;
  stompClient.subscribe(`/topic/quiz-session/${sessionId}/owner-left`, () => {
      callback();
    },
  );
};

export const startSession = () => {
  if (stompClient == null) return;
  stompClient.publish({ destination: `/app/quiz-session/start` });
};

export const listenToSessionStartLoading = (sessionId, callback) => {
  if (stompClient == null) return;
  stompClient.subscribe(`/topic/quiz-session/${sessionId}/start-loading`, () => {
      callback();
    },
  );
};

export const listenToSessionStart = (sessionId, callback) => {
  if (stompClient == null) return;
  stompClient.subscribe(`/topic/quiz-session/${sessionId}/start`, message => {
      callback((JSON.parse(message.body)).data);
    },
  );
};

export const requestSessionQuestions = () => {
  if (stompClient == null) return;
  stompClient.publish({ destination: `/app/quiz-session/request-questions` });
};

export const listenToIndividualSessionQuestions = (callback) => {
  if (stompClient == null) return;
  stompClient.subscribe(`/user/queue/quiz-session/questions`, message => {
      callback((JSON.parse(message.body)).data);
    },
  );
};

export const completeSession = (sessionData) => {
  if (stompClient == null) return;
  stompClient.publish({ destination: `/app/quiz-session/complete`, body: JSON.stringify(sessionData) });
};

export const partiallyCompleteSession = (sessionData) => {
  if (stompClient == null) return;
  stompClient.publish({ destination: `/app/quiz-session/complete-partial`, body: JSON.stringify(sessionData) });
};

export const saveSessionProgress = (sessionData) => {
  if (stompClient == null) return;
  stompClient.publish({ destination: `/app/quiz-session/save-progress`, body: JSON.stringify(sessionData) });
};

export const listenToParticipantCompleteSession = (sessionId, callback) => {
  if (stompClient == null) return;
  stompClient.subscribe(`/topic/quiz-session/${sessionId}/participant-complete`, message => {
      callback((JSON.parse(message.body)).data);
    },
  );
};

export const endSession = () => {
  if (stompClient == null) return;
  stompClient.publish({ destination: `/app/quiz-session/end` });
};

export const listenToSessionEnd = (sessionId, callback) => {
  if (stompClient == null) return;
  stompClient.subscribe(`/topic/quiz-session/${sessionId}/end`, () => {
      callback();
    },
  );
};

// TODO: Investigate whether unsubscribe is necessary, will socket disconnect unsubscribe from all

export const socketDisconnect = () => {
  if (stompClient == null) return;
  stompClient.deactivate();
  // TODO: handle on disconnect
  stompClient = null;
};
